import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { User } from 'shared/models/user.model';
import { AuthService } from 'shared/services/auth.service';
import { FriendshipService } from 'shared/services/friendship.service';
import { ProfileService } from 'shared/services/profile.service';

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss']
})
export class UserSearchComponent implements OnInit {
 users: Subject<User[]> =  new Subject<User[]>();
 auxUsers: User[] = [];
 auxFriends: any = [];
 friends: any = [];
 requests: any = [];
 currentUser!: User;
 user!: User;

 @ViewChild('profile', { static: true }) profile!: TemplateRef<any>;
  private subscriptions: Subscription[] = [];

  constructor(private frienshipService: FriendshipService,
              private profileService: ProfileService,
              private authService: AuthService,
              private dialog: MatDialog,
    ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.authService.userReplay$
        .subscribe((user) => {
          this.user = user;
        }),
      this.frienshipService.getMyFriends()
        .subscribe((friends: any) => {
          // // console.log(friends);
          if (friends) {
            const newFriends = friends.friends ? friends.friends : [];
            this.requests = friends.friendRequestsSended;
            // // console.log(this.requests);
            for (const friend of newFriends) {
              this.subscriptions.push(this.profileService.getProfile(friend)
                .subscribe((user) => {
                  // // console.log(user);
                  this.auxFriends.push(user);
                  this.friends.push(user);
                  // // console.log(this.friends);
                }))
            }
            // Obtenemos los usuarios
            this.subscriptions.push(
              this.frienshipService.getAllUsers()
              .subscribe((users: User[]) => {
                this.auxUsers = users
                  .filter((x) => !this.friends.map((y: any) => y.id).includes(x.uid))
                  .filter((x) => x.uid !== this.user.uid);
                this.users.next(this.auxUsers);
                // // console.log(users);
              }));
            } else {
            // Obtenemos los usuarios
            this.subscriptions.push(
              this.frienshipService.getAllUsers()
              .subscribe((users: User[]) => {
                this.auxUsers = users
                  .filter((x) => x.uid !== this.user.uid);
                this.users.next(this.auxUsers);
                // // console.log(users);
              }));
          }
        }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  onShowProfile(user: User) {
    this.currentUser = user;
    const dialogRef = this.dialog.open(this.profile, {
      width: '90%',
      maxWidth: '600px',
      panelClass: 'profile-dialog-container'
    });
  }

  onClose() {
    this.dialog.closeAll();
  }

  applyFilter(filterValue: any) {
    filterValue = filterValue?.target?.value;
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.users.next(this.auxUsers.filter((x) => x.name?.toLowerCase().includes(filterValue)));
    this.friends = this.auxFriends.filter((x: any) => x.name?.includes(filterValue));
  }
}
