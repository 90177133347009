export const content = {
  sign_in_text_1: 'Más que una red social. Conéctate, haz negocios y mucho más con otros Alumni pertenecientes a la red Semper Altius.',
  sign_in_text_2: '¡Únete a la comunidad y descubre  descuentos exclusivos y más beneficios para ti!',
  sign_in_create_account_button: 'CREAR CUENTA NUEVA',
  sign_in_log_in_button: 'INICIAR SESIÓN',
  sign_in_go_back_button: 'REGRESAR',
  sign_in_username_input_label: 'Correo',
  sign_in_password_input_label: 'Contraseña',
  sign_in_forgot_password_link: '¿OLVIDASTE TU CONTRASEÑA?',

  sign_up_step_1_name: 'Información',
  sign_up_step_1_title: 'CREA TU CUENTA',
  sign_up_step_1_subtitle: 'Este acceso es exclusivo, prepárate para proveer pruebas de haber estudiado o trabajado en un colegio perteneciente a la red SEMPER ALTIUS.',
  sign_up_step_1_country_input_label: '*País de residencia',
  sign_up_step_1_country_input_placeholder: 'País de residencia',
  sign_up_step_1_city_input_label: '*Ciudad de residencia',
  sign_up_step_1_city_input_placeholder: 'Ciudad de residencia',
  sign_up_step_1_institution_tipe_input_label: '*Tipo de Institución',
  sign_up_step_1_institution_tipe_input_placeholder: 'Tipo de Institución',
  sign_up_step_1_promotion_code_input_label: '*Código de promoción',
  sign_up_step_1_promotion_code_input_placeholder: 'Código de promoción',
  sign_up_step_1_birthdate_input_label: '*Fecha de nacimiento',
  sign_up_step_1_birthdate_day_input_placeholder: 'Día',
  sign_up_step_1_birthdate_month_input_placeholder: 'Mes',
  sign_up_step_1_birthdate_year_input_placeholder: 'Año',

  sign_up_step_2_name: 'Información Cuenta',
  sign_up_step_2_title: 'CREA TU CUENTA',
  sign_up_step_2_firstname_input_placeholder: '*Nombre(s)',
  sign_up_step_2_lastname_input_placeholder: '*Apellidos',
  sign_up_step_2_email_input_placeholder: '*Correo/Email',
  sign_up_step_2_confirm_email_input_placeholder: '*Confirmar correo/Email',
  sign_up_step_2_password_input_placeholder: '*Contraseña/Password',
  sign_up_step_2_confirm_password_name_input_placeholder: '*Confirmar contraseña/Password',
  sign_up_step_2_acceptance_terms_text_1: 'Acepto los ',
  sign_up_step_2_terms_conditions_link: 'Términos & Condiciones',
  sign_up_step_2_acceptance_terms_text_2: ' y el uso de mis datos personales de acuerdo a las ',
  sign_up_step_2_privacy_policy_link: 'Políticas de Privacidad.',
  sign_up_step_2_create_account_button: 'CREAR CUENTA',

  sign_up_step_3_name: 'Verificación de Cuenta',
  sign_up_step_3_verification_text: 'Te hemos enviado un correo de confirmación. Una vez que hayas verificado tu cuenta, podrás continuar con tu proceso.',
  sign_up_step_3_verification_button: 'NO HE RECIBIDO UN CORREO',

  sign_up_step_4_name: 'Información Profesional',
  sign_up_step_4_title: 'A TAN SÓLO UN PASO...',
  sign_up_step_4_SUBtitle: 'Antes de pasar a la sección de facturación y pago, cuéntanos un poco más acerca de tu educación profesional.',
  sign_up_step_4_institution_label: '*Colegio en el que estudiaste:',
  sign_up_step_4_institution_placeholder: 'Colegio',
  sign_up_step_4_institution_city_label: '*Ciudad del colegio:',
  sign_up_step_4_institution_city_placeholder: 'Ciudad del colegio',
  sign_up_step_4_institution_generation_label: '*Generación (Año):',
  sign_up_step_4_institution_generation_placeholder: 'Generación (Año)',
  sign_up_step_4_major_label: '*Profesión o carrera (título):',
  sign_up_step_4_major_placeholder: 'Profesión o carrera',
  sign_up_step_4_inscription_label: '*Inscripción:',
  sign_up_step_4_month_placeholder: 'Mes',
  sign_up_step_4_year_placeholder: 'Año',
  sign_up_step_4_graduation_label: '*Año que saliste del colegio:',
  // sign_up_step_4_graduation_month_placeholder: 'Mes',
  // sign_up_step_4_graduation_year_placeholder: 'Año',
  sign_up_step_4_profesional_experience_text: 'Ahora dinos tu experiencia profesional, así como datos de contacto. (No te preocupes, esta información será privada).',
  sign_up_step_4_postgraduate_label: 'Estudio de Posgrado (en caso de tener uno):',
  sign_up_step_4_business_label: 'Negocio (en caso de tener uno):',
  sign_up_step_4_businessline_label: 'Giro del negocio:',
  sign_up_step_4_business_description: 'Descríbenos tu negocio en unas cuantas palabras:',
  sign_up_step_4_business_email: '*Email de negocio/personal:',
  sign_up_step_4_business_phone: '*Teléfono de oficina/celular:',
  sign_up_step_4_another_phone: 'Otro teléfono de oficina/celular (opcional)',
  sign_up_step_4_add_diploma: 'AGREGAR DIPLOMA',
  sign_up_step_4_acceptance_text_1: 'Acepto que cumpliré con los ',
  sign_up_step_4_ethical_values: 'Valores de Ética',
  sign_up_step_4_acceptance_text_2: ' al usar esta aplicación.',
  sign_up_step_4_proceed_to_pay_button: 'PROCEDER A PAGO',

  sign_up_step_5_name: 'Método De Pago',
  sign_up_step_5_title: 'TIPOS DE PLANES',
  sign_up_step_5_subtitle: 'Selecciona tu plan',
  sign_up_step_5_year_title: 'ANUAL',
  sign_up_step_5_year_price: '60',
  sign_up_step_5_year_conditions: '*Pagando $ 5 USD al mes.',
  sign_up_step_5_year_description: 'Disfruta de todos los beneficios como cupones, ofertas y más.',
  sign_up_step_5_one_pay_title: 'ÚNICO PAGO',
  sign_up_step_5_one_pay_price: '49',
  sign_up_step_5_one_pay_description: 'Disfruta de todos los beneficios como cupones, ofertas y más.',
  sign_up_step_5_currency_1: 'USD',
  sign_up_step_5_selected_plan_currency: 'dlls',
  sign_up_step_5_selected_plan_duration_1: '*1 año de suscripción',
  sign_up_step_5_selected_plan_duration_2: '',
  sign_up_step_5_payment_subtitle: 'Pago',
  sign_up_step_5_payment_summary: 'Resumen',
  sign_up_step_5_payment_subtotal: 'Subtotal',
  sign_up_step_5_payment_taxes: 'I.V.A.',
  sign_up_step_5_payment_total: 'Total',
  sign_up_step_5_confirmation_subtitle: 'Confirmación',
  sign_up_step_5_subscription_button: 'Suscribirse',

  sign_up_succesful_registration_title: '¡Felicidades!',
  sign_up_succesful_registration_text_1: 'Has terminado tu proceso de aplicación. Estaremos revisando tu solicitud y en los siguientes días te enviaremos un correo de admisión.',
  sign_up_succesful_registration_text_2: 'Mientras tanto, disfruta de este ‘sneak peak’ donde podrás accesar a las ofertas, historia y mucho más.',
  sign_up_succesful_registration_button: 'IR A LA SOBREMESA',




  sign_up_next_button_text: 'SIGUIENTE',
  sign_up_already_have_account_label: '¿Ya tienes con una cuenta?',
  sign_up_already_have_account_link: 'INICIA SESIÓN',

  // -------------------------------
  home_write_label: 'Escribe',
  home_write_something_placeholder: 'Escribe algo...',
  home_upload_image_label: 'Sube una foto',
  home_go_to_forum_label: 'Ve a un foro',
  // -------------------------------
  business_coupons_title: 'CUPONES',
  business_coupons_section_explore: 'Explorar',
  business_coupons_section_saved: 'Guardados',
  business_coupons_filter_last: 'LO ÚLTIMO',
  business_coupons_filter_travels: 'VIAJES',
  business_coupons_filter_restaurants: 'RESTAURANTES',
  // business_coupons_title: '',
  // business_coupons_title: '',
  // business_coupons_title: '',
  // business_coupons_title: '',

  search_new_offer_step: '',
  search_new_offer_step_1_title: 'Paso 1 De 2',
  search_new_offer_step_2_title: 'Paso 2 De 2',
  search_new_offer_title_1: '¡Inicia tu oferta!',
  search_new_offer_subtitle: 'Bienvenido al apartado de Ofertas. Aquí podrás hacer alguna oferta en tus servicios profesionales, productos o inmuebles. Por favor llena el formulario con toda la información necesaria.',
  search_new_offer_step_conditions: '**Límite de publicaciones a una sola área y categoría cada 48 horas. Tu oferta estará disponible 30 días o hasta que decidas quitarla.',
  search_new_offer_step_link: 'Ver lista de prohibiciones.',
  search_new_offer_step_radio_1_label: 'Tipo de Oferta',
  search_new_offer_step_radio_1_option_1: 'Ventas',
  search_new_offer_step_radio_1_option_2: 'Servicios',
  search_new_offer_step_radio_1_option_3: 'Bienes Raíces',
  search_new_offer_step_input_1_label: '*Selecciona la categoría que mejor encaje.',
  search_new_offer_step_input_1_placeholder: 'Categoría',
  search_new_offer_step_input_valid_end_label: '*Elige la fecha de vencimiento de la oferta.',
  search_new_offer_step_input_valid_end_placeholder: 'Fecha',
  search_new_offer_step_input_2_label: '*Título de oferta: Ej. “Cubrebocas KN95”',
  search_new_offer_step_input_2_placeholder: 'Título',
  search_new_offer_step_input_3_label: '*Precio Oferta:',
  search_new_offer_step_input_3_placeholder: 'Precio',
  search_new_offer_step_input_4_label: '*Descripción de producto/servicio:',
  search_new_offer_step_input_4_placeholder: 'Cuéntale a la gente acerca de lo que trata tu oferta.',
  search_new_offer_step_next_button: 'SIGUIENTE',
  search_new_offer_step_title_2: 'UN PASO MÁS...',
  search_new_offer_step_input_5_label: '*Selecciona hasta tres imágenes para tu oferta:',
  search_new_offer_step_input_6_label: '*Selecciona un estado:',
  search_new_offer_step_input_6_placeholder: 'Estado',
  search_new_offer_step_input_7_label: '*Selecciona una ciudad:',
  search_new_offer_step_input_7_placeholder: 'Ciudad',
  search_new_offer_step_input_8_label: '*Teléfono (Para que te contacte el/la interesado/a):',
  search_new_offer_step_input_8_placeholder: 'Teléfono',
  search_new_offer_step_input_9_label: '*Correo (Para que te contacte el/la interesado/a):',
  search_new_offer_step_input_9_placeholder: 'Correo',
  search_new_offer_step_radio_2_label: '*Qué información te gustaría mostrar en tu anuncio:',
  search_new_offer_step_radio_2_option_1: 'Teléfono',
  search_new_offer_step_radio_2_option_2: 'Correo',
  search_new_offer_step_radio_2_option_3: 'Ambos',
  search_new_offer_step_terms_and_conditions_1: 'Acepto los',
  search_new_offer_step_terms_and_conditions_link_1: 'Términos & Condiciones',
  search_new_offer_step_terms_and_conditions_2: ' y el uso de mis datos personales de acuerdo a las ',
  search_new_offer_step_terms_and_conditions_link_2: 'Políticas de Privacidad.',
  search_new_offer_step_publish_button: 'PUBLICAR',
  search_new_request_step: '',
  search_new_request_step_1_title: 'Paso 1 De 2',
  search_new_request_step_2_title: 'Paso 2 De 2',
  search_new_request_title_1: 'AÑADE TU BÚSQUEDA',
  search_new_request_subtitle: 'Bienvenido al apartado de Búsqueda y Necesidades. Aquí podrás preguntar a la comunidad si tienen algo que estás buscando. Por favor llena el formulario con toda la información necesaria.',
  search_new_request_step_conditions: '**Límite de publicaciones a una sola área y categoría cada 48 horas. Tu oferta estará disponible 30 días o hasta que decidas quitarla.',
  search_new_request_step_link: 'Ver lista de prohibiciones.',
  search_new_request_step_radio_1_label: '¿Qué buscas? ¿Qué necesitas?',
  search_new_request_step_radio_1_option_1: 'Ventas',
  search_new_request_step_radio_1_option_2: 'Servicios',
  search_new_request_step_radio_1_option_3: 'Inmuebles',
  search_new_request_step_input_1_label: '*Selecciona la categoría que mejor encaje.',
  search_new_request_step_input_1_placeholder: 'Categoría',
  search_new_request_step_input_valid_end_label: '*Elige la fecha de vencimiento de la búsqueda.',
  search_new_request_step_input_valid_end_placeholder: 'Fecha',
  search_new_request_step_input_2_label: '*Título de demanda: Ej. “Necesito 200 pzs de…”',
  search_new_request_step_input_2_placeholder: 'Título',
  search_new_request_step_input_3_label: '*Descripción de demanda:',
  search_new_request_step_input_3_placeholder: 'Cuéntale a la gente acerca de lo que buscas.',
  search_new_request_step_next_button: 'SIGUIENTE',
  search_new_request_step_title_2: 'UN PASO MÁS...',
  search_new_request_step_input_4_label: '*Selecciona un estado.',
  search_new_request_step_input_4_placeholder: 'Estado',
  search_new_request_step_input_5_label: '*Selecciona una ciudad.',
  search_new_request_step_input_5_placeholder: 'Ciudad',
  search_new_request_step_input_6_label: '*Teléfono (Para que te contacte el/la interesado/a):',
  search_new_request_step_input_6_placeholder: 'Teléfono',
  search_new_request_step_input_7_label: '*Correo (Para que te contacte el/la interesado/a):',
  search_new_request_step_input_7_placeholder: 'Correo',
  search_new_request_step_radio_2_label: '*Qué información te gustaría mostrar en tu anuncio:',
  search_new_request_step_radio_2_option_1: 'Teléfono',
  search_new_request_step_radio_2_option_2: 'Correo',
  search_new_request_step_radio_2_option_3: 'Ambos',
  search_new_request_step_terms_and_conditions_1: 'Acepto los',
  search_new_request_step_terms_and_conditions_link_1: 'Términos & Condiciones',
  search_new_request_step_terms_and_conditions_2: ' y el uso de mis datos personales de acuerdo a las ',
  search_new_request_step_terms_and_conditions_link_2: 'Políticas de Privacidad.',
  search_new_request_step_publish_button: 'PUBLICAR',
  search_new_business_step_1_title: 'Paso 1 De 2',
  search_new_business_step_2_title: 'Paso 2 De 2',
  search_new_business_title_1: 'Añade tu Negocio o servicios',
  search_new_business_subtitle: 'Bienvenido al Directorio. Aquí podrás añadirte como profesionista para ofrecer tus servicios o añadir tu negocio. Por favor llena el formulario con toda la información necesaria.',
  // search_new_business_step_conditions: '**Límite de publicaciones a una sola área y categoría cada 48 horas. Tu oferta estará disponible 30 días o hasta que decidas quitarla.',
  search_new_business_step_link: 'Ver lista de prohibiciones.',
  search_new_business_step_advice_label: 'Para poder mostrar tus servicios profesionales, es necesario que llenes primero tu perfil profesional',
  search_new_business_step_advice_link: 'aquí',
  search_new_business_step_checkbox_1_label: 'Agregar información de',
  search_new_business_step_checkbox_1_professional_profile: 'Mi perfil profesional',
  search_new_business_step_radio_1_label: '¿Cómo te gustaría añadirte, como negocio o servicios profesionales?',
  search_new_business_step_radio_1_option_1: 'Mi negocio',
  search_new_business_step_radio_1_option_2: 'Mis servicios',
  search_new_business_step_input_1_label: '*Selecciona la categoría que mejor encaje.',
  search_new_business_step_input_1_placeholder: 'Categoría',
  search_new_business_step_input_2_label: '*El título de profesión que mejor te describa:',
  search_new_business_step_input_2_placeholder: 'Ej. “Diseñadora Gráfica”',
  search_new_business_step_input_3_label: '*¿Cuál es tu página de internet o tu principal red social (donde se pueda ver tu portafolio o más ejemplos de lo que haces)?',
  search_new_business_step_input_3_placeholder: 'http://www…',
  search_new_business_step_input_4_label: '*Descripción:',
  search_new_business_step_input_4_placeholder: 'Cuéntale a la gente un poco más de ti y tu experiencia.',
  search_new_business_step_next_button: 'SIGUIENTE',
  search_new_business_step_title_2: 'UN PASO MÁS...',
  search_new_business_step_input_5_label: '*¿Cuántos años de experiencia tienes?',
  search_new_business_step_input_5_placeholder: 'Años de experiencia',
  search_new_business_step_input_6_label: '*Selecciona hasta cinco imágenes para tu publicación:',
  search_new_business_step_input_7_label: '*Selecciona un estado.',
  search_new_business_step_input_7_placeholder: 'Estado',
  search_new_business_step_input_8_label: '*Selecciona una ciudad.',
  search_new_business_step_input_8_placeholder: 'Ciudad',
  search_new_business_step_input_9_label: '*Teléfono (Para que te contacte el/la interesado/a):',
  search_new_business_step_input_9_placeholder: 'Teléfono',
  search_new_business_step_input_10_label: '*Correo (Para que te contacte el/la interesado/a):',
  search_new_business_step_input_10_placeholder: 'Correo',
  search_new_business_step_radio_2_label: '*Qué información te gustaría mostrar en tu anuncio:',
  search_new_business_step_radio_2_option_1: 'Teléfono',
  search_new_business_step_radio_2_option_2: 'Correo',
  search_new_business_step_radio_2_option_3: 'Ambos',
  search_new_business_step_terms_and_conditions_1: 'Acepto los',
  search_new_business_step_terms_and_conditions_link_1: 'Términos & Condiciones',
  search_new_business_step_terms_and_conditions_2: ' y el uso de mis datos personales de acuerdo a las ',
  search_new_business_step_terms_and_conditions_link_2: 'Políticas de Privacidad.',
  search_new_business_step_publish_button: 'PUBLICAR',
  search_new_job_step_1_title: 'Paso 1 De 2',
  search_new_job_step_2_title: 'Paso 2 De 2',
  search_new_job_title_1: 'Añade UNA OFERTA DE TRABAJO',
  search_new_job_subtitle: 'Bienvenido a la Bolsa de Trabajo. Aquí podrás añadir nuevas ofertas si estás en busca de profesionistas. Por favor llena el formulario con toda la información necesaria.',
  search_new_job_step_link: 'Ver lista de prohibiciones.',
  search_new_job_step_input_1_label: '*El título de profesión que mejor describa la posición:',
  search_new_job_step_input_1_placeholder: 'Ej. “Ilustrador/a”',
  search_new_job_step_input_2_label: '*Selecciona la categoría que mejor encaje.',
  search_new_job_step_input_2_placeholder: 'Categoría',
  search_new_job_step_input_3_label: '¿Cuál es tu página de internet?',
  search_new_job_step_input_3_placeholder: 'http://www…',
  search_new_job_step_input_4_label: '¿Cuál es la red social principal (fb, ig, tw, etc) de tu negocio?',
  search_new_job_step_input_4_placeholder: 'http://www…',
  search_new_job_step_input_5_label: '*Descripción del puesto:',
  search_new_job_step_input_5_placeholder: 'Cuéntale a la gente un poco más de lo que buscas para este puesto en específico.',
  search_new_job_step_next_button: 'SIGUIENTE',
  search_new_job_step_title_2: 'UN PASO MÁS...',
  search_new_job_step_input_6_label: 'Puedes subir una imagen para este puesto como fondo :',
  search_new_job_step_input_7_label: '*Sube la imagen de tu logo :',
  search_new_job_step_input_8_label: '*Selecciona un estado y ciudad para la vacante.',
  search_new_job_step_input_8_placeholder: 'Estado',
  search_new_job_step_input_9_placeholder: 'Ciudad',
  search_new_job_step_terms_and_conditions_1: 'Acepto los',
  search_new_job_step_terms_and_conditions_link_1: 'Términos & Condiciones',
  search_new_job_step_terms_and_conditions_2: ' y el uso de mis datos personales de acuerdo a las ',
  search_new_job_step_terms_and_conditions_link_2: 'Políticas de Privacidad.',
  search_new_job_step_publish_button: 'PUBLICAR',

  profile_container_connections: 'Conexiones',
  profile_container_account_configuration: 'CONFIGURACIÓN DE CUENTA',
  profile_container_see_professional_profile: 'VER PERFIL PROFESIONAL',

  profile_professional_experience_title: 'EXPERIENCIA',
  profile_professional_education_title: 'EDUCACIÓN',
  profile_professional_skills_title: 'SKILLS',
  profile_professional_skills_add_new_placeholder: '+ Agregar Nuevo',
  profile_professional_cv_title: 'CV COMPLETO',
  profile_professional_cv_insert_button: 'ADJUNTAR PDF',

  search_results_title_1: 'OFERTAS',
  search_results_seeing_results_for: 'Estás viendo resultados para:',
  search_results_change_search_button: 'CAMBIAR BÚSQUEDA',
  // search_new_offer_step: '',

  settings_list_1_element: 'Invitar Amigos por Whatsapp',
  settings_list_2_element: 'Editar Perfil',
  settings_list_3_element: 'Mi Actividad',
  settings_list_4_element: 'Notificaciones',
  settings_list_5_element: 'Seguridad Y Privacidad',
  settings_list_6_element: 'Cambio De Contraseña',
  settings_list_7_element: 'Pagos Y Suscripción',
  settings_list_8_element: 'Reglamento de Uso',
  settings_list_9_element: 'Cerrar Sesión',
  settings_list_10_element: 'Solicitudes de amistad',
  settings_notifications_cancel: 'Cancelar',
  settings_notifications_title: 'Notificaciones',
  settings_notifications_save: 'Guardar',
  settings_notifications_1_element: 'Comentarios en Foros Seguidos',
  settings_notifications_2_element: 'Comentarios en Mis Foros Creados',
  settings_notifications_3_element: 'Recomendaciones de Foros',
  settings_notifications_4_element: 'Recomendaciones de Nuevos Contactos',
  settings_notifications_5_element: 'Solicitud Nuevo Contacto',
  settings_notifications_6_element: 'Recibir Notificaciones por Correo',
  settings_security_cancel: 'Cancelar',
  settings_security_title: 'Seguridad/Privacidad',
  settings_security_save: 'Guardar',
  settings_security_1_element: 'Acceso a Mesa de Negocios (Mesa de Ofertas, Mesa de Demandas, Búsqueda/Directorio).',
  settings_security_1_hint: '*Al tener acceso aceptas tener pública tu información de contacto a personas que no sean tus contactos.',
  settings_security_2_element: 'Aparecer en Búsqueda de Servicios Profesionales.',
  settings_security_2_hint: '*Al aceptar, aparecerás en la búsqueda de profesionistas en la Bolsa de Trabajo.',
  settings_security_3_element: 'Aparecer en Búsqueda de Negocios.',
  settings_security_3_hint: '*Al aceptar, aparecerá tu negocio en la búsqueda de profesionistas en la Bolsa de Trabajo.',
  settings_security_subtitle: 'Conexiones e Interacciones',
  settings_security_forum_reported: 'Foros Reportados',
  settings_security_post_reported: 'Posts Reportados',
  settings_security_person_reported: 'Personas Reportadas',
  settings_security_connections: 'Conexiones/Contactos',
  settings_edit_profile_cancel: 'Cancelar',
  settings_edit_profile_title: 'Editar Perfil',
  settings_edit_profile_save: 'Guardar',
  settings_edit_profile_change_profile_image: 'Cambiar imagen de perfil',
  settings_edit_profile_name_label: 'Nombre:',
  settings_edit_profile_school_label: 'Escuela:',
  settings_edit_profile_instagram_label: 'Instagram:',
  settings_edit_profile_twitter_label: 'Twitter:',
  settings_edit_profile_skype_label: 'Skype:',
  settings_edit_profile_edit_profile_professional_cancel: 'Cancelar',
  settings_edit_profile_edit_profile_professional_title: 'Editar Perfil Profesional',
  settings_edit_profile_edit_profile_professional_save: 'Guardar',
  settings_edit_profile_edit_profile_professional_link: 'Editar Perfil Profesional',
  settings_edit_profile_professional_basic_information_title: 'INFORMACIÓN BÁSICA',
  settings_edit_profile_professional_basic_information_current_enterprise_label: 'Empresa Actual:',
  settings_edit_profile_professional_basic_information_current_position_label: 'Ocupación/Puesto:',
  settings_edit_profile_professional_basic_information_current_location_label: 'Ubicación:',
  settings_edit_profile_professional_contact_information_title: 'INFORMACIÓN DE CONTACTO',
  settings_edit_profile_professional_contact_information_email_label: 'Dirección de correo:',
  settings_edit_profile_professional_contact_information_phone_label: 'Número de teléfono:',
  settings_edit_profile_professional_contact_information_website_label: 'URL Sitio web:',
  settings_edit_profile_professional_about_title: 'ACERCA',
  settings_edit_profile_professional_about_label: 'Preséntate, cuéntale a la gente acerca de ti.',
  settings_edit_profile_professional_job_experience_title: 'EXPERIENCIA DE TRABAJO', 
  settings_edit_profile_professional_job_experience_enterprise_label: 'Empresa/Organización:', 
  settings_edit_profile_professional_job_experience_website_label: 'Sitio Web:', 
  settings_edit_profile_professional_job_experience_position_label: 'Puesto:', 
  settings_edit_profile_professional_job_experience_location_label: 'Ubicación:', 
  settings_edit_profile_professional_job_experience_start_date_label: 'Fecha de inicio:', 
  settings_edit_profile_professional_job_experience_end_label: 'Fecha de finalización:', 
  settings_edit_profile_professional_job_experience_add_experience_link: '+ Añadir Experiencia de Trabajo', 
  settings_edit_profile_professional_education_title: 'EDUCACIÓN', 
  settings_edit_profile_professional_education_school_label: 'Escuela/Universidad:', 
  settings_edit_profile_professional_education_website_label: 'Sitio Web:', 
  settings_edit_profile_professional_education_title_label: 'Título:', 
  settings_edit_profile_professional_education_location_label: 'Ubicación:', 
  settings_edit_profile_professional_education_start_date_label: 'Fecha de inicio:', 
  settings_edit_profile_professional_education_end_label: 'Fecha de finalización:', 
  settings_edit_profile_professional_education_add_experience_link: '+ Añadir Más Estudios', 
  settings_edit_profile_professional_education_skills_title: 'Skills', 

  // offers_create_offer_title: '¡Inicia tu oferta!',
  // offers_create_offer_subtitle: 'Bienvenido al apartado de Ofertas. Aquí podrás hacer alguna oferta en tus servicios profesionales, productos o inmuebles. Por favor llena el formulario con toda la información necesaria.',
  // offers_create_offer_conditions: '**Límite de publicaciones a una sola área y categoría cada 48 horas. Tu oferta estará disponible 30 días o hasta que decidas quitarla.',
  // offers_create_offer_link: 'Ver lista de prohibiciones.',
  // offers_create_offer_title: '',
  // offers_create_offer_title: '',

  result_offer_detail_title: 'OFERTAS',
  result_directory_detail_title: 'DIRECTORIO',

  search_job_bank_title: 'BOLSA DE TRABAJO',
  search_job_bank_category_placeholder: 'Selecciona una categoría',
  search_job_bank_region_placeholder: 'Selecciona una región',
  search_job_bank_recommended_subtitle: 'Recomendado para ti...',
};
