import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { MarketplaceOfferRequest } from 'shared/models/marketplace-offer-request.model';
import { MarketplaceDirectoryJobBankOffer } from 'shared/models/marketplace-directory-job-bank-offer.model';
import { TranslateService } from 'shared/services/translate.service';
import { StorageService } from 'shared/services/storage.service';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import { StoragedFile } from 'shared/models/storaged-file.model';
import { MarketplaceService } from 'shared/services/marketplace.service';
import firebase from 'firebase/app';
import * as moment from 'moment';
import { AuthService } from 'shared/services/auth.service';
import { first, map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from 'shared/services/profile.service';
import { UserProfessionalProfile } from 'shared/models/user-professional-profile.model';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-create-advertising',
  templateUrl: './create-advertising.component.html',
  styleUrls: ['./create-advertising.component.scss']
})
export class CreateAdvertisingComponent implements OnInit, OnDestroy {
  @Input() mode!: 'offer' | 'search' | 'business' | 'job';

  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() conditions!: string;
  @Input() link!: string;
  @Input() radioButtons!: any[];
  @Input() firstSelect!: any[];

  // @ViewChild('success', { static: true }) success!: TemplateRef<any>;

  isAccepted = false;
  hasProfessionalProfile!: boolean;
  addProfessionalProfile = false;
  private professionalProfileRef = '';

  currentForm: any;
  currentForm2: any;

  today = moment(Date.now());
  auxToday = moment(Date.now());

  data!: MarketplaceOfferRequest | MarketplaceDirectoryJobBankOffer;
  categories: string[] = [];
  states: any[] = [];
  cities: string[] = [];
  images: StoragedFile[] = [];
  jobImage!: StoragedFile;
  companyLogo!: StoragedFile;
  imagesIndex = 0;
  imageIsNew = true;
  subscriptions: Subscription[] = [];

  content: any;
  private currentId: string = '';
  fromResolver = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              private fb: FormBuilder,
              private storageService: StorageService,
              private marketplaceService: MarketplaceService,
              private profileService: ProfileService,
              private authService: AuthService,
              private dialog: MatDialog,
              private activedRoute: ActivatedRoute,
               // private successDialog: MatDialog,
              private snackbar: MatSnackBar,
              private router: Router,
              private translateService: TranslateService) {
    this.translateService.dataObservable.subscribe((data) => {
    this.content = data;
    });
    
    this.getParamId();
    this.getDataFromResolver();
  }

  ngOnInit(): void {
    switch (this.mode) {
      case 'offer': {
        this.currentForm = this.fb.group({
          type: ['', Validators.required],
          category: ['', Validators.required],
          validDateEnd: [this.auxToday.add(30, 'day'), Validators.required],
          title: ['', Validators.required],
          price: ['', Validators.required],
          description: ['', Validators.required],
        });
        this.currentForm2 = this.fb.group({
          state: ['', Validators.required],
          city: ['', Validators.required],
          contactPhone: ['', Validators.required],
          contactEmail: ['', Validators.required],
          showInfo: ['', Validators.required],    
        });
        if (this.fromResolver) {
          this.currentForm.patchValue({
            ...this.data,
            validDateEnd: moment((this.data as MarketplaceOfferRequest).validDateEnd!.toMillis())
          });
          this.images = [...this.data.images!];
          this.currentForm2.patchValue({
            ...this.data,
          });
          // // console.log(this.currentForm);
          // // console.log(this.currentForm2);
        }
        break;
      }
      case 'search': {
        this.currentForm = this.fb.group({
          type: ['', Validators.required],
          category: ['', Validators.required],
          validDateEnd: [this.auxToday.add(30, 'day'), Validators.required],
          title: ['', Validators.required],
          description: ['', Validators.required],
        });
        this.currentForm2 = this.fb.group({
          state: ['', Validators.required],
          city: ['', Validators.required],
          contactPhone: ['', Validators.required],
          contactEmail: ['', Validators.required],
          showInfo: ['', Validators.required],    
        });
        if (this.fromResolver) {
          this.currentForm.patchValue({
            ...this.data,
            validDateEnd: moment((this.data as MarketplaceOfferRequest).validDateEnd!.toMillis())
          });
          this.images = [...this.data.images!];
          this.currentForm2.patchValue({
            ...this.data,
          });
          // // console.log(this.currentForm);
          // // console.log(this.currentForm2);
        }
        break;
      }
      case 'business': {
        this.currentForm = this.fb.group({
          showAs: ['', Validators.required],
          category: ['', Validators.required],
          title: ['', Validators.required],
          website: ['', Validators.required],
          description: ['', Validators.required],
        });
        this.currentForm2 = this.fb.group({
          expYears: ['', Validators.required],
          state: ['', Validators.required],
          city: ['', Validators.required],
          contactPhone: ['', Validators.required],
          contactEmail: ['', Validators.required],
          showInfo: ['', Validators.required],    
        });
        if (this.fromResolver) {
          this.currentForm.patchValue({
            ...this.data,
          });
          this.images = [...this.data.images!];
          this.currentForm2.patchValue({
            ...this.data,
          });
          // // console.log(this.currentForm);
          // // console.log(this.currentForm2);
        }
        break;
      }
      case 'job': {
        this.currentForm = this.fb.group({
          title: ['', Validators.required],
          category: ['', Validators.required],
          website: ['', Validators.required],
          socialUrl: ['', Validators.required],
          description: ['', Validators.required],
        });
        this.currentForm2 = this.fb.group({
          state: ['', Validators.required],
          city: ['', Validators.required],
        });
        if (this.fromResolver) {
          this.currentForm.patchValue({
            ...this.data,
          });
          this.images = [...this.data.images!];
          this.currentForm2.patchValue({
            ...this.data,
          });
          // // console.log(this.currentForm);
          // // console.log(this.currentForm2);
        }
        break;
      }
    }

    this.subscriptions.push(this.marketplaceService.getAllMarketplaceCategories()
      .subscribe((categories: any) => {
        this.categories = categories.categories;
        this.subscriptions.push(this.profileService.getProfessionalProfile()
          .subscribe((profile: UserProfessionalProfile) => {
            if (profile.basicInfo) {
              this.hasProfessionalProfile = true;
            } else {
              this.hasProfessionalProfile = false;
            }
          }));
      }))
    // this.categories = ['Categoría 1', 'Categoría 2'];
    this.states = this.marketplaceService.getAllStates();
    this.cities = ['merida', 'campeche'];
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.subscriptions.length > 0) {
      for (const subscription of this.subscriptions) {
        subscription.unsubscribe();
      }
    }
  }

  
  /**
   * Obtiene el ID de la tarea a minipular.
   */
   private getParamId() {
    this.subscriptions.push(this.activedRoute.params.subscribe(param => {
      this.currentId = param.id;
    }));
  }

  /**
   * Obtiene los datos de la tarea que nos da el resolver.
   */
  private getDataFromResolver() {
    // Obtenemos los datos de la tarea
    this.subscriptions.push(
      this.activedRoute.data.pipe(
          map(data => data.data)
        )
        .subscribe((data: any) => {
          if (data) {
            // this.mode = 'edit';
            // console.log(data);
            // Inicializamos los datos
            this.data = {
              ...data as MarketplaceOfferRequest,
            };
            this.fromResolver = true;
          }
        })
    );
  }

  public async onUploadFile(event: any, index: number, isNewFile: boolean) {
    let fileRoute = `offers`;
    if (this.mode === 'business') {
      fileRoute = `business`;
    } else if (this.mode === 'job') {
      fileRoute = `job-bank`;
    }

    if (event.target.files.length > 0
      && this.storageService.imageFileTypes.includes(event.target.files[0].type)) {
      if (isNewFile) {
        const filePromise = this.storageService.managedUploadFile(event, null!, true, fileRoute);
        if (this.mode === 'job') {
          if (index === 0) {
            this.jobImage = await filePromise;
          } else {
            this.companyLogo = await filePromise;
          }
        } else {
          this.images.push(await filePromise);
        }
      } else {
        const filePromise = this.storageService.managedUploadFile(event, this.images[index], true, fileRoute);
        if (this.mode === 'job') {
          if (index === 0) {
            this.jobImage = await filePromise;
          } else {
            this.companyLogo = await filePromise;
          }
        } else {
          this.images[index] = await filePromise;
        }
      }
      // if (isNewFile) {
      //   const filePromise = this.storageService.managedUploadFile(event, null!, true, fileRoute);
      //   this.images.push(await filePromise);
      // } else {
      //   const filePromise = this.storageService.managedUploadFile(event, this.images[index], true, fileRoute);
      //   this.images[index] = await filePromise;
      // }
    } else {
      this.snackbar.open('No se pudo subir la imagen. Verifique si el tipo del archivo corresponde a una imagen.',
        undefined, { duration: 5000});
    }
  }

  public onDeleteFile(index: number) {
    // Abrimos una el dialogo para preguntarle si esta seguro
    const dialogRef = this.dialog.open(ConfirmDialogComponent,
      { width: '350px', data: 'Eliminar archivo'});
    // Si confirmo que desea continuar
    this.subscriptions.push(
      dialogRef.componentInstance.confirmSave.subscribe((confirm: boolean) => {
        if (confirm) {
          this.storageService.deleteFile(this.images[index].fullPath!);
          if (this.mode === 'job') {
            if (index === 0) {
              this.jobImage = {};
            } else {
              this.companyLogo = {};
            }
          } else {
            this.images.splice(index, 1);
          }
        }
      })
    );
  }

  async onPublish() {
    const user = await this.authService.userReplay$.pipe(first()).toPromise();
    switch(this.mode) {
      case 'offer': {
        const result: MarketplaceOfferRequest =
          {
            ...this.currentForm.value as MarketplaceOfferRequest,
            ...this.currentForm2.value as MarketplaceOfferRequest,
          };
        if (this.images.length > 0) {
          result.images = this.images;
        }
        result.validDateEnd = firebase.firestore.Timestamp.fromDate(this.currentForm.value.validDateEnd.toDate()) ;
        result.marketplaceType = 'offer';
        if (this.fromResolver) {
          this.marketplaceService.updateOffer(this.currentId, result)
          .then(() => {
            this.router.navigate(['/mesa-de-negocios/marketplace/ofertas/crear-nueva/publicada']);
          });
        } else {
          result.dateCreated = firebase.firestore.Timestamp.fromDate(this.today.toDate())
          result.contactName = user.name;
          result.validDateStart = firebase.firestore.Timestamp.fromDate(this.today.toDate());
          result.creatorId = user.uid;
          this.marketplaceService.createOffer(result)
            .then(() => {
              this.router.navigate(['/mesa-de-negocios/marketplace/ofertas/crear-nueva/publicada']);
            });
        }
        break;
      }
      case 'search': {
        const result: MarketplaceOfferRequest =
          {
            ...this.currentForm.value as MarketplaceOfferRequest,
            ...this.currentForm2.value as MarketplaceOfferRequest,
          };
          result.validDateEnd = firebase.firestore.Timestamp.fromDate(this.currentForm.value.validDateEnd.toDate()) ;
          result.marketplaceType = 'request';
          if (this.fromResolver) {
            this.marketplaceService.updateRequest(this.currentId, result)
            .then(() => {
              this.router.navigate(['/mesa-de-negocios/marketplace/busqueda/crear-nueva/publicada']);
            });
          } else {
            result.validDateStart = firebase.firestore.Timestamp.fromDate(this.today.toDate());
            result.creatorId = user.uid;
            result.contactName = user.name;
            result.dateCreated = firebase.firestore.Timestamp.fromDate(this.today.toDate())
            this.marketplaceService.createRequest(result)
              .then(() => {
                this.router.navigate(['/mesa-de-negocios/marketplace/busqueda/crear-nueva/publicada']);
              });
        }
        break;
      }
      case 'business': {
        const result: MarketplaceDirectoryJobBankOffer =
          {
            ...this.currentForm.value as MarketplaceDirectoryJobBankOffer,
            ...this.currentForm2.value as MarketplaceDirectoryJobBankOffer,
          };
          if (this.images.length > 0) {
            result.images = this.images;
          }
          result.showCreatorProfile = this.addProfessionalProfile;
          result.marketplaceType = 'directory';
          if (this.fromResolver) {
            this.marketplaceService.updateDirectory(this.currentId, result)
            .then(() => {
              this.router.navigate(['/mesa-de-negocios/marketplace/directorio/crear-nuevo/publicado']);
            });
          } else {
            result.creatorId = user.uid;
            result.contactName = user.name;
            result.dateCreated = firebase.firestore.Timestamp.fromDate(this.today.toDate())
            this.marketplaceService.createDirectory(result)
              .then(() => {
                this.router.navigate(['/mesa-de-negocios/marketplace/directorio/crear-nuevo/publicado']);
              });
        }
        break;
      }
      case 'job': {
        const result: MarketplaceDirectoryJobBankOffer =
          {
            ...this.currentForm.value as MarketplaceDirectoryJobBankOffer,
            ...this.currentForm2.value as MarketplaceDirectoryJobBankOffer,
          };
          result.jobImage = this.jobImage;
          result.companyLogo = this.companyLogo;
          result.marketplaceType = 'job';
          if (this.fromResolver) {
            this.marketplaceService.updateJobBank(this.currentId, result)
            .then(() => {
              this.router.navigate(['/mesa-de-negocios/marketplace/bolsa-de-trabajo/crear-nuevo/publicado']);
            });
          } else {
            result.creatorId = user.uid;
            result.dateCreated = firebase.firestore.Timestamp.fromDate(this.today.toDate())
            this.marketplaceService.createJobBank(result)
              .then(() => {
                this.router.navigate(['/mesa-de-negocios/marketplace/bolsa-de-trabajo/crear-nuevo/publicado']);
              });
        }
        break;
      }
    }
  }

  onChangeAccepted() {
    this.isAccepted = !this.isAccepted;
  }

  onChangeAddProfessionalProfile() {
    this.addProfessionalProfile = !this.addProfessionalProfile;
  }
}
