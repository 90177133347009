import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ForumPost } from 'shared/models/forum-post.model';
import { TranslateService } from 'shared/services/translate.service';
import { Subscription } from 'rxjs';
import { ForumService } from 'shared/services/forum.service';
import { NotificationService } from 'shared/services/notification.service';
import { ProfileService } from 'shared/services/profile.service';
import { AuthService } from 'shared/services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  content: any;
  posts: ForumPost[] = [];
  private subscriptions: Subscription[] = [];
  likedPosts: string[] = [];
  postsIndex = 1;
  AreThereNewResults = true;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              private forumService: ForumService,
              private notificationService: NotificationService,
              private authService: AuthService,
              private profileService: ProfileService,
              private translateService: TranslateService) {
    this.translateService.dataObservable.subscribe((data: any) => {
    this.content = data;
    });

    // this.notificationService.requestPermission();
  }

  
  async ngOnInit() {
    this.subscriptions.push(
      // Obtenemos los post del dia
      (await this.forumService.refreshAllPostsFromToday())
        .subscribe((posts: ForumPost[]) => {
          const postLength = this.posts.length;
          posts.map((x) => !this.posts.map((y) => y.id).includes(x.id) ? this.posts.push(x) : null);
          
          if (postLength === this.posts.length) {
            this.AreThereNewResults = false;
          }
        }),
      // Obtenemos los post likeados por el usuario logueado
      this.profileService.getLikeByMe()
        .subscribe((posts: string[]) => {
          this.likedPosts = [...posts];
        }),
    );
  }

  ngOnDestroy() {
    // this.subscriptions.forEach(x => x.unsubscribe());
  }

  onLoadMorePosts() {
    this.forumService.refreshAllPostsFromToday(++this.postsIndex);
  }
}
