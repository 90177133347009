import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from './auth.service';
import { SavedCoupons, SavedPostLikes } from '../models/saved-coupons.model';
import firebase from 'firebase/app';
import { first, map, switchMap } from 'rxjs/operators';
import { UserProfessionalProfile } from 'shared/models/user-professional-profile.model';
import { cleanObject } from 'shared/methods/common-methods';
import { Coupon } from 'shared/models/coupon.model';
import { Reference } from '@angular/compiler/src/render3/r3_ast';
import { User } from 'shared/models/user.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private get couponsRoute(): string {
    return `coupons`;
  }

  constructor(private dbFirestore: AngularFirestore,
              private snackbar: MatSnackBar,
              private authService: AuthService) { }

  async updateActivities(ref: firebase.firestore.DocumentReference, activiy: 'forum' | 'comments' | 'mkOffers' | 'mkRequest' | 'mkJobBank' | 'mkDirectory') {
    const userId = (await this.authService.userReplay$.pipe(first()).toPromise()).uid;
    if (activiy === 'forum') {
      this.dbFirestore.doc(`users/${userId}/activities/info`)
        .set({forums: firebase.firestore.FieldValue.arrayUnion(ref)}, {merge: true});
    } else if(activiy === 'comments') {
      this.dbFirestore.doc(`users/${userId}/activities/info`)
        .set({comments: firebase.firestore.FieldValue.arrayUnion(ref)}, {merge: true});
    } else if(activiy === 'mkOffers') {
      this.dbFirestore.doc(`users/${userId}/activities/info`)
        .set({mkOffers: firebase.firestore.FieldValue.arrayUnion(ref)}, {merge: true});
    } else if(activiy === 'mkRequest') {
      this.dbFirestore.doc(`users/${userId}/activities/info`)
        .set({mkRequest: firebase.firestore.FieldValue.arrayUnion(ref)}, {merge: true});
    } else if(activiy === 'mkJobBank') {
      this.dbFirestore.doc(`users/${userId}/activities/info`)
        .set({mkJobBank: firebase.firestore.FieldValue.arrayUnion(ref)}, {merge: true});
    } else if(activiy === 'mkDirectory') {
      this.dbFirestore.doc(`users/${userId}/activities/info`)
        .set({mkDirectory: firebase.firestore.FieldValue.arrayUnion(ref)}, {merge: true});
    }
  }

   /**
   * Actualiza los datos del usuario.
   * @param id Id del documento del usuario.
   * @param data Información del usuario.
   */
  async updateUser(data: User) {
    try {
      const userId = (await this.authService.userReplay$.pipe(first()).toPromise()).uid;

      cleanObject(data);
      return this.dbFirestore.doc<User>(`users/${userId}`)
        .update({
          ...data,
          lastModifiedDate: firebase.firestore.Timestamp.now(),
        })
        // .then(() => {
        //   this.snackbar.open('Usuario actualizado exitosamente.',
        //     undefined, { duration: 5000});
        // });
    } catch (error) {
      this.snackbar.open('Ups! Algo salió mal al actualizar el usuario',
        undefined, { duration: 5000});
      // console.error('updateUser - Detalles del error: ', error);
    }
  }

  getProfile(id: string) {
    return this.dbFirestore.doc<User>(`users/${id}`)
      .get()
      .pipe(
        map((document) => {
          return {
            ...document.data() as User, 
            reference: document.ref, 
            id: document.id
          } as User ;
        })
      );
  }

  getProfessionalProfile(id?: string) {
    if (id) {
      return this.dbFirestore.doc(`users/${id}/profile/info`)
        .get()
        .pipe(
          map((document) => {
            return {
              ...document.data() as UserProfessionalProfile, 
              reference: document.ref, 
              id: document.id
            } as UserProfessionalProfile ;
          })
        )
    } else {
      return this.authService.userReplay$
        .pipe(
          switchMap(user => {
            return this.dbFirestore.doc(`users/${user.uid}/profile/info`)
              .get()
              .pipe(
                map((document) => {
                  return {
                    ...document.data() as UserProfessionalProfile, 
                    reference: document.ref, 
                    id: document.id
                  } as UserProfessionalProfile ;
                })
              )
          })
        );
    }
  }

  updateProfile(user: User, professional: UserProfessionalProfile) {
    Promise.all([
      this.updateUser(user),
      this.updateProfessionalProfile(professional),
      this.authService.refreshUserDoc()
        .pipe(first())
        .toPromise()
    ])
    .then(() => {
      setTimeout(() => {
        // this.authService.refreshUserDoc();
        this.snackbar.open('Usuario actualizado',
         'OK', { duration: 3000});
      }, 300);
    })
  }

  async updateProfessionalProfile(data: UserProfessionalProfile) {
    cleanObject(data);
    const userId = (await this.authService.userReplay$.pipe(first()).toPromise()).uid;
    return this.dbFirestore.doc(`users/${userId}/profile/info`)
      .set({...data}, {merge: true})
      .then(() => {
        setTimeout(() => {
          // this.authService.refreshUserDoc();
          this.snackbar.open('Usuario actualizado',
           'OK', { duration: 3000});
        }, 300);
      })
  }
  
  async saveUserCoupon(id: string) {
    const user = await this.authService.userReplay$.pipe(first()).toPromise();
    return this.dbFirestore.doc(`users/${user.uid}/coupons/info`)
      .set({couponsId: firebase.firestore.FieldValue.arrayUnion(id)}, {merge: true});
  }

  getMySavedCoupons() {
    return this.authService.userReplay$
    .pipe(
      // Obtenemos los IDs de los cupones guardados
      switchMap(user => {
        return this.dbFirestore.doc(`users/${user.uid}/coupons/info`)
          .snapshotChanges()
          .pipe(
            map(x => x.payload.data() as {couponsId: string[]})
          );
      }),
      // Obtenemos los cupones
      switchMap(doc => {
        if (doc?.couponsId?.length > 0) {
          return this.dbFirestore.collection(this.couponsRoute, 
            ref  => ref.where(firebase.firestore.FieldPath.documentId(), 'in', doc.couponsId)
          )
          .get()
          .pipe(
            map((documents) => {
              return documents.docs.map((document) => {
                return document.data() as Coupon;
              })
            })
          );
        } else {
          return of([]);
        }
      })
    );
  }

  getLikeByMe() {
    return this.authService.userReplay$
      .pipe(
        switchMap(user => {
          return this.dbFirestore.doc<SavedPostLikes>(`users/${user.uid}/liked-by-me/info`)
            .valueChanges()
            .pipe(
              map((x) => {
                return x?.postsId && x?.postsId.length > 0 ? x.postsId! : [];
              })
            );
        })
      );
  }

  async likeByMe(id: string) {
    const userId = (await this.authService.userReplay$.pipe(first()).toPromise()).uid;
    return this.dbFirestore.doc(`users/${userId}/liked-by-me/info`)
      .set({postsId: firebase.firestore.FieldValue.arrayUnion(id)}, {merge: true});
  }

  async unlikeByMe(id: string) {
    const userId = (await this.authService.userReplay$.pipe(first()).toPromise()).uid;
    return this.dbFirestore.doc(`users/${userId}/liked-by-me/info`)
      .set({postsId: firebase.firestore.FieldValue.arrayRemove(id)}, {merge: true});
  }

  getCountries() {
    return [
      {title: 'México', value: 'mexico'},
      // {title: 'Estados Unidos', value: 'usa'},
    ]
  }

  getStates(country: string) {
    if (country && country !== '') {
      switch(country) {
        case 'mexico': {
          return [
            {title: 'Aguascalientes', value: 'aguascalientes'},
            {title: 'Baja California', value: 'baja-california'},
            {title: 'Baja California Sur', value: 'baja-california-sur'},
            {title: 'Campeche', value: 'campeche'},
            {title: 'Coahuila', value: 'coahuila'},
            {title: 'Colima', value: 'colima'},
            {title: 'Chiapas', value: 'chiapas'},
            {title: 'Chihuahua', value: 'chihuahua'},
            {title: 'CDMX', value: 'cdmx'},
            {title: 'Durango', value: 'durango'},
            {title: 'Guanajuato', value: 'guanajuato'},
            {title: 'Guerrero', value: 'guerrero'},
            {title: 'Hidalgo', value: 'hidalgo'},
            {title: 'Jalisco', value: 'jalisco'},
            {title: 'Michoacán', value: 'michoacan'},
            {title: 'Morelos', value: 'morelos'},
            {title: 'Nayarit', value: 'nayarit'},
            {title: 'Nuevo León', value: 'nuevo-leon'},
            {title: 'Oaxaca', value: 'oaxaca'},
            {title: 'Puebla', value: 'puebla'},
            {title: 'Querétaro', value: 'queretaro'},
            {title: 'Quintana Roo', value: 'quintana-roo'},
            {title: 'San Luis Potosí', value: 'san-luis-potosi'},
            {title: 'Sinaloa', value: 'sinaloa'},
            {title: 'Sonora', value: 'sonora'},
            {title: 'Tabasco', value: 'tabasco'},
            {title: 'Tamaulipas', value: 'tamaulipas'},
            {title: 'Tlaxcala', value: 'tlaxcala'},
            {title: 'Veracruz', value: 'veracruz'},
            {title: 'Yucatán', value: 'yucatan'},
            {title: 'Zacatecas', value: 'zacatecas'},
          ]
        }
        case 'usa': {
          return [
            {title: 'Florida', value: 'florida'}
          ]
        }
        default: {
          return [];
        }
      }
    } else {
      return [];
    }
  } 
}
