<div class="container" fxLayout="column" fxLayoutAlign="start">
  <div fxLayout="column" fxLayoutAlign="start">
    <div class="list-element settings-title"
      fxLayout="row" fxLayoutAlign="space-between center">
      <span>{{content.settings_notifications_cancel}}</span>
      <span>{{content.settings_notifications_title}}</span>
      <span class="link">{{content.settings_notifications_save}}</span>
    </div>
    <mat-divider></mat-divider>
    <div class="list-element"
      fxLayout="row" fxLayoutAlign="space-between center">
      <span>{{content.settings_notifications_1_element}}</span>
      <mat-slide-toggle color="primary" (click)="onChangeFollowedForums()"></mat-slide-toggle>
    </div>
    <mat-divider></mat-divider>
    <div class="list-element"
      fxLayout="row" fxLayoutAlign="space-between center">
      <span>{{content.settings_notifications_2_element}}</span>
      <mat-slide-toggle color="primary" (click)="onChangeMyCreatedForums()"></mat-slide-toggle>
    </div>
    <mat-divider></mat-divider>
    <div class="list-element"
      fxLayout="row" fxLayoutAlign="space-between center">
      <span>{{content.settings_notifications_3_element}}</span>
      <mat-slide-toggle color="primary" (click)="onChangeForumsRecommendations()"></mat-slide-toggle>
    </div>
    <mat-divider></mat-divider>
    <div class="list-element"
      fxLayout="row" fxLayoutAlign="space-between center">
      <span>{{content.settings_notifications_4_element}}</span>
      <mat-slide-toggle color="primary" (click)="onChangenewContactRecommendations()"></mat-slide-toggle>
    </div>
    <mat-divider></mat-divider>
    <div class="list-element"
      fxLayout="row" fxLayoutAlign="space-between center">
      <span>{{content.settings_notifications_5_element}}</span>
      <mat-slide-toggle color="primary" (click)="onChangeNewContactRequest()"></mat-slide-toggle>
    </div>
    <mat-divider></mat-divider>
    <div class="list-element"
      fxLayout="row" fxLayoutAlign="space-between center">
      <span>{{content.settings_notifications_6_element}}</span>
      <mat-slide-toggle color="primary" (click)="onChangeReceiveEmailNotifications()"></mat-slide-toggle>
    </div>
    <mat-divider></mat-divider>
  </div>
</div>