import { Injectable } from '@angular/core';
import { StoragedFile } from '../models/storaged-file.model';
import { AngularFireUploadTask, AngularFireStorage } from '@angular/fire/storage';
import { Subscription, Observable, BehaviorSubject, of } from 'rxjs';
import { AuthService } from './auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, first } from 'rxjs/operators';
import { cleanObject } from '../methods/common-methods';
import firebase from 'firebase/app';

/**
 * Representa una serie de métodos y propiedades para la subida de archivos.
 * Es de notar que debes llamar al método `cancelSubscriptions()` cuando ya no
 * necesites estos datos.
 */
@Injectable({
  providedIn: 'root'
})
export class StorageService {
  /**
   * Indica si un archivo esta siendo cargado a la nube.
   */
  public isUploading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public uploadProgress$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private uploadTask: AngularFireUploadTask | undefined;
  private subscriptions: Subscription[] = [];
  public imageFileTypes = [
    'image/apng',
    'image/bmp',
    'image/svg',
    'image/jpeg',
    'image/jpg',
    'image/png',
  ];

  constructor(private authService: AuthService,
              private database: AngularFirestore,
              private storage: AngularFireStorage,
              private snackbar: MatSnackBar) {
                this.uploadTask;
               }

  /**
   * Destruye todas las suscripciones del servicio.
   */
  destroy() {
    try {
      if (this.subscriptions.length) {
        this.subscriptions.forEach((subscription) => {
          subscription.unsubscribe();
        });
      }
    } catch (error) {
      this.snackbar.open('Ups! Algo salió mal al eliminar las subscripciónes del servicio de almacenamiento',
        undefined, { duration: 5000});
      // console.error('destroy - Detalles del error: ', error);
    }
  }

  /**
   * Crea una referencia en Firestore del archivo subido a Firebase Storage.
   * @param fileInfo Información del archivo.
   */
  createFileReferenceInDb(fileInfo: StoragedFile): string {
    const id = this.database.createId();
    this.database.doc(`files/${id}`)
            .set({...fileInfo});
    return id;
  }

  private randomString() {
    return Math.random().toString(36).substring(2);
  }

  /**
   * Sube un archivo a Firebase Storage y devuelve los datos necesarios
   * para su manipulación en el futuro.
   * Ideal para usar para proceso de cargas personalizados.
   * @param storagePath Dirección de guardado en Storage (i.e. `/projects`)
   * @param file Datos del archivos.
   */
  async uploadFile(storagePath: string, file: any, isImage: boolean = false): Promise<StoragedFile | any> {
    try {
      if (file === null || file === undefined) {
        throw new Error('No existe un archivo para subir.');
      }
      if (!(await this.isUploading$.pipe(first()).toPromise())) {
        // Informamos que el proceso de descarga está empezando
        this.isUploading$.next(true);

        // Creamos el nombre de almacenamiento del archivo
        const storedName = `${this.database.createId() + '-' + file.name}`;
        // Creamos el objeto que contendrá la informacion del archivo
        // tslint:disable-next-line:prefer-const
        let storedFile: StoragedFile = {
          name: file.name,
          storedname: storedName,
          fullPath: `${storagePath}/${storedName}`,
          fileSize: file.size,
          fileType: file.type,
          uploadDate: firebase.firestore.Timestamp.now(),
          // tslint:disable-next-line: object-literal-shorthand
          isImage: isImage,
        };

        // Subimos el archivo a Storage
        this.uploadTask = this.storage.upload(storedFile.fullPath as string, file);

        // Actualizamos el porcentaje de subida
        this.subscriptions.push(
          this.percentageUploaded().subscribe((percentage: number) => {
            this.uploadProgress$.next(percentage);
            if (percentage >= 100) {
              this.isUploading$.next(false);
            }
        }));

        // Obtenemos el link de descarga
        storedFile.downloadUrl = await this.uploadTask.then((x) => x.ref.getDownloadURL());

        return storedFile;
      } else {
        this.snackbar.open('Por favor espera mientras otra carga termina.',
        undefined, { duration: 3000 });
      }
    } catch (error) {
      this.snackbar.open(error, undefined, { duration: 3000 });
    }
  }

  /**
   * Sube un archivo a Firebase Storage con todo el proceso manejado. Ideal
   * para subir archivos de manera rápida.
   * @param event Evento de carga que contiene la información del archivo a subir.
   * @param previousFile Archivo anterior para comparación.
   */
  async managedUploadFile(event: any, previousFile: StoragedFile, isImage: boolean = false, path: string = '') {
    try {
      // NOTA: Si se llama al input del HTML y se da
      // cancelar al boton del dialogo
      // no se llamará a esta función a menos que antes
      // ya se haya seleccionado y aceptado el dialogo
      // previamente.
      // Si ya se acepto y se quiere cambiar la imagen
      // pero se le da cancelar al dialogo se reinicia
      // el estado y se aplica lo del inicio de la nota.

      // Verificamos si el usuario selecciono un archivo
      if (event.target.files.length > 0) {
        // Verificamos si ya se ha subido una imagen anteriormente
        if (previousFile && previousFile.uploadDate) {
          // Borramos el archivo subido anteriormente
          this.deleteFile(previousFile.fullPath as string);
          previousFile = {};
        }

        // Le informamos al usuario que se se esta subiendo su imagen
        this.snackbar.open('Subiendo archivo...',
          undefined, {duration: 5000});

        const user = await this.authService.userReplay$.pipe(first()).toPromise();

        // Subimos la imagen a Firebase Storage
        if (path) {
          return this.uploadFile(`${path}`, event.target.files[0], isImage);
        } else {
          return this.uploadFile(`users/${user.name} - ${user.uid}`, event.target.files[0], true);
        }
      } else {
        this.snackbar.open('Por favor selecciona un archivo para subir.',
        undefined, {duration: 5000});
      }
    } catch (error) {
      this.snackbar.open('Ups! Algo salió mal al subir el archivo',
        undefined, { duration: 5000});
      // console.error('managedUploadFile - Detalles del error: ', error);
    }
  }

  createCompanyCloudFileDocument(file: StoragedFile) {
    const id = this.database.createId();
    cleanObject(file);
    this.database.doc(`files/${id}`)
      .set({...file});
    return id;
  }

  async deleteCompanyCloudFileDocument(id: string) {
    try {
      // Obtenemos la información del documento
      const fileInfo = await this.database.doc<StoragedFile>(`files/${id}`)
        .valueChanges().pipe(first()).toPromise();

      // Borramos el documento de la base de datos
      this.database.doc(`files/${id}`)
        .delete()
        .then(() => {
          // Borramos el archivo de Firebase Storage
          this.deleteFile(fileInfo?.fullPath as string);
        });
    } catch (error) {
      this.snackbar.open('Ups! Algo salió mal al eliminar un archivo de la nube empresarial',
        undefined, { duration: 5000});
      // console.error('deleteCompanyCloudFileDocument - Detalles del error: ', error);
    }
  }

  /**
   * Obtiene el porcentage de carga de una operación.
   */
  percentageUploaded(): Observable<number | any> | any  {
    try {
      return this.uploadTask?.percentageChanges();
    } catch (error) {
      this.snackbar.open('Ups! Algo salió mal al obtener el porcentaje la carga',
        undefined, { duration: 5000});
      // console.error('percentageUploaded - Detalles del error: ', error);
      return of(null);
    }
  }

  /**
   * Pausa la operación de carga actual.
   */
  pauseUpload(): boolean | undefined {
    try {
      return this.uploadTask?.pause();
    } catch (error) {
      this.snackbar.open('Ups! Algo salió mal al pausar la carga',
        undefined, { duration: 5000});
      // console.error('pauseUpload - Detalles del error: ', error);
      return;
    }
  }

  /**
   * Reanuda la operación de carga actual.
   */
  resumeUpload() {
    try {
      this.uploadTask?.resume();
    } catch (error) {
      this.snackbar.open('Ups! Algo salió mal al reanudar la carga',
      undefined, { duration: 5000});
      // console.error('resumeUpload - Detalles del error: ', error);
    }
  }

  /**
   * Cancela la operación de carga actual.
   */
  async cancelUpload() {
    try {
      if (await this.isUploading$.pipe(first()).toPromise()) {
        this.isUploading$.next(false);
        this.uploadTask?.cancel();
      }
    } catch (error) {
      this.snackbar.open('Ups! Algo salió mal al cancelar la carga',
        undefined, { duration: 5000});
      // console.error('cancelUpload - Detalles del error: ', error);
    }
  }

  /**
   * Borra un archivo de Firebase Storage.
   * `Importante:` No se pueden borrar carpetas
   * programaticamente en Firebase Storage.
   * @param storagePath Dirección del archivo.
   */
  deleteFile(storagePath: string) {
    try {
      this.storage.ref(storagePath).delete();
    } catch (error) {
      this.snackbar.open('Ups! Algo salió mal al eliminar el archivo de Firebase Storage',
        undefined, { duration: 5000});
      // console.error('deleteFile - Detalles del error: ', error);
    }
  }
}
