<div class="container" fxLayout="column" fxLayoutAlign="start">
  <h2 class="section-title">Solicitudes de amistad</h2>
  <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="5px">
    <div class="request"
      *ngFor="let user of users"
      fxLayout="row" fxLayoutAlign="space-between center">  
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <!-- IMAGEN -->
        <div class="img-container">
          <img src="{{user.img ? user.img.downloadUrl : '../../../../../../../assets/no-profile.png'}}">
        </div>
        
        <!-- NOMBRE -->
        <span class="user-name">{{user.name | titlecase}}</span>
      </div>


      <!-- ACCIONES -->
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="0px">
        <button mat-button color="warn" (click)="onCancelRequest(user.id!)">Rechazar</button>
        <button mat-button color="primary" (click)="onAcceptRequest(user.id!)">Aceptar</button>
      </div>
    </div>
  </div>
  <h2 *ngIf="users.length === 0">No tienes solicitudes de amistad pendientes</h2>
</div>