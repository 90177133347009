import * as moment from 'moment';
import { StoragedFile } from 'shared/models/storaged-file.model';

/**
 * Limpia un objeto de todos sus undefined.
 * Uso de paso por referencia.
 * @param obj Objeto a limpiar.
 */
// tslint:disable-next-line: typedef
export function cleanObject(obj: any) {
  // Buscamos si tiene la propiedad 'id'
  if ('id' in obj) {
    // Limpiamos el id
    obj.id = undefined;
  }
  // Buscamos si tiene la propiedad 'reference'
  if ('reference' in obj) {
    // Limpiamos el reference
    obj.reference = undefined;
  }

  // Limpiamos los undefined del objeto
  Object.keys(obj)
      .forEach(key => obj[key] === undefined ? delete obj[key] : '');
}

/**
 * Recorta los espacios al final de una cadena.
 * @param text Texto a manipular.
 */
export function trimString(text: string): string {
  return text ? text.trim() : '';
}

/**
 * Recorta los espacio al final de una cadean y la vuelve
 * toda a minusculas.
 * @param text Texto a manipular.
 */
export function lowercaseAndTrimString(text: string): string {
  return text ? text.trim().toLowerCase() : '';
}

/**
 * Devuelve el valor numérico pasado en caso de que exista, si es nulo
 * o no está definido devuelve 0.
 * @param value Número a analizar.
 */
export function getNumberValueOrZero(value: number, DEFAULT: number = 0): number {
  return value === null || value === undefined ? DEFAULT : value;
}

/**
 * Devuelve el arriglo pasado en caso de que exista, si es nulo
 * o no está definido develve un arreglo de lenght 0.
 * @param value Arreglo a analizar.
 */
export function getArrayOrArrayZero(value: any[]): any[] {
  return value?.length > 0 ? value : [];
}

/**
 * Devuelve la cadena de texto ingresada en caso de que exista, sino
 * devuelve el valor por defecto que es un string vacío.
 * @param text Cadena de texto a evaluar.
 * @param DEFAULT Valor por defecto.
 */
export function getStringOrDefault(text: string, DEFAULT: string): string {
  return text === null || text === undefined || text === '' ? DEFAULT : text;
}

/**
 * Devuelve el booleano ingresado en caso de que existe, sino
 * devuelve `false`.
 * @param value Booleano a evaluar.
 */
export function getBooleanValueOrFalse(value: boolean): boolean {
  return value ? value : false;
}

/**
 * Devuelve el numero del booleano ingresado en caso de que existe. true = 1 y false = 0.
 * @param value Booleano a evaluar.
 */
export function getBoleeanValueAsNumber(value: boolean): number {
  return value ? 1 : 0;
}

/**
 * Filtra un arreglo de cadenas de acuerdo a un valor.
 * @param dataArray Arreglo de cadenas de texto.
 * @param value Valor a filtrar o que se busca.
 */
export function filterArray(dataArray: string[], value: string): string[] {
  const filterValue = value.toLowerCase();
  return dataArray.filter(data => data.toLowerCase().indexOf(filterValue) === 0);
}

/**
 * Convierte de una base numerica a otra.
 * @param value Valor a convertir.
 * @param base1 Base.
 * @param base2 Base.
 */
export function convertBaseToBase(value: number, base1: number, base2: number): string {
  return parseInt(String(value), base1).toString(base2);
}

export function createFolio(): string {
  const origin = moment(Date.now()).valueOf();
  const strNumber = origin.toString();
  // tslint:disable-next-line:prefer-const
  let numbers: number[] = [];
  for (let i = 0; i < strNumber.length; i++) {
    numbers.push(+strNumber.charAt(i));
  }

  const valDecimal1 = numbers[0] * 100 + numbers[1] * 10 + numbers[2];
  const valDecimal2 = numbers[3] * 100 + numbers[4] * 10 + numbers[5];
  const valDecimal3 = numbers[6] * 100 + numbers[7] * 10 + numbers[8];

  // tslint:disable-next-line:max-line-length
  return `${convertBaseToBase(valDecimal1, 10, 36)} ${convertBaseToBase(valDecimal2, 10, 36)} ${convertBaseToBase(valDecimal3, 10, 36)} ${Math.random().toString(36).slice(2, 4)}`;
}

export function convertStringToUrlFriendlyId(value: string): string {
  return value
    .trim()
    .toLocaleLowerCase() // Convertimos a minúsculas
    .normalize('NFD') // Normalizamos el texto seprando la letra de los grafemas(i.e: 'é' -> 'e' + '´')
    .replace(/[\u0300-\u036f]/g, '') // Sustituimos los grafemas por un string vacio
    .replace(/[&\/\\#!¡,+()$~%.'":*¿?<>{}]/g, '') // Removemos los simbolos como : ? !
    .replace(/\s\s+/g, ' ') // Convertimos multiples espacios en un solo espacio
    .split(' ') // Creamos N strings por cada espacio en la cadena
    .join('-'); // Juntamos la N cadenas en una sola uniendolas con un '-'
}

export function makeRandomString(length: number = 6) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for ( let i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

// export function validateImageThumbnails(data: StoragedFile, isProfile = false) {
//   if (data?.thumbnails?.length! > 0) {
//     if (data?.thumbnails![1].downloadUrl) {
//       return data.thumbnails[1].downloadUrl;
//     } else if (data?.thumbnails![0].downloadUrl) {
//       return data.thumbnails[0].downloadUrl;
//     } else {
//       if (!isProfile) {
//         return '../../projects/client/src/assets/no-profile.png';
//       } else {
//         return '../../projects/client/src/assets/no-image.jpg';
//       }
//     }
//   } else if (data?.downloadUrl) {
//     return data.downloadUrl;
//   } else {
//     if (!isProfile) {
//       return '../../projects/client/src/assets/no-profile.png';
//     } else {
//       return '../../projects/client/src/assets/no-image.jpg';
//     }
//   }
// }