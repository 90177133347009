import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { StoragedFile } from 'shared/models/storaged-file.model';
import { UserProfessionalProfile } from 'shared/models/user-professional-profile.model';
import { User } from 'shared/models/user.model';
import { AuthService } from 'shared/services/auth.service';
import { ProfileService } from 'shared/services/profile.service';
import { StorageService } from 'shared/services/storage.service';
import { TranslateService } from 'shared/services/translate.service';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-profile-professional',
  templateUrl: './profile-professional.component.html',
  styleUrls: ['./profile-professional.component.scss']
})
export class ProfileProfessionalComponent implements OnInit, OnDestroy {

  content: any;
  experience: any[] = [];
  education: any[] = [];
  skills: any[] = [
    'Business development',
    'Management',
    'Marketing',
    'Social Media',
    'Production',
    'Product Management',
    'Business Strategy'
  ];
  @Input() isEdit = true;

  user: User = { img: {}};
  profile!: UserProfessionalProfile;
  private subscriptions: Subscription[] = [];
  currentId!: string;
  comesFromResolver = false;
  currentCV!: StoragedFile;
  isNewCV = true;

  constructor(private translateService: TranslateService,
              private activedRoute: ActivatedRoute,
              private authService: AuthService,
              private snackbar: MatSnackBar,
              private dialog: MatDialog,
              private storageService: StorageService,
              private profileService: ProfileService,) {
      this.translateService.dataObservable.subscribe((data: any) => {
      this.content = data;
      this.getParamId();
      if (this.comesFromResolver) {
        this.getDataFromResolver();
      } else {
        this.getDataOnConstructor();
      }
      });
  }

    /**
   * Obtiene los datos de la tarea que nos da el resolver.
   */
  private getDataOnConstructor() {
    // Obtenemos los datos de la tarea
    this.subscriptions.push(
      this.authService.userReplay$
        .subscribe((user: User) => {
          if (user) {
            // console.log(user);
            this.user = {
              ...user,
              img: user?.img && user?.img?.downloadUrl ? user.img : { downloadUrl: '../../../../assets/no-profile.png'},
            };
            this.subscriptions.push(this.profileService.getProfessionalProfile()
            .subscribe((profile: UserProfessionalProfile) => {
              this.profile = {...profile};
              // console.log(profile);
              if (profile && profile.resume) {
                this.isNewCV = false;
              }
            }));
          }
        })
    );
  }

  ngOnInit(): void {
    // this.subscriptions.push(
    //   this.authService.userReplay$.subscribe((user: User) => {
    //     this.user = {
    //       ...user,
    //       img: user?.img && user?.img?.downloadUrl ? user.img : { downloadUrl: '../../../../assets/no-profile.png'},
    //     };
    //   }),
    //   this.profileService.getProfessionalProfile()
    //     .subscribe((profile: UserProfessionalProfile) => {
    //       this.profile = {...profile};
    //     })
    // );
  }

    /**
   * Obtiene el ID de la tarea a minipular.
   */
     private getParamId(): void {
      this.subscriptions.push(this.activedRoute.params.subscribe(param => {
        if (param.id) {
          this.currentId = param.id;
          this.comesFromResolver = true;
        }
      }));
    }
  
    /**
     * Obtiene los datos de la tarea que nos da el resolver.
     */
    private getDataFromResolver(): void {
      // Obtenemos los datos de la tarea
      this.subscriptions.push(
        this.profileService.getProfile(this.currentId)
          .subscribe((user) => {
            this.user = user;
          }),
        this.activedRoute.data.pipe(
            map(data => data.data)
          )
          .subscribe((profile: UserProfessionalProfile) => {
            if (profile) {
              this.profile = {...profile};
              // console.log(profile);
            }
          })
      );
    }

  onAddSkill(e: any) {
    if(e.key === "Enter" && e.target.value !== '') {
      this.skills.push(e.target.value);
      e.target.value = '';
    }
  }

  async onUploadCv(event: any, isNewFile: boolean) {
    const fileRoute = `profile`;

    if (event.target.files.length > 0
      && event.target.files[0].type === 'application/pdf') {
      if (isNewFile) {
        const filePromise = this.storageService.managedUploadFile(event, null!, true, fileRoute);
        this.currentCV = await filePromise;
      } else {
        const filePromise = this.storageService.managedUploadFile(event, this.currentCV, true, fileRoute);
        this.currentCV = await filePromise;
      }
      this.profile.resume = this.currentCV;
      this.profileService.updateProfessionalProfile({resume: this.currentCV});
    } else {
      this.snackbar.open('No se pudo subir el archivo, intente más tarde.',
        undefined, { duration: 5000});
    }
  }

  public onDeleteFile(index: number) {
    // Abrimos una el dialogo para preguntarle si esta seguro
    const dialogRef = this.dialog.open(ConfirmDialogComponent,
      { width: '350px', data: 'Eliminar archivo'});
    // Si confirmo que desea continuar
    this.subscriptions.push(
      dialogRef.componentInstance.confirmSave.subscribe((confirm: boolean) => {
        if (confirm) {
          this.storageService.deleteFile(this.profile.resume!.fullPath!);
          this.currentCV = {};
          this.profile.resume = {};
          this.profileService.updateProfessionalProfile({resume: {}});
        }
      })
    );
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      for (const subscription of this.subscriptions) {
        subscription.unsubscribe();
      }
    }
  }

}
