<div class="container"
  fxLayout="column" fxLayoutAlign="start center">
  <div class="profile-container"
    fxLayout="column" fxLayoutAlign="start center"
    fxLayoutGap="20px">
    <div class="profile-info-container"
      fxLayout="column" fxLayoutAlign="start">
      <div class="profile-img-container">
        <img src="{{user.img ? user.img.downloadUrl : '../../../../../../../assets/no-profile.png'}}">
      </div>
      <!-- FIXME: Membresía -->
      <div class="profile-internal-container"
        fxLayout="column" fxLayoutAlign="start center">
        <span class="profile-name">{{ user.name ? (user.name | titlecase) : 'No disponible' }}</span>
        <span class="profile-position" *ngIf="profile && profile.basicInfo?.job">{{ profile?.basicInfo?.job! ? (profile?.basicInfo?.job | titlecase) : 'No disponible' }}</span>
        <span class="profile-college">{{ user.school ? (user.school | titlecase) : 'No disponible' }}</span>
      </div>
      
      <div class="profile-internal-container"
        *ngIf="isEdit && !comesFromResolver"
        fxLayout="row" fxLayoutAlign="end center" style="padding: 0 25px;">
        <button mat-mini-fab color="primary"
          routerLink="/configuracion/editar-perfil"><mat-icon>edit</mat-icon></button>
      </div>
      
      <div class="profile-internal-container" style="width: 100%;"
        fxLayout="column" fxLayoutAlign="start center"
        fxLayoutGap="10px">
        <div class="profile-social" *ngIf="profile && profile.instagram"
          fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
          <img src="../../../../assets/instagram-logo.png">
          <a style="text-decoration: none; color: inherit;" target="_blank" href="https://www.instagram.com/{{profile.instagram ? profile.instagram : ''}}" [innerHTML]="profile.instagram ? (profile.instagram! | linky) : 'No disponible'"></a>
        </div>
        <div class="profile-social" *ngIf="profile && profile.twitter"
          fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
          <img src="../../../../assets/twitter-logo.png">
          <a style="text-decoration: none; color: inherit;" target="_blank" href="https://www.twitter.com/{{profile.twitter ? profile.twitter : ''}}" [innerHTML]="profile.twitter ? (profile.twitter! | linky) : 'No disponible'"></a>
        </div>
        <!-- <div class="profile-social" *ngIf="profile && profile.skype"
          fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
          <img src="../../../../assets/skype-logo.png">
          <a style="text-decoration: none; color: inherit;" target="_blank" href="https://www.skype.com/{{profile.skype ? profile.twitter : ''}}" [innerHTML]="profile.skype ? (profile.skype! | linky) : 'No disponible'"></a>
        </div> -->
      </div>
    </div>
    <!-- Experiencia -->
    <div class="profile-list-container" *ngIf="profile && profile!.experience?.length! > 0"
      fxLayout="column" fxLayoutAlign="start"
      fxLayoutGap="10px">
      <div class="top" fxLayout="column" fxLayoutAlign="center center">
        {{content.profile_professional_experience_title}}
      </div>
      <div class="element" *ngFor="let experience of profile.experience"
        fxLayout="row" fxLayoutAlign="start center"
        fxLayoutGap="20px">
        <div class="img-container">
          <img src="../../../../../../../assets/no-profile.png">
        </div>
        <div fxLayout="column" fxLayoutAlign="start start"
          fxLayoutGap="0px">
          <span class="element-position">{{experience.job ? (experience.job | titlecase) : 'No disponible'}}</span>
          <span class="element-enterprise">{{experience.company ? (experience.company | titlecase) : 'No disponible'}}</span>
          <!-- <span class="element-period">{{experience.company ? (experience.company | titlecase) : 'No disponible'}}</span> -->
          <span class="element-location">{{(experience.city && experience.state) ? (experience.city | titlecase) + ', ' + (experience.city | titlecase) : 'No disponible'}}</span>
        </div>
        <mat-divider></mat-divider>
      </div>
    </div>
    <!-- Educación -->
    <div class="profile-list-container" *ngIf="profile && profile!.education?.length! > 0"
      fxLayout="column" fxLayoutAlign="start"
      fxLayoutGap="10px">
      <div class="top" fxLayout="column" fxLayoutAlign="center center">
        {{content.profile_professional_education_title}}
      </div>
      <div class="element" *ngFor="let education of profile.education"
        fxLayout="row" fxLayoutAlign="start center"
        fxLayoutGap="20px">
        <div class="img-container">
          <img src="../../../../../../../assets/no-profile.png">
        </div>
        <div fxLayout="column" fxLayoutAlign="start start"
          fxLayoutGap="0px">
          <span class="element-position">{{education.school ? (education.school | titlecase) : 'No disponible'}}</span>
          <span class="element-enterprise">{{education.title ? (education.title | titlecase) : 'No disponible'}}</span>
          <!-- <span class="element-period">{{education.title ? (education.title | titlecase) : 'No disponible'}}</span> -->
          <span class="element-location">{{(education.city && education.state) ? (education.city | titlecase) + ', ' + (education.city | titlecase) : 'No disponible'}}</span>
        </div>
        <mat-divider></mat-divider>
      </div>
    </div>
    <!-- Skills -->
    <div class="profile-list-container" *ngIf="profile! && profile!.skills?.length! > 0"
      fxLayout="column" fxLayoutAlign="start"
      fxLayoutGap="10px">
      <div class="top" fxLayout="column" fxLayoutAlign="center center">
        {{content.profile_professional_skills_title}}
      </div>
      <div class="skills-container">
        <div class="skill-element"
          *ngFor="let skill of profile.skills">
          <span>{{skill | titlecase | slice:0:20}}</span>
        </div>
        <!-- <mat-form-field *ngIf="isEdit">
          <mat-label>{{content.profile_professional_skills_add_new_placeholder}}</mat-label>
          <input matInput (keydown)="onAddSkill($event)"
            name="addNewSkill" #addNewSkill="ngModel" ngModel>
        </mat-form-field> -->
      </div>
    </div>
    <!-- CV -->
    <div class="profile-list-container"
      fxLayout="column" fxLayoutAlign="start"
      fxLayoutGap="10px">
      <div class="top" fxLayout="column" fxLayoutAlign="center center">
        {{content.profile_professional_cv_title}}
      </div>
      <div class="cv-exterior-container" fxLayout="column" fxLayoutAlign="center center">
        <div *ngIf="!comesFromResolver" class="cv-interior-container"
          fxLayout="column" fxLayoutAlign="center center">
          <button *ngIf="profile && !profile.resume?.downloadUrl!" mat-raised-button class="insert-cv"
            (click)="uploadFileCV.click()">
            <mat-icon>attach_file</mat-icon>
            {{content.profile_professional_cv_insert_button}}
          </button>
          <input hidden type="file" 
            #uploadFileCV accept="application/pdf"
            (change)="onUploadCv($event, isNewCV)">
          <button *ngIf="profile && profile.resume && profile.resume.downloadUrl" [matMenuTriggerFor]="menuDownloadbleMedia" mat-button>CV ACTUAL: {{profile.resume.name}}</button>
          <mat-menu #menuDownloadbleMedia="matMenu">
            <a style="text-decoration: none;"  target="_blank" href="{{profile && profile.resume && profile.resume.downloadUrl}}">
              <button mat-menu-item>
                Descargar
              </button>         
            </a>
            <button mat-menu-item 
              (click)="uploadFileCV.click();"
              type="button">
              Editar
            </button>         
            <button mat-menu-item 
              (click)="onDeleteFile(0)"
              type="button">
              Borrar
            </button>         
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</div>

