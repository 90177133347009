import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';
import { UserProfessionalProfile } from 'shared/models/user-professional-profile.model';
import { ProfileService } from 'shared/services/profile.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileResolver implements Resolve<UserProfessionalProfile> {
  constructor (private service: ProfileService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserProfessionalProfile> {
    return this.service.getProfessionalProfile(route.params.id).pipe(first());
  }
}
