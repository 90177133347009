import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from 'shared/models/user.model';
import { FriendshipService } from 'shared/services/friendship.service';
import { ProfileService } from 'shared/services/profile.service';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-settings-friendship',
  templateUrl: './settings-friendship.component.html',
  styleUrls: ['./settings-friendship.component.scss']
})
export class SettingsFriendshipComponent implements OnInit {
  requests = [];
  users: any[] = [];
  subscriptions: Subscription[] = [];

  constructor(private friendshipService: FriendshipService,
              private dialog: MatDialog,
              private snackbar: MatSnackBar,
              private router: Router,
              private profileService: ProfileService) { }

  ngOnInit(): void {
    this.subscriptions.push(this.friendshipService.getMyRequests()
      .subscribe((requests: any) => {
        // console.log(requests);
        if (requests && requests.friendRequests) {
          this.requests = requests.friendRequests;
        }
        for (const request of this.requests) {
          // console.log(request);
          this.subscriptions.push(this.profileService.getProfile(request)
            .subscribe((user) => {
              // console.log(user);
              this.users.push(user);
              // console.log(this.users);
            }))
        }
      }))
  }

  onAcceptRequest(id: string) {
    // console.log(id);
    this.friendshipService.acceptFriendshipRequest(id);
    this.snackbar.open(`¡Felicidades, ahora tienes una nueva conexión!`, undefined,
    { duration: 7000 });
    this.router.navigate([`/buscar`]);
  }

  onCancelRequest(id: string) {
    // Abrimos una el dialogo para preguntarle si esta seguro
    const dialogRef = this.dialog.open(ConfirmDialogComponent,
      { width: '350px', data: 'Rechazar solicitud'});
    // Si confirmo que desea continuar
    this.subscriptions.push(
      dialogRef.componentInstance.confirmSave.subscribe((confirm: boolean) => {
        if (confirm) {
          // this.friendshipService.denyFriendship(id);
        }
      })
    );
  }
}
