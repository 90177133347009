import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ForumPost } from 'shared/models/forum-post.model';
import { PublicService } from 'shared/services/public.service';

@Component({
  selector: 'app-login-app-preview',
  templateUrl: './login-app-preview.component.html',
  styleUrls: ['./login-app-preview.component.scss']
})
export class LoginAppPreviewComponent implements OnInit, OnDestroy {
  posts: ForumPost[] = [];
  subscriptions: Subscription[] = [];

  constructor(private service: PublicService) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.service.getAllPosts(3).subscribe((posts) => {
        console.log(posts);
        
        this.posts = [...posts];
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

}
