<div class="notification-item-container"
  fxLayout="row" fxLayoutAlign="space-between center" [ngStyle]="{'opacity': notification.isRead ? '50%' : '100%'}">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
    
    <!-- Imagen de la notificacion -->
    <div class="profile-image">
      <img src="{{image}}">
    </div>
  
    <!-- Informacion de la notificacion -->
    <div fxLayout="column" fxLayoutAlign="start start">
      <span class="notification-title">{{notification.title ? notification.title : 'No disponible'}}</span>
      <span class="notification-description">{{notification.body ? notification.body : 'No disponible'}}</span>
    </div>
  </div>

  <!-- Acciones -->
  <div fxLayout="row" fxLayout.lt-md="column" 
    fxLayoutAlign="start center" 
    fxLayoutGap="20px" fxLayoutGap.lt-md="10px"
    *ngIf="notification.body !== 'Aun no tienes notificaciones.'">

    <button mat-flat-button class="action-button" 
      *ngIf="notification?.isFriendRequest && !notification.isRead"
      (click)="onOpenFriendDialog()">VER SOLICITUD</button>

    <button mat-flat-button class="action-button" 
      *ngIf="!notification?.isFriendRequest && !notification.isRead"
      (click)="onGoToNotification(notification)">VER</button>

    <!-- <button mat-flat-button class="action-button" color="warn"
      (click)="onDelete()">BORRAR</button> -->

    <button mat-flat-button *ngIf="!notification.isRead" class="action-button" 
      (click)="onMarkAsRead()">MARCAR LEÍDO</button>

    <button mat-flat-button *ngIf="notification.isRead" class="action-button" 
      (click)="onDelete()">ELIMINAR</button>
  </div>
</div>
