import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { trimString } from 'shared/methods/common-methods';
import { ForumPost } from 'shared/models/forum-post.model';
import { Forum } from 'shared/models/forum.model';
import { User } from 'shared/models/user.model';
import { AuthService } from 'shared/services/auth.service';
import { ForumService } from 'shared/services/forum.service';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';



@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit {
  @Input() isInForum = false;
  @Input() post!: ForumPost;
  @Input() likedByUser = false;
  @Input() forumCreatorId!: string;
  @ViewChild('image', { static: true }) imageModal!: TemplateRef<any>;
  @Input() isForReceptionPost = false;
  imgUrl!: string;
  hasImage = true;
  followedForums: Forum[] = [];
  isMyPost = false;
  @Output() isDeleted = new EventEmitter<boolean>();

  creator: Subject<User> = new Subject<User>();
  currentUser!: User;
  canUserInteract = false;

  private subscriptions: Subscription[] = [];

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              private authService: AuthService,
              private forumService: ForumService,
              private snackbar: MatSnackBar,
              private dialog: MatDialog,
              // private translateService: TranslateService
              ) {

  }

  ngOnInit(): void {
    // let currentUser: User = {};
    this.subscriptions.push(
      this.authService.userReplay$
        .subscribe((user) => {
          // // console.log(user);
          this.currentUser = user;
        }),
      // Obtenemos todos los foros disponibles
      this.forumService.getCreator(this.post.creatorId!)
        .subscribe((creator) => {
          // // console.log(creator);
          this.creator.next(creator);
          if (creator.uid === this.currentUser.uid) {
            this.isMyPost = true;
            // // console.log(creator.uid);
            // // console.log(this.currentUser.uid);
            // // console.log(this.isMyPost);
          }
        }),
      );
      // Verificamos si el usuario sigue al foro
      this.forumService.isForumFollowedByUser(this.post.forumId!)
        .then((result) => {
          if (this.post.creatorId === this.currentUser.uid) { // Si eres creador del post
            this.canUserInteract = true;
          } else if (this.forumCreatorId === this.currentUser.uid) { // Si eres creador del foro
            this.canUserInteract = true;
          } else if (result) { // Si es seguidor del foro
            this.canUserInteract = true;
          } else {
            this.canUserInteract = false;
          }
        })

    // TODO: Eliminar seccion de codigo no parece se necesaria
    // if (!(this.post?.images && this.post?.images.length > 0)) { // Si no tiene imagenes
    //   this.post.images = [{downloadUrl: '../../../assets/no-image.jpg'}];
    //   this.imgUrl = this.post.images[0].downloadUrl!;
    //   this.hasImage = false;
    // } else {
    //   this.imgUrl = this.post.images[0].downloadUrl!;
    // }

    // Si tiene imagenes
    if (this.post?.images && this.post?.images.length > 0) { 
      this.imgUrl = this.post.images[0].downloadUrl!;
    } 
  }

  onCommentPost(post: ForumPost, comment: string): void {
    comment = trimString(comment);
    if (this.canUserInteract && comment && comment !== '' && post.reference) {
      this.forumService.createMainComment(post.reference, {
        isArchive: false,
        text: comment,
      })
      .then(() => {
        this.snackbar.open('Comentario enviado', null!, { duration: 5000 });
      })
    }
  }

  onOpenModal(): void{
    if (window.innerHeight < window.innerWidth){
      this.dialog.open(this.imageModal, {
        width: '680px',
        maxWidth: '90vw',
        height: 'auto',
      });
    } else if (window.innerHeight > window.innerWidth) {
      this.dialog.open(this.imageModal, {
        width: '680px',
        maxWidth: '90vw',
        height: 'auto',
      });
    }
  }

  onLike() {
    if (this.likedByUser === false && this.post?.reference) {
      this.forumService.likePost(this.post.reference);
      if (this.post?.likes) {
        this.post.likes += 1;
      } else {
        this.post.likes = 1;
      }
    }
  }

  onDislike() {
    if (this.likedByUser === true && this.post?.reference) {
      this.forumService.unlikePost(this.post.reference);
      if (this.post?.likes && this.post?.likes > 0) {
        this.post.likes -= 1;
      } else {
        this.post.likes = 1;
      }
    }
  }

  onDeletePost() {
    // Abrimos una el dialogo para preguntarle si esta seguro
    const dialogRef = this.dialog.open(ConfirmDialogComponent,
      { width: '350px', data: 'Eliminar post'});
    // Si confirmo que desea continuar
    this.subscriptions.push(
      dialogRef.componentInstance.confirmSave.subscribe((confirm: boolean) => {
        if (confirm) {
          this.forumService.deletePost(this.post.reference!)
            .then(() => {
              this.isDeleted.emit(true);
            });
        }
      })
    );
  }
}
