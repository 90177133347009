<div class="container" fxLayout="column" fxLayoutAlign="start">
  <div fxLayout="column" fxLayoutAlign="start">
    <div class="list-element settings-title"
      fxLayout="row" fxLayoutAlign="space-between center">
      <span>{{content.settings_security_cancel}}</span>
      <span>{{content.settings_security_title}}</span>
      <span class="link">{{content.settings_security_save}}</span>
    </div>
    <mat-divider></mat-divider>
    <div class="first-list-element"
      fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column">
        <span>{{content.settings_security_1_element}}</span>
        <span class="hint">{{content.settings_security_1_hint}}</span>
      </div>
      <mat-slide-toggle color="primary" (click)="onChangeBussinesAccess()"></mat-slide-toggle>
    </div>
    <mat-divider></mat-divider>
    <div class="first-list-element"
      fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column">
        <span>{{content.settings_security_2_element}}</span>
        <span class="hint">{{content.settings_security_2_hint}}</span>
      </div>
      <mat-slide-toggle color="primary" (click)="onChangeAppearOnProfessionalServicesSearch()"></mat-slide-toggle>
    </div>
    <mat-divider></mat-divider>
    <div class="first-list-element"
      fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column">
        <span>{{content.settings_security_3_element}}</span>
        <span class="hint">{{content.settings_security_3_hint}}</span>
      </div>
      <mat-slide-toggle color="primary" (click)="onChangeAppearOnBusinessSearch()"></mat-slide-toggle>
    </div>
    <mat-divider></mat-divider>
    <span>{{content.settings_security_subtitle}}</span>
    <mat-divider></mat-divider>
    <div class="second-list-element"
      fxLayout="row" fxLayoutAlign="space-between center">
      <span>{{content.settings_security_forum_reported}}</span>
      <mat-icon routerLink="/configuracion/reportes">keyboard_arrow_right</mat-icon>
    </div>
    <mat-divider></mat-divider>
    <div class="second-list-element"
      fxLayout="row" fxLayoutAlign="space-between center">
      <span>{{content.settings_security_post_reported}}</span>
      <mat-icon routerLink="/configuracion/reportes">keyboard_arrow_right</mat-icon>
    </div>
    <mat-divider></mat-divider>
    <div class="second-list-element"
      fxLayout="row" fxLayoutAlign="space-between center">
      <span>{{content.settings_security_person_reported}}</span>
      <mat-icon routerLink="/configuracion/reportes">keyboard_arrow_right</mat-icon>
    </div>
    <mat-divider></mat-divider>
    <div class="second-list-element"
    fxLayout="row" fxLayoutAlign="space-between center">
      <span>{{content.settings_security_connections}}</span>
      <mat-icon routerLink="/configuracion/reportes">keyboard_arrow_right</mat-icon>
    </div>
    <mat-divider></mat-divider>
  </div>
</div>