import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserProfessionalProfile } from 'shared/models/user-professional-profile.model';
import { User } from 'shared/models/user.model';
import { AuthService } from 'shared/services/auth.service';
import { FriendshipService } from 'shared/services/friendship.service';
import { ProfileService } from 'shared/services/profile.service';
import { TranslateService } from 'shared/services/translate.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  content: any;
  @Input() isFriend = false;
  @Input() isMyProfile = true;
  @Input() hasRequested = false;
  @Input() passedUser!: any;
  @Input() isInDialog =  false;
  @Output() close = new EventEmitter<boolean>();
  user: User = { img: {}};
  profile!: UserProfessionalProfile;
  private subscriptions: Subscription[] = [];

  constructor(private authService: AuthService,
              private profileService: ProfileService,
              private snackbar: MatSnackBar,
              private friendshipService: FriendshipService,
              private router: Router,
              // private dialogRef: MatDialogRef<ProfileComponent>,
              // @Inject(MAT_DIALOG_DATA) public data: any,
              private translateService: TranslateService) {
      this.translateService.dataObservable.subscribe((data: any) => {
      this.content = data;
      });
  }

  ngOnInit(): void {
    if (this.isMyProfile) {
      // Obtenemos los datos de la tarea
      this.subscriptions.push(
        this.authService.userReplay$
          .subscribe((user: User) => {
            if (user) {
              // // console.log('User', user);
              this.user = {
                ...user,
                img: user?.img && user?.img?.downloadUrl ? user.img : { downloadUrl: '../../../../assets/no-profile.png'},
              };
            }
          }),
        this.profileService.getProfessionalProfile()
        .subscribe((profile: UserProfessionalProfile) => {
          this.profile = {...profile};
          // // console.log('UserProfessionalProfile', profile);
        })
      );
    } else {
      this.user = {
        ...this.passedUser,
        img: this.passedUser?.img && this.passedUser?.img?.downloadUrl ? this.passedUser.img : { downloadUrl: '../../../../assets/no-profile.png'},
      };
      // // console.log(this.user);
    }
    // // console.log(this.isMyProfile);
    // // console.log(this.isFriend);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  onSendFriendRequest() {
    // // console.log(this.user.uid);
    this.friendshipService.requestFriendship(this.user.uid!)
      .then((res) => {
        this.snackbar.open('Se ha enviado la solicitud al usuario.', undefined,
          { duration: 5000 });
        })
      .catch((error) => {
        this.snackbar.open('Hubo un error al mandar la solicitud al usuario. Intente más tarde.', undefined,
          { duration: 5000 });
        // console.log('Error: ', error);
      })
    this.close.emit(true);

  }

  onClose() {
    this.close.emit(true);
    // // console.log('closing');
    // this.dialogRef.close();
  }
}
