import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ForumPost } from 'shared/models/forum-post.model';

@Injectable({
  providedIn: 'root'
})
export class PublicService {

  constructor(private database: AngularFirestore) { }

  getAllPosts(limit:number = 10): Observable<ForumPost[]>{
    return this.database.collection<ForumPost>(`public-forum-posts`,
        ref => ref.orderBy('dateCreated', 'desc').limit(limit)
      )
      .get()
      .pipe(
        map((documents) => {
          return documents.docs.map((document) => {
            return {
            ...document.data()
            }
          })
        })
      );
  }
}
