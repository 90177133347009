import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from 'shared/models/user.model';
import { AuthService } from 'shared/services/auth.service';
import { CouponService } from 'shared/services/coupon.service';
import { NotificationService } from 'shared/services/notification.service';


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {

  notificationsNumber = 0;
  couponsNumber: number = 0;
  /**
   * Titulo del header
   */
  title: string = '';

  user: User = { img: {}};
  private subscriptions: Subscription[] = [];

  constructor(private authService: AuthService,
              private couponService: CouponService,
              private notificationService: NotificationService,
              private router: Router) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.getUser(),
      this.getRouterTitle(),
      this.getCouponsNumber(),
      this.getNotificationsNumber(),
      );
  }

  private getNotificationsNumber() {
    return this.notificationService.getAllNotifications()
      .subscribe((notifications) => {
        this.notificationsNumber = notifications.filter((x) => !x.isRead).length;
      });
  }

  private getCouponsNumber() {
    return this.couponService.getValidCoupons()
      .subscribe((coupons) => {
        this.couponsNumber = coupons.length ? coupons.length : 0;
      });
  }

  private getRouterTitle(): Subscription {
    return this.router.events.subscribe((value) => {
      if (value instanceof NavigationEnd) {
        this.title = this.getTitle(value.url);
      }
    });
  }

  private getUser(): Subscription {
    return this.authService.userReplay$.subscribe((user: User) => {
      this.user = {
        ...user,
        img: user?.img && user?.img?.downloadUrl ? user.img : { downloadUrl: '../../../../assets/no-profile.png' },
      };
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  getTitle(route: string) {
    if (route.includes('ofertas')) {
      return 'OFERTAS';
    } else if(route.includes('busqueda')) {
      return 'BÚSQUEDAS';
    } else if (route.includes('directorio')) {
      return 'DIRECTORIO';
    } else if(route.includes('bolsa')) {
      return 'BOLSA DE TRABAJO';
    } else if(route.includes('notificaciones')) {
      return 'NOTIFICACIONES';
    } else if(route.includes('foros')) {
      return 'FOROS';
    } else if(route.includes('cupones')) {
      return 'CUPONES';
    } else if(route.includes('chat')) {
      return 'CHAT';
    } else if(route === '/mesa-de-negocios/marketplace') {
      return 'MARKETPLACE';
    } else if(route === '/perfil') {
      return 'PERFIL';
    } else if(route === '/') {
      return 'INICIO';
    } else {
      return '';
    }

  }
}
