<div class="login-component">
  <!-- <img class="bg-top" src="../../../../assets/top-scene-background.svg" alt=""> -->

  <div class="loginContent">

    <section class="home">        
      <!-- Sale speech -->
      <div class="saleSpeech">
        <img class="homeLogoAU" src="./../../../../assets/alumni-universe-logo-color.svg" alt="Alumni Universe">
        <h1>
          ÉSTE ES <br> 
          EL UNIVERSO <br> 
          DE LOS ALUMNI <br>
          SEMPER ALTIUS</h1>
        <p class="saleSpeechText">Más que una Red Social. <br>Reencuentra a quienes compartimos tu pasión, e intercambia apoyos, ideas, productos y servicios.</p>
        <!-- Call to action -->
      </div>
    </section>
  
    <!-- -------------------------------------------------------------------------- -->
    <!-- FORMULARIO: Iniciar sesion -->
    <!-- -------------------------------------------------------------------------- -->
    <form #f="ngForm" class="form-inputs" *ngIf="showForm">
      <!-- CORREO -->
      <div class="email-input">
        <span>{{content.sign_in_username_input_label}}</span>
        <mat-form-field appearance="outline">
          <input matInput name="email" #email="ngModel"
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" required
          ngModel type="email">
          <mat-error *ngIf="!f.valid && email.touched">Correo no válido.</mat-error>
        </mat-form-field>
      </div>

      <!-- CONTRASENA -->
      <div class="email-input">
        <span>{{content.sign_in_password_input_label}}</span>
        <mat-form-field appearance="outline">
          <!-- Minimum eight characters, at lwast one letter and one number -->
          <input matInput required name="password" #password="ngModel"
          [type]="hidePassword ? 'password' : 'text'" ngModel>
          <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error *ngIf="!f.valid && password.touched">
            Ingrese su contraseña.
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Restablecer contraseña -->
      <a class="pointer" (click)="onResetPassword(f)">{{content.sign_in_forgot_password_link}}</a>

      <!-- Boton de inicio de sesion -->
      <button class="login-button" [ngStyle]="{'background-color': f.valid ? '' : 'rgba(0,0,0,.12)'}" mat-flat-button
      (click)="onLogin(f)" [disabled]="!f.valid" type="submit">{{content.sign_in_log_in_button}}</button>

      <!-- Regresar a la seleccion de accion -->
      <button class="login-button" mat-flat-button type="button" (click)="showForm = false;">{{content.sign_in_go_back_button}}</button>

      <!-- Legal - Terminos y condiciones y Aviso de privacidad -->
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <a routerLink="/legal/terms-of-service">Términos de servicio</a> 
        <a routerLink="/legal/privacy-policy">Aviso de privacidad</a>.
      </div>
    </form>

    <!-- -------------------------------------------------------------------------- -->
    <!-- Texto inferior -->
    <!-- -------------------------------------------------------------------------- -->
    <!-- <p *ngIf="!showForm">{{content.sign_in_text_2}}</p> -->
  </div>

  <!-- <img class="bg-bottom" src="../../../../assets/bottom-scene-background.svg" alt=""> -->
  <!-- <img class="abs-logo-bottom" src="../../../../assets/alumni-universe-logo-color.svg" alt=""> -->
</div>

<!-- -------------------------------------------------------------------------- -->
<!-- Intro -->
<!-- -------------------------------------------------------------------------- -->
<!-- <div class="intro-container"
  fxLayout="column" fxLayoutAlign="center center">
  <div class="intro-logo"
    fxLayout="column" fxLayoutAlign="center center">
    <img src="../../../../assets/alumni-universe-logo-white.svg" alt="">
    <div class="intro-background"></div>
  </div>
</div> -->
