<div class="container"
  fxLayout="column" fxLayoutAlign="start center">
  <!-- -------------------------------------------------------------------------------- -->
  <!-- Busqueda -->
  <!-- -------------------------------------------------------------------------------- -->
  <div class="search-bar" fxLayout="column" fxLayoutAlign=" center">
    <mat-form-field appearance="outline">
      <input matInput (keyup)="applyFilter($event)" placeholder="Buscar conexiones">
    </mat-form-field>
  </div>

  <!-- Mis conexiones -->
  <!-- -------------------------------------------------------------------------------- -->
  <div class="userGrid" *ngIf="friends.length > 0">
    <div class="title">
      <h1> Mis conexiones</h1>
      <button mat-flat-button color="primary"
        routerLink="/configuracion/solicitudes-amistad" >Solicitudes</button>
    </div>
    <div class="user" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px"
      *ngFor="let user of friends" (click)="onShowProfile(user)">
      <div *ngIf="user?.img && user?.img?.downloadUrl">
        <img src="{{ user.img!.downloadUrl! }}">
      </div>
      <img *ngIf="!(user?.img?.downloadUrl)" src="../../../../assets/no-profile.png">
      <span>{{ user.name | titlecase }}</span>
    </div>
  </div>

  <!-- Sugerencias -->
  <!-- -------------------------------------------------------------------------------- -->
  <div class="userGrid">
    <h1 class="title">Sugerencias para ti</h1>
    <div class="user pointer" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px"
      *ngFor="let user of users | async" (click)="onShowProfile(user)">
      <div *ngIf="user?.img && user?.img?.downloadUrl">
        <img src="{{ user.img!.downloadUrl! }}">
      </div>
      <img *ngIf="!(user?.img?.downloadUrl)" src="../../../../assets/no-profile.png">
      <span>{{ user.name | titlecase }}</span>
    </div>
  </div>
</div>

<!-- -------------------------------------------------------------------------------- -->
<!-- Dialogo del perfil -->
<!-- -------------------------------------------------------------------------------- -->
<ng-template #profile>
  <app-profile [isMyProfile]="false" [passedUser]="currentUser" [hasRequested]="requests ? requests.includes(currentUser.uid) : false"
    [isFriend]="friends.includes(currentUser)" [isInDialog]="true" (close)="onClose()"></app-profile>
</ng-template>