import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, forkJoin, Observable, of, pipe, ReplaySubject, Subject, Subscription } from 'rxjs';
import { first, map, switchAll, switchMap } from 'rxjs/operators';
import { convertStringToUrlFriendlyId, getStringOrDefault } from 'shared/methods/common-methods';
import { Forum, ForumFollowers } from 'shared/models/forum.model';
import { AuthService } from './auth.service';
import { DatabaseService } from './database.service';
import firebase from 'firebase/app';
import { ForumPost } from 'shared/models/forum-post.model';
import { User } from 'shared/models/user.model';
import * as moment from 'moment';
import { ForumComment } from 'shared/models/forum-comment.model';
import { ProfileService } from './profile.service';
import { AdminService } from './admin.service';
import { merge } from 'rxjs';
import { ThrowStmt } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class ForumService {

  private get usersRoute(): string {
    return `users`;
  }
  private get forumsRoute(): string {
    return `forums`;
  }
  private get postRoute(): string {
    return `posts`;
  }
  private get commentsRoute(): string {
    return `comments`;
  }

  private userCreatedForums: BehaviorSubject<Forum[]> = new BehaviorSubject<Forum[]>([]);
  private userFollowedForums: BehaviorSubject<Forum[]> = new BehaviorSubject<Forum[]>([]);

  private latestPosts: ReplaySubject<ForumPost[]> = new ReplaySubject<ForumPost[]>();
  latestPostsSize = 3;

  private forumFollowers: ReplaySubject<User[]> = new ReplaySubject<User[]>();
  private followedForums: ReplaySubject<Forum[]> = new ReplaySubject<Forum[]>();
  
  private subscriptions: Subscription[] = [];

  constructor(private database: DatabaseService,
              private authService: AuthService,
              private adminService: AdminService,
              private profileService: ProfileService,
              private dbFirestore: AngularFirestore) {
                this.refreshAllUserFollowedForums()
                  .then((res) => {
                    this.subscriptions.push(
                    res.subscribe((x) => {
                      this.userFollowedForums.next(x);
                    }));
                  })
                this.subscriptions.push(
                  this.refreshAllUserCreatedForums()
                    .subscribe((x) => {
                      this.userCreatedForums.next(x);
                    }),
                );
               }

  //---------------------------------------------------------------------------------------
  // Creadores
  //---------------------------------------------------------------------------------------
  getCreator(id: string) {
    return this.dbFirestore.doc<User>(`${this.usersRoute}/${id}`)
    .get()
    .pipe(
      map((document) => {
        const user: User = {
          ...document.data(),
          uid: document.id,
          reference: document.ref,
        };



        const userResult: User = {
          img: document.exists && user?.img?.downloadUrl ? user.img : { downloadUrl: './assets/no-profile.png' },
          name: document.exists ? user.name : 'Alumni Universe',
          uid: document.id,
        };

        if (id === 'ALUMNI-UNIVERSE') {
          userResult!.img!.downloadUrl = './assets/alumni-universe-logo-space-color.png';
        }

        return userResult;
      })
    );
  }

  //---------------------------------------------------------------------------------------
  // Forums
  //---------------------------------------------------------------------------------------

  getAllForums() {
    // return this.database.getCollectionSnapshotChanges<Forum>(this.forumsRoute);
    return this.dbFirestore.collection<Forum>(this.forumsRoute)
        .get()
        .pipe(
          map((documents) => {
            return documents.docs.map((document) => {
              return {
                ...document.data(),
                id: document.id,
                reference: document.ref,
              };
            });
          })
        );
  }

  getAllForumsNonArchive(limit: number = 30) {
    return this.dbFirestore.collection<Forum>(this.forumsRoute, 
          ref => ref.where('isArchived', '==', false) 
                    .limit(limit)
        )
        .get()
        .pipe(
          map((documents) => {
            return documents.docs.map((document) => {
              return {
                ...document.data(),
                id: document.id,
                reference: document.ref,
              };
            });
          })
        );
  }

  getAllForumsByFollowers(limit: number = 30, direction: 'asc' | 'desc' = 'desc') {
    return this.dbFirestore.collection<Forum>(this.forumsRoute, 
        ref => ref.orderBy('followers', direction)
                  .where('isArchived', '==', false) 
                  .limit(limit)
      )
      .get()
      .pipe(
        map((documents) => {
          return documents.docs.map((document) => {
            return {
              ...document.data(),
              id: document.id,
              reference: document.ref,
            };
          });
        })
      );
  }

  getAllForumsByDate(limit: number = 30, direction: 'asc' | 'desc' = 'desc') {
    return this.dbFirestore.collection<Forum>(this.forumsRoute, 
      ref => ref.orderBy('dateCreated', direction)
                .where('isArchived', '==', false) 
                .limit(limit)
    )
    .get()
    .pipe(
      map((documents) => {
        return documents.docs.map((document) => {
          return {
            ...document.data(),
            id: document.id,
            reference: document.ref,
          };
        });
      })
    );
  }

  getAllUserForums() {
    if (this.userFollowedForums.value.length > 0 && this.userCreatedForums.value.length > 0) {
      return merge(
        this.userCreatedForums,
        this.userFollowedForums,
      );
    } else {
      return merge(
        this.refreshAllUserCreatedForums(),
        this.refreshAllUserFollowedForums(),
      );
    }
  }

  getAllUserCreatedForums() {
    if (this.userCreatedForums.value.length > 0) {
      return this.userCreatedForums;
    } else {
      return this.refreshAllUserCreatedForums();
    }
  }
  
  private refreshAllUserCreatedForums() {
    return this.authService.userReplay$.pipe(
      map(x => x.uid),
      map(id => {
        if (id) {
          // Devolvemos los documentosen base a los IDs
          return this.dbFirestore.collection<Forum>(this.forumsRoute, 
            ref => ref.orderBy('dateCreated', 'desc')
                      .where('creatorId', '==', id) 
          )
          .get()
          .pipe(
            map((documents) => {
              return documents.docs.map((document) => {
                return {
                  ...document.data(),
                  id: document.id,
                  reference: document.ref,
                };
              });
            })
          );
        } else {
          return of([]);
        }
      }),
      switchMap(x => x),
      // map(x => {
      //   this.userCreatedForums.next(x);
      //   return x;
      // }),
    );
  }

  // FIXME: ---------------------------------------------------------------------------

  async isForumFollowedByUser(forumId: string) {
    const result = await this.authService.userReplay$.pipe(first(),
      map(x => x.forums)).toPromise();
    return result?.includes(forumId);
  }

  async getAllUserFollowedForums(index = 1): Promise<Observable<Forum[]>> {
    if (this.userFollowedForums.value.length > 0) {
      return this.userFollowedForums;
    } else {
      return await this.refreshAllUserFollowedForums(index);
    }
  }

  cleanAllUserFollowedForums() {
    this.followedForums.complete();
    this.followedForums = new ReplaySubject<Forum[]>();
  }

  async refreshAllUserFollowedForums(index = 1) {
    const x = await this.getUserFollowedForums( index).pipe(first()).toPromise();
    this.followedForums.next(x);
    return this.followedForums.asObservable();
  }

  getUserFollowedForums(index = 1) {
    return this.authService.userReplay$.pipe(
      map(x => x.forums),
      map(ids => {
        if (ids && ids?.length > 0) {
          return this.paginateAllUserFollowedForums(ids, index);
        } else {
          return of([]);
        }
      }),
      switchMap(x => x),
    );
  }

  private paginateAllUserFollowedForums(total: string[], index = 1) {
     const sections = Math.ceil(total.length / 10);
     
     if (index > sections) {
       index = sections;
     }
   
     const result =  total.slice(((index - 1) * 10), ((index * 10)));
     return this.dbFirestore.collection<Forum>(this.forumsRoute, 
      ref  => ref.where(firebase.firestore.FieldPath.documentId(), "in", result)
    )
    .get()
    .pipe(
      map((documents) => {
        return documents.docs.map((document) => {
          return { ...document.data(),
            id: document.id,
            reference: document.ref,
          } as Forum;
        })
      })
    );
  }

  getForum(id: string) {
    return this.database.getDocument<Forum>(`${this.forumsRoute}/${id}`);
  }

  async createForum(data: Forum) {
    const urlFriendlyId = convertStringToUrlFriendlyId(data.title as string);
    return this.database.createDocument(this.forumsRoute, urlFriendlyId, {
      ...data,
      creatorId: (await this.authService.userReplay$.pipe(first()).toPromise()).uid,
      dateCreated: firebase.firestore.Timestamp.fromDate(new Date()),
    }).then(() => {
      return urlFriendlyId;
    })
  }

  updateForum(id: string, data: Forum) {
    return this.database.updateDocument(`${this.forumsRoute}/${id}`, data);
  }

  archiveForum(id: string, isArchived: boolean) {
    return this.database.updateDocument<Forum>(`${this.forumsRoute}/${id}`, { isArchived });
  }

  deleteForum(id: string) {
    return this.database.deleteDocument(`${this.forumsRoute}/${id}`);
  }

  private paginateForumFollowers(total: string[], index = 1) {
    const sections = Math.ceil(total.length / 10);
    
    if (index > sections) {
      index = sections;
    }
  
    const result =  total.slice(((index - 1) * 10), ((index * 10)));
    if (result.length > 0) {
      return this.dbFirestore.collection(this.usersRoute,
        ref  => ref.where(firebase.firestore.FieldPath.documentId(), 'in', result)
      )
      .get()
      .pipe(
        map((documents) => {
          return documents.docs.map((document) => {
            return {
              uid: document.id,
              ...document.data() as User
            };
          })
        })
      );
    } else {
      return of([]);
    }
  }

  getForumFollowers(id: string, index = 1) {
    return this.dbFirestore.doc<ForumFollowers>(`${this.forumsRoute}/${id}/followers/info`)
    .get()
    .pipe(
      // Obtenemos la lista de IDs
      map(document => {
        if (document?.data()?.userIds) {
          return document.data()?.userIds as string[];
        } else {
          return [];
        }
      }),
      // Leemos de la base de datos un observable a los usuario
      map((userssId: string[]) => {
        return this.paginateForumFollowers(userssId, index);
      }),
      // Devolvemos los usuarios
      switchMap(x => {
        return x;
      })
    );
  }

  async refreshForumFollowers(id: string, index = 1) {
    const x = await this.getForumFollowers(id, index).pipe(first()).toPromise();
    this.forumFollowers.next(x);
    return this.forumFollowers.asObservable();
  }

  async subscribeToForum(forumId: string) {
    const userId = (await this.authService.userReplay$.pipe(first()).toPromise()).uid;

    return this.dbFirestore.doc(`${this.usersRoute}/${userId}`)
      .update({ forums: firebase.firestore.FieldValue.arrayUnion(forumId) })
      .then(() => {
        this.updateForumFollowersAccount(forumId, 'add');
        this.authService.refreshUserDoc()
        .pipe(first())
        .toPromise()
        .then(() => {
          this.refreshAllUserFollowedForums(); // TODO: Verificar que se este ejecutando a tiempo en diferentes dispositivos.
        });
      })
  }

  async unsubscribeFromForum(forumId: string) {
    const userId = (await this.authService.userReplay$.pipe(first()).toPromise()).uid;

    return this.dbFirestore.doc(`${this.usersRoute}/${userId}`)
      .update({ forums: firebase.firestore.FieldValue.arrayRemove(forumId) })
      .then(() => {
        this.updateForumFollowersAccount(forumId, 'subtract');
        this.authService.refreshUserDoc()
          .pipe(first())
          .toPromise()
          .then(() => {
            this.refreshAllUserFollowedForums(); // TODO: Verificar que se este ejecutando a tiempo en diferentes dispositivos.
          });
      })
  }

  private async updateForumFollowersAccount(id: string, increment: 'add' | 'subtract') {
    // RAMM: La cantidad de followers en el documento de Forum se actualiza con un trigger.
    const userId = (await this.authService.userReplay$.pipe(first()).toPromise()).uid;

    if (increment === 'subtract') { // Si redujo
      this.dbFirestore.doc(`${this.forumsRoute}/${id}/followers/info`)
        .set({userIds: firebase.firestore.FieldValue.arrayRemove(userId)}, {merge: true});
    } else { // Si se agrego
      this.dbFirestore.doc(`${this.forumsRoute}/${id}/followers/info`)
        .set({userIds: firebase.firestore.FieldValue.arrayUnion(userId)}, {merge: true});
    }
  }

  //---------------------------------------------------------------------------------------
  // Posts
  //---------------------------------------------------------------------------------------
  async refreshAllPostsFromToday(index = 1) {
    const result = await this.getAllPostsFromToday(index).pipe(first()).toPromise();
    this.latestPosts.next(result);
    return this.latestPosts.asObservable();
  }
  
  getAllPostsFromToday(index = 1) {
    const today  =  
      firebase.firestore.Timestamp.fromDate(moment().startOf('day').subtract(1, 'day').toDate());
    
      return this.authService.userReplay$
      .pipe(
        switchMap(user => {
          if (!(user?.forums && user?.forums?.length > 0)) {
            return of([]);
          }

          return this.paginatePostsFromToday(user.forums, index, user);
        }),
        map((posts: ForumPost[]) => {
          if (posts.length > 0) {
            return posts;
          } else {
            return [
              {
                title: 'Bienvenido a Alumni Universe',
                images: [{ downloadUrl: './assets/welcome-post-image.jpeg' }],
                dateCreated: firebase.firestore.Timestamp.now(),
                creatorId: 'ALUMNI-UNIVERSE',
              }
            ]
          }
        })
      );

    
  }

  private paginatePostsFromToday(total: string[], index = 1, user: User): Observable<ForumPost[]> {
    let sectIndex = index;
    const sections = Math.ceil(total.length / 10);

    if (index > sections) {
      sectIndex = sections;
    }

    const result = total.slice(((sectIndex - 1) * 10), ((sectIndex * 10)));
    return this.dbFirestore.collectionGroup<ForumPost>(`${this.postRoute}`,
    ref => ref.where('forumId', 'in', result!)
              .where('isArchived', '==', false)
              .orderBy('dateCreated', 'desc')
              .limit(this.latestPostsSize * index) // Al inicio devuelve 3
      )
      .get()
      .pipe(
        // Foros a los que estoy suscrito
        map((documents) => {
          return documents.docs.map((document) => {
            return {
              ...document.data(),
              id: document.id,
              reference: document.ref,
            }
          })
        }),
        // Foros creados por el usuario
        switchMap((subscribedPosts) => {
          return this.dbFirestore.collectionGroup<ForumPost>(`${this.postRoute}`,
          ref => ref.where('creatorId', '==', user.uid!)
                    .where('isArchived', '==', false)
                    .orderBy('dateCreated', 'desc')
                    .limit(this.latestPostsSize * index)
            )
            .get()
            .pipe(
              map((documents) => {
                return [
                  // Posts publicados por el usuario
                  ...documents.docs.slice(this.latestPostsSize * (index - 1), this.latestPostsSize * index)
                    .map((document) => {
                    return {
                      ...document.data(),
                      id: document.id,
                      reference: document.ref,
                    }
                  }),
                  // Post a los que esta suscrito el usuario
                  ...subscribedPosts.slice(this.latestPostsSize * (index - 1), this.latestPostsSize * index)
                ];
              })
            ); 
        }),
        map((posts) => {
          return posts.sort(this.sortForumPostsByDate);
        })
      );
  }

  sortForumPostsByDate(a: ForumPost, b: ForumPost) {
    const aDate = a.dateCreated as firebase.firestore.Timestamp;
    const bDate = b.dateCreated as firebase.firestore.Timestamp;

    if (aDate > bDate) {
      return -1;
    }
    if (aDate < bDate) {
      return 1;
    }
    return 0;
  }

  getAllPostsFromForum(id: string) {
    return this.dbFirestore.collection<ForumPost>(`${this.forumsRoute}/${id}/${this.postRoute}`,
          ref => ref.limit(30)
        )
        .get()
        .pipe(
          map((documents) => {
            return documents.docs.map((document) => {
              return {
                ...document.data(),
                id: document.id,
                reference: document.ref,
              };
            });
          })
        );
  }

  getAllPostsNonArchivedFromForum(id: string, limit: number = 30, direction: 'asc' | 'desc' = 'desc'): Observable<ForumPost[]> {
    return this.dbFirestore.collection<ForumPost>(`${this.forumsRoute}/${id}/${this.postRoute}`,
          ref => ref.orderBy('dateCreated', direction)
                    .where('isArchived', '==', false)
                    .limit(limit)
        )
        .get()
        .pipe(
          map((documents) => {
            return documents.docs.map((document) => {
              return {
                ...document.data(),
                id: document.id,
                reference: document.ref,
              };
            });
          })
        );
  }

  getPost(forumId: string, postId: string) {
    return this.database.getDocument<Forum>(`${this.forumsRoute}/${forumId}/${this.postRoute}/${postId}`);
  }

  async createPost(forumId: string, data: Forum) {
    return this.database.addDocumentToCollection(`${this.forumsRoute}/${forumId}/${this.postRoute}`, {
      ...data,
      forumId: forumId,
      creatorId: (await this.authService.userReplay$.pipe(first()).toPromise()).uid,
      dateCreated: firebase.firestore.Timestamp.now(),
    });
  }

  updatePost(forumId: string, postId: string, data: Forum) {
    return this.database.updateDocument(`${this.forumsRoute}/${forumId}/${this.postRoute}/${postId}`, data);
  }

  archivePost(postRef: firebase.firestore.DocumentReference, isArchived: boolean) {
    return postRef.update({ isArchived });
  }

  deletePost(postRef: firebase.firestore.DocumentReference) {
    return postRef.delete();
  }

  likePost(postRef: firebase.firestore.DocumentReference) {
    return postRef.update({ likes: firebase.firestore.FieldValue.increment(1)})
      .then(() => {
        this.profileService.likeByMe(postRef.id);
      });
  }

  unlikePost(postRef: firebase.firestore.DocumentReference) {
    return postRef.update({ likes: firebase.firestore.FieldValue.increment(-1)})
      .then(() => {
        this.profileService.unlikeByMe(postRef.id);
      });
  }

  //---------------------------------------------------------------------------------------
  // Comentarios
  //---------------------------------------------------------------------------------------

  getAllCommentsFromPostRef(postRef: firebase.firestore.DocumentReference) {
    return this.database.getCollection<ForumComment>(`${postRef.path}/${this.commentsRoute}`);
  }

  getAllCommentsFromPostPath(postPath: string) {
    return this.dbFirestore.collection<ForumComment>(`${postPath}/${this.commentsRoute}`,
        ref => ref.where('isArchive', '==', false)
                .orderBy('dateCreated', 'desc')
      )
      .get()
      .pipe(
        map((documents) => {
          return documents.docs.map((document) => {
            return {
              ...document.data(),
              id: document.id,
              reference: document.ref,
            };
          })
        })
      );
  }

  async createMainComment(postRef: firebase.firestore.DocumentReference, comment: ForumComment) {
    return this.database.addDocumentToCollection(`${postRef.path}/${this.commentsRoute}`, {
      ...comment,
      creatorId: (await this.authService.userReplay$.pipe(first()).toPromise()).uid,
      dateCreated: firebase.firestore.Timestamp.fromDate(new Date()),
      likes: 0,
      comments: [],
    });
  }

  async createMainCommentFromPostPath(postPath: string, comment: ForumComment) {
    const newComment: ForumComment = {
      ...comment,
      creatorId: (await this.authService.userReplay$.pipe(first()).toPromise()).uid,
      dateCreated: firebase.firestore.Timestamp.fromDate(new Date()),
      likes: 0,
      comments: [],
    };
    this.database.addDocumentToCollection(`${postPath}/${this.commentsRoute}`, newComment);

    return newComment;
  }

  editMainComment(commentRef: firebase.firestore.DocumentReference, comment: ForumComment) {
    return this.database.updateDocument<ForumComment>(commentRef.path, { text: getStringOrDefault(comment.text!, '')});
  }

  deleteMainComment(commentRef: firebase.firestore.DocumentReference) {
    return commentRef.delete();
  }

  /**
   * No estara en el MVP.
   * @param commentRef 
   */
  createCommentResponse(commentRef: firebase.firestore.DocumentReference) {

  }

  /**
   * No estara en el MVP. 
   * @param commentRef 
   */
  editCommentResponse() {

  }

  /**
   * No estara en el MVP. 
   * @param commentRef 
   */
  deleteCommentResponse() {

  }
}
