import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter, first, map } from 'rxjs/operators';
import { User } from 'shared/models/user.model';
import { AuthService } from './auth.service';
import firebase from 'firebase/app';
import { cleanObject, makeRandomString } from 'shared/methods/common-methods';
import { Observable, of, ReplaySubject } from 'rxjs';
import { DatabaseService } from './database.service';
import { AuthData } from 'shared/models/auth-data.model';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private get adminsRoute(): string {
    return `admins`;
  }
  public get usersRoute(): string {
    return 'users';
  }

  usersById: ReplaySubject<User[]> = new ReplaySubject<User[]>();

  constructor(private dbFirestore: AngularFirestore,
    private database: DatabaseService,
    private authService: AuthService,
    private snackbar: MatSnackBar) { }

  /**
   * Obtiene a todos los administradores de la plataforma.
   */
  getAllAdmins(): Observable<User[]> {
    try {
      return this.dbFirestore.collection<User>(`${this.adminsRoute}`)
        .snapshotChanges()
        .pipe(
          map((documents) => {
            return documents.map((document) => {
              return {
                ...document.payload.doc.data(),
                id: document.payload.doc.id,
                reference: document.payload.doc.ref,
              };
            });
          }),
          map((admins) => {
            return admins.sort(this.sortUserAlphabetically);
          })
        );
    } catch (error) {
      this.snackbar.open('Ups! Algo salió mal al obtener los invitados',
      undefined, { duration: 5000});
      // console.error('getAllAdmins - Detalles del error: ', error);
      return of([]);
    }
  }

  sortUserAlphabetically(a: User, b: User) {
    const aName = a.name as string;
    const bName = b.name as string;

    if (aName as string < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  }

  /**
   * Obtiene los datos de un usuario en especifico.
   * @param id Uid del usuario.
   */
  getAdmin(id: string) {
    try {
      return this.dbFirestore.doc<User>(`${this.adminsRoute}/${id}`)
        .snapshotChanges()
        .pipe(
          map((document) => {
            return {
              ...document.payload.data(),
              id: document.payload.id,
              reference: document.payload.ref,
            };
          })
        );
    } catch (error) {
      this.snackbar.open('Ups! Algo salió mal al obtener el usuario',
        undefined, { duration: 5000});
      // console.error('getUser - Detalles del error: ', error);
      return of({});
    }
  }

  /**
   * Crea un nuevo usuario en la plataforma, registrandolo en la base de
   * datos de autenticación y la base de datos.
   * @param data Información del usuario.
   * @param password Contraseña. Se sugiere que sea un contraseña provisional. Ya que no será recuperable por la plataforma.
   */
  async createAdmin(data: User, password: string) {
    try {
      // const currentUser = await this.authService.user$.pipe(first()).toPromise();

      this.callableFuncion_CreateAdmin(data, password);
      this.snackbar.open('El usuario estará disponible de 1 a 15 minutos', undefined, {
        duration: 5000
      });
    } catch (error) {
      this.snackbar.open('Ups! Algo salió mal al crear el usuario',
        undefined, { duration: 5000});
      // console.error('createUser - Detalles del error: ', error);
    }
  }

  /**
   * Actualiza los datos del usuario.
   * @param id Id del documento del usuario.
   * @param data Información del usuario.
   */
  async updateAdmin(id: string, data: User) {
    try {
      // const currentUser = await this.authService.user$.pipe(first()).toPromise();

      cleanObject(data);
      this.dbFirestore.doc<User>(`${this.adminsRoute}/${id}`)
        .update({
          ...data,
        })
        .then(() => {
          this.snackbar.open('Usuario actualizado exitosamente.',
            undefined, { duration: 5000});
        });
    } catch (error) {
      this.snackbar.open('Ups! Algo salió mal al actualizar el usuario',
        undefined, { duration: 5000});
      // console.error('updateUser - Detalles del error: ', error);
    }
  }

  /**
   * Desactiva o activa a un usuario. Por defecto lo desactiva.
   * @param id Id del documento del usuario.
   * @param value Indica si el usuario estará(`true`) o no activo(`false`). Por defecto `false`.
   */
  async activateAdmin(id: string, value: boolean = false) {
    try {
      // const currentUser = await this.authService.user$.pipe(first()).toPromise();
      const snackbarText = value ? 'Usuario activado exitosamente.' : 'Usuario desactivado exitosamente.';
      this.dbFirestore.doc<User>(`${this.adminsRoute}/${id}`)
        .update({
          isActive: value,
        })
        .then(() => {
          this.snackbar.open(snackbarText,
            undefined, { duration: 5000});
        });
    } catch (error) {
      this.snackbar.open('Ups! Algo salió mal al actualizar el usuario',
        undefined, { duration: 5000});
      // console.error('updateUser - Detalles del error: ', error);
    }
  }

  deleteAdmin(user: User) {
    try {
      this.callableFuncion_deleteAdmin(user);
    } catch (error) {
      this.snackbar.open('Ups! Algo salió mal al eliminar el usuario.',
        undefined, { duration: 5000});
      // console.error('Detalles del error: ', error);
    }
  }

  // -----------------------------------------------------------------------------
  // Usuarios
  // -----------------------------------------------------------------------------
  getAllUsers() {
    return this.database.getCollectionSnapshotChanges<User>(`${this.usersRoute}`);
  }

  getUser(id: string) {
    return this.database.getDocument<User>(`${this.usersRoute}/${id}`);
  }

  getUsersById(ids: string[], index = 1) {
    if (ids.length > 0) {
      const sections = Math.ceil(ids.length / 10);
  
      if (index > sections) {
        index = sections;
      }
  
      const result = ids.slice(((index - 1) * 10), ((index * 10)));
      // // console.log('result: ', result);
      return this.dbFirestore.collection(this.usersRoute, 
        ref  => ref.where(firebase.firestore.FieldPath.documentId(), 'in', result!)
      )
      .get()
      .pipe(
        map((documents) => {
          // return documents.docs.filter((x) => ids.includes(x.id)).map((document) => {
          return documents.docs.map((document) => {
            return {
              uid: document.id,
              ...document.data() as User
            };
          }).filter((x) => ids.includes(x.uid))
        })
      );
    } else {
      return of([]);
    }
  }

  async refreshUsersById(ids: string[], index = 1) {
    const x = await this.getUsersById(ids, index).pipe(first()).toPromise();
    this.usersById.next(x);
    return this.usersById.asObservable();
  }

  createUserWithLoginInfo(data: User, password: string) {
    this.callableFuncion_CreateUser(data, password)
      .then(() => {
        this.snackbar.open('El usuario estará disponible de 1 a 15 minutos', undefined, {
          duration: 5000
        });
      })
      .catch ((error) => {
        this.snackbar.open('Ups! Algo salió mal al crear el usuario',
          undefined, { duration: 5000});
        // console.error('createUser - Detalles del error: ', error);
      });
  }

  createUserWithNOLogin(data: User) {
    this.callableFuncion_CreateUser(data, makeRandomString());
  }

  /**
   * Actualiza los datos del usuario.
   * @param id Id del documento del usuario.
   * @param data Información del usuario.
   */
  async updateUser(id: string, data: User) {
    try {
      // const currentUser = await this.authService.user$.pipe(first()).toPromise();

      cleanObject(data);
      this.dbFirestore.doc<User>(`${this.usersRoute}/${id}`)
        .update({
          ...data,
          lastModifiedDate: firebase.firestore.Timestamp.now(),
        })
        .then(() => {
          this.snackbar.open('Usuario actualizado exitosamente.',
            undefined, { duration: 5000});
        });
    } catch (error) {
      this.snackbar.open('Ups! Algo salió mal al actualizar el usuario',
        undefined, { duration: 5000});
      // console.error('updateUser - Detalles del error: ', error);
    }
  }

  /**
   * Desactiva o activa a un usuario. Por defecto lo desactiva.
   * @param id Id del documento del usuario.
   * @param value Indica si el usuario estará(`true`) o no activo(`false`). Por defecto `false`.
   */
  async activateUser(id: string, value: boolean = false) {
    try {
      // const currentUser = await this.authService.user$.pipe(first()).toPromise();
      const snackbarText = value ? 'Usuario activado exitosamente.' : 'Usuario desactivado exitosamente.';
      this.dbFirestore.doc<User>(`${this.usersRoute}/${id}`)
        .update({
          isActive: value,
        })
        .then(() => {
          this.snackbar.open(snackbarText,
            undefined, { duration: 5000});
        });
    } catch (error) {
      this.snackbar.open('Ups! Algo salió mal al actualizar el usuario',
        undefined, { duration: 5000});
      // console.error('updateUser - Detalles del error: ', error);
    }
  }

  // deleteUser(user: User) {
  //   try {
  //     this.callableFuncion_deleteUser(user);
  //   } catch (error) {
  //     this.snackbar.open('Ups! Algo salió mal al eliminar el usuario.',
  //       undefined, { duration: 5000});
  //     // console.error('Detalles del error: ', error);
  //   }
  // }
  // ---------------------------------------------------------------------------------------
  // Config
  // ----------------------------------------------------------------------------------------

  updateCategoryCoupons(categoriesV: string[]): void {

    try {
      this.database.updateDocument(`config/coupon-categories`, {
        categories: [...categoriesV]
      }).then(() => {
        this.snackbar.open('Se ha actualizado las categorias correctamente', undefined, { duration: 5000});
      });
    } catch (error) {
      // console.log(error);
    }
  }

  updateCategoriesMarketplace(categoriesV: string[]): void {
    try {
      this.database.updateDocument(`/config/marketplace-categories`, {
        categories: [...categoriesV]
      }).then(() => {
        this.snackbar.open('Se ha actualizado las categorias correctamente', undefined, { duration: 5000});
      });
    } catch (error) {
      // console.log(error);
    }
  }

  getAllMarketplaceCategories(): Observable<string[]> {
    return this.database.getDocument<string[]>(`/config/marketplace-categories`);
  }

  getAllCouponsCategories(): Observable<string[]> {
    return this.database.getDocument<string[]>(`/config/coupon-categories`);
  }

  // -------------------------------------------------------------------------------------------
  // Methods to Callable Functions
  // -------------------------------------------------------------------------------------------

  /**
   * Crea un nuevo usuario con una contraseña provisional
   * @param user Datos del usuario a crear.
   * @param password Contraseña provisional creada por un administrador.
   */
  private async callableFuncion_CreateAdmin(user: User, password: string) {
    try {
      const currentUserUid = (await this.authService.userReplay$.pipe(first()).toPromise()).uid;
      if (user && currentUserUid) {
        cleanObject(user);
        const authData: AuthData = {
          email: user.email as string,
          password,
        };

        // Llamamos a la function de Cloud Functions para crear el usuario con el Admin SDK
        // tslint:disable-next-line: object-literal-shorthand
        firebase.functions().httpsCallable('createAdmin')({authData: authData, user: user, creatorUid: currentUserUid});
      } else {
        throw new Error('No se puede crear el usuario debido a que no hay información del mismo');
      }
    } catch (error) {
      // console.error('createUser - createUser: ', error);
      this.snackbar.open('Ups! Algo salió mal al reiniciar la contraseña del usuario',
        undefined, { duration: 5000});
    }
  }

  async callableFuncion_deleteAdmin(user: User) {
    try {
      const currentUserUid = this.authService.userId$.value;

      if (user && currentUserUid) {
        cleanObject(user);
        // this.blockUser(user.uid as string);
        // Eliminamos al usuario de la aplicación
        // tslint:disable-next-line: object-literal-shorthand
        firebase.functions().httpsCallable('deleteUser')({user: user, creatorUid: currentUserUid});

      } else {
        throw new Error('No se puede eliminar el usuario');
      }
    } catch (error) {
      // console.error('createUser - createUser: ', error);
      this.snackbar.open('Ups! Algo salió mal al eliminar el usuario',
        undefined, { duration: 5000});
    }
  }

  /**
   * Crea un nuevo usuario con una contraseña provisional
   * @param user Datos del usuario a crear.
   * @param password Contraseña provisional creada por un administrador.
   */
  private async callableFuncion_CreateUser(user: User, password: string) {
    try {
      const currentUserUid = (await this.authService.userReplay$.pipe(first()).toPromise()).uid;
      if (user && currentUserUid) {
        cleanObject(user);
        const authData: AuthData = {
          email: user.email as string,
          password,
        };
        // Llamamos a la function de Cloud Functions para crear el usuario con el Admin SDK
        // tslint:disable-next-line: object-literal-shorthand
        firebase.functions().httpsCallable('createUser')({authData: authData, user: user, creatorUid: currentUserUid});
      } else {
        throw new Error('No se puede crear el usuario debido a que no hay información del mismo');
      }
    } catch (error) {
      // console.error('createUser - createUser: ', error);
      this.snackbar.open('Ups! Algo salió mal al reiniciar la contraseña del usuario',
        undefined, { duration: 5000});
    }
  }
}
