<div fxLayout="column" fxLayoutAlign="center"
  fxLayoutGap="20px">
  <div>
    <h1 style="color: #011E41" *ngIf="data">{{ data }}</h1>
    <p>¿Está seguro que desea hacer esto?</p>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center"
    fxLayoutGap="20px">
    <button mat-raised-button
      (click)="onCancel()">No, quiero regresar</button>
    <button mat-raised-button
      (click)="onAccept()"
      color="primary">Sí</button>
  </div>
</div>