<div fxLayout="column" fxLayoutAlign="center"
  fxLayoutGap="20px" style="padding: 20px;">
  <div>
    <h3 *ngIf="data">{{ data }}</h3>
    <p>¿Está seguro de que desea hacer esto?</p>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center"
    fxLayoutGap="20px">
    <button mat-raised-button
      (click)="onCancel()">Cancelar</button>
    <button mat-raised-button color="primary"
      (click)="onAccept()">Aceptar</button>
  </div>
</div>