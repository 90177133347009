import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import { cleanObject, lowercaseAndTrimString } from 'shared/methods/common-methods';
import { AcceptanceRequest } from 'shared/models/acceptance-request.model';
import { User } from 'shared/models/user.model';
import { AuthService } from './auth.service';
import { DatabaseService } from './database.service';

@Injectable({
  providedIn: 'root'
})
export class AcceptanceRequestService {
  
  private get requestsRoute(): string {
    return `acceptance-requests`;
  }
constructor(private dbFirestore: AngularFirestore,
            private database: DatabaseService,
            private authService: AuthService,
            private snackbar: MatSnackBar) { }

  getAllRequests() {
    return this.dbFirestore.collection<AcceptanceRequest>(`${this.requestsRoute}`,
        ref => ref.where('isAccepted', '==', true)
      )
      .get()
      .pipe(
        map((documents) => {
          return documents.docs.map((document) => {
            return {
              ...document.data(),
              id: document.id,
              reference: document.ref,
            }
          })
        }),
        map(x => x.sort(this.sortByDate))
      );
  }

  getActiveRequests(isAccepted = false) {
    return this.dbFirestore.collection<AcceptanceRequest>(`${this.requestsRoute}`,
      ref => ref.where('isAccepted', '==', isAccepted)
                .where('isArchived', '==', false)
      )
      .get()
      .pipe(
        map((documents) => {
          return documents.docs.map((document) => {
            return {
              ...document.data(),
              id: document.id,
              reference: document.ref,
            }
          })
        }),
        map(x => x.sort(this.sortByDate))
      );
  }

  sortByDate(a: AcceptanceRequest, b: AcceptanceRequest) {
    if (a.dateCreated! > b.dateCreated!) {
      return -1;
    }
    if (a.dateCreated! < b.dateCreated!) {
      return 1;
    }
    return 0;
  }

  get(id: string) {
    return this.database.getDocument<AcceptanceRequest>(`${this.requestsRoute}/${id}`);
  }

  create(data: AcceptanceRequest) {
    // // console.log(data);
    const newRequest = {...data};
    // Eliminamos del objeto la informacion de su contrasena para la solicitud
    (<any>newRequest)["confirmEmail"] = undefined;
    (<any>newRequest)["confirmPassword"] = undefined;
    (<any>newRequest)["password"] = undefined;
    
    cleanObject(newRequest);

    return this.database.addDocumentToCollection(this.requestsRoute, newRequest)
      .then((res) => {
        const newUser: User = {
          name: lowercaseAndTrimString(`${data.firstName} ${data.lastName}`),
          email: lowercaseAndTrimString(data.email!),
          isActive: false,
          isWaitingTG: false,
        };
        // Implementar creación de usuario en auth y en doc
        this.authService.createUserWithLoginInfoFromSubscriptionRequest(newUser, data.password!, res.id);
        // console.log('successful creation!');
      })
      .catch((err) => {
        // console.error('Error: ', err);
      });
  }

  update(id: string, data: AcceptanceRequest) {
    return this.database.updateDocument(`${this.requestsRoute}/${id}`, data);
  }

  delete(id: string) {
    return this.database.deleteDocument(`${this.requestsRoute}/${id}`);
  }
}
