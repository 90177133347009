import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { first, map, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import firebase from 'firebase/app';
import { DatabaseService } from './database.service';
import { User } from 'shared/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class FriendshipService {

  public get usersRoute(): string {
    return 'users';
  }

  constructor(private dbFirestore: AngularFirestore,
    private database: DatabaseService,
    private authService: AuthService) { }

  getAllUsers() {
    // return this.database.getCollectionSnapshotChanges<User>(`${this.usersRoute}`);
    return this.dbFirestore.collection(this.usersRoute,
        ref => ref.where('isActive', '==', true)
      )
      .get()
      .pipe(
        map((documents) => {
          return documents.docs.map((document) => {
            return {
              ...document.data() as User,
              uid: document.id,
              reference: document.ref,
            } as User;
          })
        })
      );
  }

  getMyFriends() {
    return this.authService.userReplay$
      .pipe(
        switchMap(user => {
          return this.dbFirestore.doc(`users/${user.uid}/friends/info`)
            .get()
            .pipe(
              map(x => x.data())
            );
        })
      );

  }

  getMyRequests() {
    return this.authService.userReplay$
      .pipe(
        switchMap(user => {
          return this.dbFirestore.doc(`users/${user.uid}/friends-request/info`)
            .get()
            .pipe(
              map(x => x.data())
            );
        })
      );

  }

  async requestFriendship(requetUserId: string) {
    const user = await this.authService.userReplay$.pipe(first()).toPromise();
    return this.dbFirestore.doc(`users/${requetUserId}/friends-request/info`)
      .set({friendRequests: firebase.firestore.FieldValue.arrayUnion(user.uid)}, {merge: true})
      .then((ans) => {
        // // console.log(ans);
        // Registramos la  solicitud de amistad
        this.dbFirestore.doc(`users/${user.uid}/friends/info`)
          .set({friendRequestsSended: firebase.firestore.FieldValue.arrayUnion(requetUserId)}, {merge: true})
          .then((res) => {
            // // console.log(res);
            return true;
          })
          .catch((error) => {
            return false;
          })
          return true;
      })
      .catch((error) => {
        return false;
      })
  }

  async acceptFriendshipRequest(requestUserid: string) {
    const user = await this.authService.userReplay$.pipe(first()).toPromise();
    // Removemos el ID del request
    this.dbFirestore.doc(`users/${user.uid}/friends-request/info`)
      .set({friendRequests: firebase.firestore.FieldValue.arrayRemove(requestUserid)}, {merge: true})
      .then(() => {
        // Y lo pasamos al arreglo de amistades confirmadas
        this.dbFirestore.doc(`users/${user.uid}/friends/info`)
          .set({friends: firebase.firestore.FieldValue.arrayUnion(requestUserid)}, {merge: true})

        // Borramos la solicitud de amistad
        this.dbFirestore.doc(`users/${requestUserid}/friends/info`)
          .set({friendRequestsSended: firebase.firestore.FieldValue.arrayRemove(user.uid)}, {merge: true})
          .then(() => {
             // Y lo pasamos al arreglo de amistades confirmadas
            this.dbFirestore.doc(`users/${requestUserid}/friends/info`)
            .set({friends: firebase.firestore.FieldValue.arrayUnion(user.uid)}, {merge: true});
              });
      });
  }

  async denyFriendship(requestUserId: string) {
    const user = await this.authService.userReplay$.pipe(first()).toPromise();
    this.dbFirestore.doc(`users/${user.uid}/friends-request/info`)
      .set({friendRequests: firebase.firestore.FieldValue.arrayRemove(requestUserId)}, {merge: true})
  }

  removeFriendship(id: string) {
    // TODO: Llamada a Cloud Function para que remueva la amistad
  }
}
