import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { StoragedFile } from 'shared/models/storaged-file.model';
import { TranslateService } from 'shared/services/translate.service';
import * as moment from 'moment';
import { environment } from 'projects/client/src/environments/environment';
import { AcceptanceRequest } from 'shared/models/acceptance-request.model';
import { AcceptanceRequestService } from 'shared/services/acceptance-request.service';
import { AuthService } from 'shared/services/auth.service';
import { StorageService } from 'shared/services/storage.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import firebase from 'firebase/app';

declare let paypal: any;
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, OnDestroy {
  content: any;
  addScript = false;
  paypalButtons = false;
  hidePaypal = false;
  showPassword = false;
  showConfirmPassword = false;
  verifyingFile!: StoragedFile;
  fileIsNew = false;

  signUpForm = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', Validators.required],
    password: ['', Validators.required],
    businessPhone: ['', Validators.required],
    institution: ['', Validators.required],
    institutionGeneration: ['', Validators.required],
  });

  @ViewChild('verifyDialog', { static: true }) verify!: TemplateRef<any>;
  requestWasSuccessfull = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              private fb: FormBuilder,
              private acceptanceService: AcceptanceRequestService,
              private dialog: MatDialog,
              private snackbar: MatSnackBar,
              private storageService: StorageService,
              private translateService: TranslateService) {
    this.translateService.dataObservable.subscribe((data: any) => {
      this.content = data;
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (!this.requestWasSuccessfull && this.verifyingFile?.fullPath) {
      // Deleting file
      this.storageService.deleteFile(this.verifyingFile.fullPath);
    }
  }

  onPayByPaypal(): void {
    this.paypalButtons = true;

    if (!this.addScript) {

      this.addPaypalScript().then((response) => {
        if (response) {
          // paypal.Button.render(this.paypalConfig, '#paypal-checkout-btn');
          paypal.Buttons({
            createOrder: (data: any, actions: any) => {
              // Set up the transaction
              return actions.order.create({
                purchase_units: [{
                  amount: {
                    // tslint:disable-next-line: max-line-length
                    value:  environment.membershipPrice,
                  }
                }]
              });
            },
            onApprove: (data: any, actions: any) => {
              // Capture the funds from the transaction
              return actions.order.capture().then((details: any) => {
                // Show a success message to your buyer
                // // console.log(details);
                this.onSavePayment(details);
              })
              .catch((err: any) => {
                // console.log(err);
              })
              ;
            }
          }).render('#paypal-checkout-btn');
        }
      });
      // this.addScript = true;
    }
  }

  onSavePayment(details?: any): void {
    // Pago por paypal exitoso
    const newRequest: AcceptanceRequest = {
      ...this.signUpForm.value as AcceptanceRequest,
      isAccepted: false,
    }
    newRequest.dateCreated = firebase.firestore.Timestamp.now();
    
    console.log(newRequest);
    // Creamos al usuario en auth y bajo users
    this.acceptanceService.create(newRequest);

    // Mostramos la pantalla de que se realizó con éxito la solicitud
    this.onShowSuccessScreen();
  }

  addPaypalScript(): Promise<any> {
    this.addScript = true;
    return new Promise((resolve, reject) => {
      // tslint:disable-next-line:prefer-const
      let scripttagElement = document.createElement('script');

      // Creamos el link de pago
      if (environment.paypalEnvSelector === 'sandbox') {
        // tslint:disable-next-line: max-line-length
        scripttagElement.src = `https://www.paypal.com/sdk/js?client-id=${environment.paypalSandboxToken}&currency=USD`;
      } else {
        // tslint:disable-next-line: max-line-length
        scripttagElement.src = `https://www.paypal.com/sdk/js?client-id=${environment.paypalProductionToken}&currency=USD`;
      }
      
      scripttagElement.onload = resolve;
      document.body.appendChild(scripttagElement);
    });
  }

  onShowVerifyDialog() {
    this.dialog.open(this.verify, {
      width: '90%',
      maxWidth: '330px',
      height: '40%',
      maxHeight: '220px',
    });
  }

  onShowSuccessScreen() {
    this.requestWasSuccessfull = true;
  }

  onClose() {
    this.dialog.closeAll();
  }

  public async onUploadFile(event: any, isNewFile: boolean) {
    let fileRoute = `acceptance-requests`;
    
    if (event.target.files.length > 0) {
      const filePromise = isNewFile ? this.storageService.managedUploadFile(event, null!, true, fileRoute) : this.storageService.managedUploadFile(event, this.verifyingFile, true, fileRoute);
      this.verifyingFile = await filePromise;
      this.snackbar.open(`Se subió el archivo ${this.verifyingFile.name}.`,
        undefined, { duration: 5000});
    } else {
      this.snackbar.open('No se pudo subir la imagen. Verifique si el tipo del archivo corresponde a una imagen.',
        undefined, { duration: 5000});
    }
  }

  // public onDeleteFile(index: number) {
  //   // Abrimos una el dialogo para preguntarle si esta seguro
  //   const dialogRef = this.dialog.open(ConfirmDialogComponent,
  //     { width: '350px', data: 'Eliminar archivo'});
  //   // Si confirmo que desea continuar
  //   this.subscriptions.push(
  //     dialogRef.componentInstance.confirmSave.subscribe((confirm: boolean) => {
  //       if (confirm) {
  //         this.storageService.deleteFile(this.images[index].fullPath!);
  //         if (this.mode === 'job') {
  //           if (index === 0) {
  //             this.jobImage = {};
  //           } else {
  //             this.companyLogo = {};
  //           }
  //         } else {
  //           this.images.splice(index, 1);
  //         }
  //       }
  //     })
  //   );
  // }

}
