<div class="signup-container" fxLayout="column" fxLayoutAlign="start center">
  
  <mat-horizontal-stepper color="accent" [linear]="true" #stepper>
    <!-- ------------------------------------------------------------------------ -->
    <!-- SIGNUP DATA -->
    <!-- ------------------------------------------------------------------------ -->
    <mat-step label="{{content.sign_up_step_1_name}}" [stepControl]="signUpForm">
      <form [formGroup]="signUpForm"
        fxLayout="column" fxLayoutAlign="start center"
        fxLayoutGap="0px">
        <span class="step-title">{{content.sign_up_step_1_title}}</span>
        <span class="step-subtitle">{{content.sign_up_step_1_subtitle}}</span>

        <!-- Nombre -->
        <mat-form-field appearance="outline" class="step-two-input">
          <mat-label>{{content.sign_up_step_2_firstname_input_placeholder}}</mat-label>
          <input matInput placeholder="{{content.sign_up_step_2_firstname_input_placeholder}}"
            type="text" formControlName="firstName">
        </mat-form-field>

        <!-- Apellidos -->
        <mat-form-field appearance="outline" class="step-two-input">
          <mat-label>{{content.sign_up_step_2_lastname_input_placeholder}}</mat-label>
          <input matInput placeholder="{{content.sign_up_step_2_lastname_input_placeholder}}"
          type="text" formControlName="lastName">
        </mat-form-field>

        <!-- Nombre de la escuela -->
        <mat-form-field appearance="outline" class="step-two-input">
          <mat-label>{{content.sign_up_step_4_institution_label}}</mat-label>
          <input matInput placeholder="{{content.sign_up_step_4_institution_placeholder}}"
            type="text" formControlName="institution">
        </mat-form-field>

        <!-- Generacion de la escuela -->
        <mat-form-field appearance="outline" class="step-two-input">
          <mat-label>{{content.sign_up_step_4_institution_generation_label}}</mat-label>
          <input matInput placeholder="{{content.sign_up_step_4_institution_generation_placeholder}}"
            type="number" formControlName="institutionGeneration">
        </mat-form-field>

        <!-- Telefono de empresa -->
        <mat-form-field appearance="outline" class="step-two-input">
          <mat-label>{{content.sign_up_step_4_business_phone}}</mat-label>
          <input matInput type="tel" formControlName="businessPhone">
        </mat-form-field>

        <!-- Correo -->
        <mat-form-field appearance="outline" class="step-two-input">
          <mat-label>{{content.sign_up_step_2_email_input_placeholder}}</mat-label>
          <input matInput placeholder="{{content.sign_up_step_2_email_input_placeholder}}"
            type="email" formControlName="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
          <mat-error *ngIf="!signUpForm.value.email.valid">
            Por favor ingrese un correo válido.
          </mat-error>
        </mat-form-field>

        <!-- Contrasena -->
        <div class="step-two-input" fxLayout="row" fxLayoutAlign="space-between center">
          <mat-form-field appearance="outline" fxFlex="85">
            <mat-label>{{content.sign_up_step_2_password_input_placeholder}}</mat-label>
            <input matInput placeholder="{{content.sign_up_step_2_password_input_placeholder}}"
            [type]="showPassword ? 'text' : 'password'" formControlName="password"
            pattern="^[a-zA-Z0-9!?.\-\_@#$%^&*]{8,}$">
            <mat-hint>Al menos 8 caracteres</mat-hint>
          </mat-form-field>
          <button mat-icon-button type="button" (click)="showPassword = !showPassword"><mat-icon>visibility</mat-icon></button>
        </div>
        
        <!-- Boton siguiente -->
        <div fxLayout="column" fxLayoutAlign="start center"
          fxLayoutGap="15px">
          <!-- Boton -->
          <button mat-button matStepperNext 
            class="stepper-next-button" 
            [disabled]="!signUpForm.valid"
            (click)="!paypalButtons ? onPayByPaypal() : '';"
            type="button">{{content.sign_up_step_4_proceed_to_pay_button}}</button>
          <!-- Link a inicio de sesion -->
          <span>{{content.sign_up_already_have_account_label}} <span class="link" routerLink="/login">{{content.sign_up_already_have_account_link}}</span></span>
        </div>
      </form>
    </mat-step>

    <!-- ------------------------------------------------------------------------ -->
    <!-- Step 5 -->
    <!-- ------------------------------------------------------------------------ -->
    <mat-step label="{{content.sign_up_step_5_name}}">
      <div id="paypal-checkout-btn"
        matTooltip="Pagar">
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>

<!-- Recuadro de agregar imagen -->
<ng-template #verifyDialog>
  <div class="verify-dialog"
    fxLayout="column" fxLayoutAlign="space-between">
    <div fxLayout="column" fxLayoutAlign="center center">
      <strong>¿Te gustaría acelerar tu proceso de Verificación subiendo una foto de un certificado o diploma de tu escuela Perteneciente a Semper Altius?</strong>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="column" fxLayoutAlign="center center">

      <div *ngIf="verifyingFile">
        <span [matMenuTriggerFor]="menuDownloadbleMedia" class="link">{{verifyingFile.name}}</span>
        <mat-menu #menuDownloadbleMedia="matMenu">
          <a style="text-decoration: none;" href="{{ verifyingFile.downloadUrl }}" target="_blank">
            <button mat-menu-item>
              Descargar
            </button>
          </a>
          <button mat-menu-item
            (click)="uploadImage1.click(); fileIsNew = false;"
            type="button">
            Editar
          </button>
          <!-- <button mat-menu-item
            (click)="onDeleteFile(0)"
            type="button">
            Borrar
          </button>          -->
        </mat-menu>
      </div>
      <button mat-button color="primary" *ngIf="!verifyingFile" type="button"
      (click)="uploadImage1.click(); fileIsNew = true;">Subir archivo</button>
      <input hidden type="file"
        #uploadImage1 accept="image/*, application/pdf"
        (change)="onUploadFile($event, fileIsNew)">
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="column" fxLayoutAlign="center center">
      <span (click)="onClose()">Cerrar</span>
    </div>
  </div>
</ng-template>

<!-- ------------------------------------------------------------------------ -->
<!-- Registro exitoso -->
<!-- ------------------------------------------------------------------------ -->
<app-successful-publication *ngIf="requestWasSuccessfull"
[title]="'¡Felicidades!'"
[subtitle]="'Has terminado tu proceso de aplicación. Estaremos revisando tu solicitud y en los siguientes días te enviaremos un correo de admisión.'"
[buttonInfo]="{text: 'TERMINAR', redirectTo: '/'}"
[bgColor]="'#071E3F'"
></app-successful-publication>