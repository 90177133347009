import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminConfirmDialogComponent } from 'projects/admin/src/app/common/admin-confirm-dialog/admin-confirm-dialog.component';
import { Subscription } from 'rxjs';
import { StoragedFile } from 'shared/models/storaged-file.model';
import { UserProfessionalProfile, UserProfessionalProfileBasicInfo } from 'shared/models/user-professional-profile.model';
import { User } from 'shared/models/user.model';
import { AuthService } from 'shared/services/auth.service';
import { ProfileService } from 'shared/services/profile.service';
import { StorageService } from 'shared/services/storage.service';
import { TranslateService } from 'shared/services/translate.service';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent implements OnInit, OnDestroy {

  content: any;
  user: User = { img: {}};
  profile!: UserProfessionalProfile;
  private subscriptions: Subscription[] = [];
  userImage!: StoragedFile;
  imageIsNew = true;
  wasImageUpdated = false;

  profileForm = this.fb.group({
    name: [''],
    school: [''],
    instagram: [''],
    twitter: [''],
    skype: [''],
  });

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              private profileService: ProfileService,
              private authService: AuthService,
              private fb: FormBuilder,
              private dialog: MatDialog,
              private snackbar: MatSnackBar,
              private storageService: StorageService,
              private translateService: TranslateService) {
    this.translateService.dataObservable.subscribe((data: any) => {
    this.content = data;
    this.getDataFromResolver();
    });
  }
  /**
   * Obtiene los datos de la tarea que nos da el resolver.
   */
  private getDataFromResolver() {
    // Obtenemos los datos de la tarea
    this.subscriptions.push(
      this.authService.userReplay$
        .subscribe((user: User) => {
          if (user) {
            this.user = user;
            this.subscriptions.push(this.profileService.getProfessionalProfile()
            .subscribe((profile: UserProfessionalProfile) => {
              this.profile = {...profile};
              this.profileForm = this.fb.group({
                name: [this.user.name],
                school: [this.user.school],
                instagram: [this.profile.instagram],
                twitter: [this.profile.twitter],
                skype: [this.profile.skype],
              });
            }));
          }
        })
    );
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  onEdit() {
    const newUser: User = this.profileForm.value as User;
    const newSocial: UserProfessionalProfile = this.profileForm.value as UserProfessionalProfile;
    if (this.wasImageUpdated) {
      newUser.img = this.user.img;
    }
    // console.log(newUser);
    // console.log(newSocial);

    this.profileService.updateProfile(
      { name: newUser.name, school: newUser.school , img: this.user.img && this.wasImageUpdated ? this.user.img : undefined}, 
      { instagram: newSocial.instagram, twitter: newSocial.twitter }
    );
  }

  public async onUploadFile(event: any, isNewFile: boolean) {
    const fileRoute = `profile`;

    if (event.target.files.length > 0
      && this.storageService.imageFileTypes.includes(event.target.files[0].type)) {
      this.wasImageUpdated = true;
      if (isNewFile) {
        const filePromise = this.storageService.managedUploadFile(event, null!, true, fileRoute);
        this.user.img = await filePromise;
      } else {
        const filePromise = this.storageService.managedUploadFile(event, this.user?.img!, true, fileRoute);
        this.user.img = await filePromise;
      }
    } else {
      this.snackbar.open('No se pudo subir la imagen. Verifique si el tipo del archivo corresponde a una imagen.',
        undefined, { duration: 5000});
    }
  }

  public onDeleteFile() {
    // Abrimos una el dialogo para preguntarle si esta seguro
    const dialogRef = this.dialog.open(AdminConfirmDialogComponent,
      { width: '350px', data: 'Eliminar archivo'});
    // Si confirmo que desea continuar
    this.subscriptions.push(
      dialogRef.componentInstance.confirmSave.subscribe((confirm: boolean) => {
        if (confirm) {
          this.storageService.deleteFile(this.user?.img?.fullPath!);
          this.user.img = {};
        }
      })
    );
  }
}
