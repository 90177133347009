import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { first, map, switchMap } from 'rxjs/operators';
import { Notification } from 'shared/models/notifications.model';
import { AuthService } from './auth.service';
import firebase from 'firebase/app';
import { User } from 'shared/models/user.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  notificationsQuantity$: Subject<number> = new Subject<number>();
  currentToken!: string;

  constructor(private dbFirestore: AngularFirestore,
              private authService: AuthService,
              private snackbar: MatSnackBar) { }

  getAllNotifications() {
    return this.authService.userReplay$
      .pipe(
        switchMap(user => {
          return this.dbFirestore.collection<Notification>(`users/${user.uid}/notifications`,
            ref => ref.orderBy('dateCreated', 'desc')
                      .where('isArchived', '==', false)
                      .limit(30)
          )
            .snapshotChanges()
            .pipe(
              map((documents) => {
                this.notificationsQuantity$.next(documents.length);
                return documents.map((document) => {
                  return {
                    ...document.payload.doc.data(),
                    id: document.payload.doc.id,
                    reference: document.payload.doc.ref,
                  }
                })
              })
            );
        })
      );
  }

  markNotificationAsRead(ref: firebase.firestore.DocumentReference) {
    return ref.update({isRead: true});
  }
  
  markNotificationAsArchived(ref: firebase.firestore.DocumentReference) {
    return ref.update({isArchived: true});
  }
  
  deleteNotification(ref: firebase.firestore.DocumentReference) {
    return this.dbFirestore.doc(ref.path).delete();
  }

  /**
   * Petición al usuario para enviar notificaciones.
   */
  requestPermission() {
    // Obtenemos el permiso del usuario para enviarle notificaciones
    firebase.messaging().requestPermission()
      .then(() => {
        firebase.messaging().getToken().then((token) => {
            // Obtenemos el token del dispositivo
            // console.log('Device Token: ', token);
            const notificationService = this;
            notificationService.currentToken = token;
            this.updateUserToken(token);
          });
      }).catch((error) => {
        this.snackbar.open(`No se pudo  obtener permiso para notificar. Favor de reportar.`, null!, {duration: 5000});
        // console.error('No se pudo obtener permiso para notificar', error);
      });
  }

  /**
   * Actualiza el token del usuario actual en el documento de FIrestore.
   * @param token Token del dispositivo
   */
  async updateUserToken(token: string) {
    const user = await this.authService.userReplay$.pipe(first()).toPromise();
    this.dbFirestore.doc<User>(`users/${user.uid}`).update({token: token});
  }
}
