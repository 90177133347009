import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings-reports-list',
  templateUrl: './settings-reports-list.component.html',
  styleUrls: ['./settings-reports-list.component.scss']
})
export class SettingsReportsListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
