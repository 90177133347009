<div class="container"
  fxLayout="column" fxLayoutAlign="start">
  <form #f="ngForm" class="general-search-container"
    fxLayout="column" fxLayoutAlign="start" fxLayoutGap="20px">
    <!-- Título -->
    <div class="title-container" fxLayout="column" fxLayoutAlign="start">
      <div class="top-shape-container">
        <div class="top-shape" [ngStyle]="{'background-color': color ? color : '#898A8D'}"></div>
      </div>
      <!-- <span class="title-info">{{title ? title : 'DEFAULT'}}</span> -->
    </div>
    <!-- Filtros -->
    <mat-button-toggle-group multiple class="search-info-container"
      fxLayout="column" fxLayoutAlign="start center"
      fxLayoutGap="10px" [value]="filters[0].value"
      required>
      <span class="offer-subtitle">{{firstSubtitle}}</span>
      <mat-button-toggle class="filters-element" *ngFor="let filter of filters"
        (click)="filter.checked = !filter.checked; onClickFilter(filter)"
        value="{{filter.value}}">
        <div fxLayout="column" fxLayoutAlign="center center">
          <mat-icon>crop_portrait</mat-icon>
          <span>
            {{filter.title | titlecase}}
          </span>
        </div>
      </mat-button-toggle>
    </mat-button-toggle-group>
    <!-- Categoría -->
    <div class="search-info-container" *ngIf="categoryAvailable"
      fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
      <span class="offer-subtitle" fxFlexAlign="start">2) Selecciona una categoría</span>
      <mat-form-field appearance="outline">
        <mat-select required name="categorySelect" #categorySelect="ngModel" ngModel
          placeholder="Categoría">
          <mat-option value="all">
            Todas las categorías
          </mat-option>
          <mat-option *ngFor="let category of categoriesSelector" [value]="category">
            {{category | titlecase}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- Estado -->
    <div class="search-info-container"
      fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
      <span class="offer-subtitle" fxFlexAlign="start"><span *ngIf="categoryAvailable">3)</span><span *ngIf="!categoryAvailable">2)</span> Selecciona una región</span>
      <mat-form-field appearance="outline">
        <mat-select required name="stateSelect" #stateSelect="ngModel" ngModel
          placeholder="Estado">
          <mat-option *ngFor="let state of statesSelector" [value]="state.value">
            {{state.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- Botones -->
    <div class="search-info-container"
      fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
      <button id="button1" class="search-button"
        mat-raised-button type="button" [disabled]="!f.valid" (click)="onSearch(f)">BUSCAR</button>
      <button class="search-button" mat-raised-button id="button2"
        routerLink="{{buttonControls.length > 0 ? (buttonControls[0].route) : ''}}"
        type="button">{{buttonControls.length > 0 ? buttonControls[0].text : 'Button 2'}}
      </button>
    </div>
  </form>
</div>