import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UserProfessionalProfile, UserProfessionalProfileJobExperience, UserProfessionalProfileEducation } from 'shared/models/user-professional-profile.model';
import { User } from 'shared/models/user.model';
import { AuthService } from 'shared/services/auth.service';
import { ProfileService } from 'shared/services/profile.service';
import { TranslateService } from 'shared/services/translate.service';

@Component({
  selector: 'app-profile-edit-professional',
  templateUrl: './profile-edit-professional.component.html',
  styleUrls: ['./profile-edit-professional.component.scss']
})
export class ProfileEditProfessionalComponent implements OnInit {

  content: any;
  user: User = { img: {}};
  profile: UserProfessionalProfile = {};
  private subscriptions: Subscription[] = [];
  countrySelect: any[] = [];
  stateSelect: any[] = [];
  // citySelect = ['merida', 'Miami'];

  currentExperienceIndex = 0;
  isNewExperience = true;
  currentEducationIndex = 0;
  isNewEducation = true;
  currentSkillIndex = 0;
  isNewSkill = true;

  profileForm = this.fb.group({
    basicInfo: this.fb.group({
      company: [''],
      job: [''],
      country: [''],
      state: [''],
      // currentCity: [''],
    }),
    contactInfo: this.fb.group({
      email: [''],
      phone: [''],
      website: [''],
    }),
    about: [''],
    });
      experienceForm = this.fb.group({
      enterprise: [''],
      website: [''],
      job: [''],
      state: [''],
      city: [''],
      startDate: [''],
      endDate: [''],
    });
    educationForm = this.fb.group({
      school: [''],
      website: [''],
      title: [''],
      state: [''],
      city: [''],
      startYear: [''],
      endYear: [''],
    });
    skillForm = this.fb.group({
      skill: [''],
    })

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              private translateService: TranslateService,
              private profileService: ProfileService,
              private authService: AuthService,
              private fb: FormBuilder) {
    this.translateService.dataObservable.subscribe((data: any) => {
    this.content = data;
    this.getDataOnConstructor();
    });
  }

  private getDataOnConstructor() {
    this.subscriptions.push(
      this.authService.userReplay$.subscribe((user: User) => {
        this.user = {
          ...user,
          img: user?.img && user?.img?.downloadUrl ? user.img : { downloadUrl: '../../../../assets/no-profile.png'},
        };
      }),
      this.profileService.getProfessionalProfile()
        .subscribe((profile: UserProfessionalProfile) => {
          this.profile = {...profile};
          // console.log(this.profile);
          this.profileForm = this.fb.group({
            basicInfo: this.fb.group({
              company: this.profile.basicInfo?.company,
              job: this.profile.basicInfo?.job,
              country: this.profile.basicInfo?.country,
              state: this.profile.basicInfo?.state,
            }),
            contactInfo: this.fb.group({
              email: this.profile.contactInfo?.email,
              phone: this.profile.contactInfo?.phone,
              website: this.profile.contactInfo?.website,
            }),
            about: this.profile.about,
          });
          this.countrySelect = this.profileService.getCountries();
          this.onSelectCountry(this.profile.basicInfo?.country, false);
      })
    ); 
  }

  ngOnInit(): void {
  }

  onSelectCountry(event: any, isEvent = true) {
    if (isEvent) {
      this.stateSelect = this.profileService.getStates(event.value);
    } else {
      this.stateSelect = this.profileService.getStates(event);
    }
  }

  onEditExperience(experience: any) { 
    if (this.isNewExperience) {
      this.profile.experience?.push(experience.value as UserProfessionalProfileJobExperience);
    } else {
      this.profile.experience?.splice(this.currentExperienceIndex, 1, experience.value as UserProfessionalProfileJobExperience);
    }
    this.experienceForm.reset();
    this.isNewExperience = true;
  }

  onEditEducation(education: any) {
    if (this.isNewEducation) {
      this.profile.education?.push(education.value as UserProfessionalProfileEducation);
    } else {
      this.profile.education?.splice(this.currentEducationIndex, 1, education.value as UserProfessionalProfileEducation);
    }
    this.educationForm.reset();
    this.isNewEducation = true;
  }

  onEditSkills(skill: any) { 
    if (this.isNewSkill) {
      this.profile.skills?.push(skill.value.skill as string);
    } else {
      this.profile.skills?.splice(this.currentEducationIndex, 1, skill.value.skill as string);
    }
    this.skillForm.reset();
    this.isNewSkill = true;
  }

  onUpdateProfile() {
    const newProfile: UserProfessionalProfile = this.profileForm.value;
    newProfile.experience = this.profile.experience;
    newProfile.education = this.profile.education;
    // newProfile.skills = this.profile.skills;
    // console.log(newProfile);
    this.profileService.updateProfessionalProfile(newProfile);
  }

}
