<div class="container" fxLayout="column" fxLayoutAlign="start">
  <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="30px" style="padding: 0 2% 20px 2%; width: 96%; max-width: 600px; align-self: center;">
    <div class="settings-title"
      fxLayout="row" fxLayoutAlign="space-between center">
      <span routerLink="/configuracion/editar-perfil">{{content.settings_edit_profile_edit_profile_professional_cancel}}</span>
      <span>{{content.settings_edit_profile_edit_profile_professional_title}}</span>
      <span class="link" (click)="onUpdateProfile()">{{content.settings_edit_profile_edit_profile_professional_save}}</span>
    </div>
    <form [formGroup]="profileForm" fxLayout="column" fxLayoutGap="30px">
      <div formGroupName="basicInfo" class="form-elements-container white-card"
        fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">
        <span class="edit-subtitle">{{content.settings_edit_profile_professional_basic_information_title}}</span>
        <label fxLayout="row" fxLayoutAlign="space-between start">
          {{content.settings_edit_profile_professional_basic_information_current_enterprise_label}}
          <mat-form-field>
            <input matInput type="text" formControlName="company">
          </mat-form-field>
        </label>
        <label fxLayout="row" fxLayoutAlign="space-between start">
          {{content.settings_edit_profile_professional_basic_information_current_position_label}}
          <mat-form-field>
            <input matInput type="text" formControlName="job">
          </mat-form-field>
        </label>
        <label fxLayout="row" fxLayoutAlign="space-between center">
          {{content.settings_edit_profile_professional_basic_information_current_location_label}}
          <div fxLayout="row" fxLayoutGap="10px"
            fxLayout.lt-md="column">
            <mat-form-field appearance="outline">
              <mat-select placeholder="País" formControlName="country" (selectionChange)="onSelectCountry($event)">
                <mat-option *ngFor="let country of countrySelect" [value]="country.value">
                  {{country.title}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-select placeholder="Estado" formControlName="state">
                <mat-option *ngFor="let state of stateSelect" [value]="state.value">
                  {{state.title}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </label>
      </div>
      <div formGroupName="contactInfo" class="form-elements-container white-card"
        fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">
        <span class="edit-subtitle">{{content.settings_edit_profile_professional_contact_information_title}}</span>
        <label fxLayout="row" fxLayoutAlign="space-between start">
          {{content.settings_edit_profile_professional_contact_information_email_label}}
          <mat-form-field>
            <input matInput type="text" formControlName="email">
          </mat-form-field>
        </label>
        <label fxLayout="row" fxLayoutAlign="space-between start">
          {{content.settings_edit_profile_professional_contact_information_phone_label}}
          <mat-form-field>
            <input matInput type="text" formControlName="phone">
          </mat-form-field>
        </label>
        <label fxLayout="row" fxLayoutAlign="space-between start">
          {{content.settings_edit_profile_professional_contact_information_website_label}}
          <mat-form-field>
            <input matInput type="text" formControlName="website">
          </mat-form-field>
        </label>
      </div>
      <div class="form-elements-container white-card"
        fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">
        <span class="edit-subtitle">{{content.settings_edit_profile_professional_about_title}}</span>
        <mat-form-field>
          <textarea matInput placeholder="{{content.settings_edit_profile_professional_about_label}}" type="text" formControlName="about"></textarea>
        </mat-form-field>
      </div>
    </form>

    
    <!-- Lista de experiencias -->
    <div class="form-elements-container white-card" *ngFor="let experience of profile.experience; let i = index;"
    (click)="experienceForm.patchValue(experience); isNewExperience = false; currentExperienceIndex = i"
      fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">
      <span class="edit-subtitle">{{content.settings_edit_profile_professional_job_experience_title}}</span>
      <label fxLayout="row" fxLayoutAlign="space-between start">
        {{content.settings_edit_profile_professional_job_experience_enterprise_label}}
        {{experience.company}}
      </label>
      <label fxLayout="row" fxLayoutAlign="space-between start">
        {{content.settings_edit_profile_professional_job_experience_website_label}}
        <mat-form-field>
          <input matInput type="text" placeholder="{{experience.website}}">
        </mat-form-field>
      </label>
      <label fxLayout="row" fxLayoutAlign="space-between start">
        {{content.settings_edit_profile_professional_job_experience_position_label}}
        <mat-form-field>
          <input matInput type="text" placeholder="{{experience.job}}">
        </mat-form-field>
      </label>
      <label fxLayout="row" fxLayoutAlign="space-between center">
        {{content.settings_edit_profile_professional_job_experience_location_label}}
        <div fxLayout="row" fxLayoutGap="10px"
        fxLayout.lt-md="column">
          <mat-form-field>
            <input matInput placeholder="{{experience.state}}">
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="{{experience.city}}">
          </mat-form-field>
        </div>
      </label>
      <label fxLayout="row" fxLayoutAlign="space-between start">
        {{content.settings_edit_profile_professional_job_experience_start_date_label}}
        <div fxLayout="row" fxLayoutGap="10px">
          <mat-form-field>
            <input matInput placeholder="{{experience.startDate}}">
          </mat-form-field>
        </div>
      </label>
      <label fxLayout="row" fxLayoutAlign="space-between start">
        {{content.settings_edit_profile_professional_job_experience_end_label}}
        <div fxLayout="row" fxLayoutGap="10px">
          <mat-form-field>
            <input matInput placeholder="{{experience.endDate}}">
          </mat-form-field>
        </div>
      </label>
    </div>
    <!-- Agregar experiencia -->
    <div [formGroup]="experienceForm" class="form-elements-container white-card"
      fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">
      <span class="edit-subtitle">{{content.settings_edit_profile_professional_job_experience_title}}</span>
      <label fxLayout="row" fxLayoutAlign="space-between start">
        {{content.settings_edit_profile_professional_job_experience_enterprise_label}}
        <mat-form-field>
          <input matInput type="text" formControlName="enterprise">
        </mat-form-field>
      </label>
      <label fxLayout="row" fxLayoutAlign="space-between start">
        {{content.settings_edit_profile_professional_job_experience_website_label}}
        <mat-form-field>
          <input matInput type="text" formControlName="website">
        </mat-form-field>
      </label>
      <label fxLayout="row" fxLayoutAlign="space-between start">
        {{content.settings_edit_profile_professional_job_experience_position_label}}
        <mat-form-field>
          <input matInput type="text" formControlName="job">
        </mat-form-field>
      </label>
      <label fxLayout="row" fxLayoutAlign="space-between center">
        {{content.settings_edit_profile_professional_job_experience_location_label}}
        <div fxLayout="row" fxLayoutGap="10px"
          fxLayout.lt-md="column">
          <mat-form-field appearance="outline">
            <mat-select placeholder="País" formControlName="state">
              <!-- <mat-option *ngFor="let item of items" [value]="item.value">
                {{item.viewValue}}
              </mat-option> -->
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-select placeholder="Ciudad" formControlName="city">
              <!-- <mat-option *ngFor="let item of items" [value]="item.value">
                {{item.viewValue}}
              </mat-option> -->
            </mat-select>
          </mat-form-field>
        </div>
      </label>
      <label fxLayout="row" fxLayoutAlign="space-between center">
        {{content.settings_edit_profile_professional_job_experience_start_date_label}}
        <div fxLayout="row" fxLayoutGap="10px"
          fxLayout.lt-md="column">
          <mat-form-field appearance="outline">
            <input matInput type="date" placeholder="Mes" formControlName="startDate">
            <!-- <mat-select placeholder="Mes" formControlName="startDate">
              <mat-option *ngFor="let item of items" [value]="item.value">
                {{item.viewValue}}
              </mat-option>
            </mat-select> -->
          </mat-form-field>
          <mat-form-field appearance="outline">
            <input matInput type="date" placeholder="Mes" formControlName="endDate">
            <!-- <mat-select placeholder="Año" formControlName="endDate">
              <mat-option *ngFor="let item of items" [value]="item.value">
                {{item.viewValue}}
              </mat-option>
            </mat-select> -->
          </mat-form-field>
        </div>
      </label>
      <button mat-button color="primary" (click)="onEditExperience(experienceForm)">+Añadir Experiencia de Trabajo</button>
    </div>
    <!-- Lista de educación -->
    <div class="form-elements-container white-card" *ngFor="let education of profile.education; let i = index;"
      (click)="educationForm.patchValue(education); isNewEducation = false; currentEducationIndex = i"
      fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">
      <span class="edit-subtitle">{{content.settings_edit_profile_professional_education_title}}</span>
      <label fxLayout="row" fxLayoutAlign="space-between start">
        {{content.settings_edit_profile_professional_education_school_label}}
        <span>{{education.school}}</span>
      </label>
      <label fxLayout="row" fxLayoutAlign="space-between start">
        {{content.settings_edit_profile_professional_education_website_label}}
        <span>{{education.website}}</span>
      </label>
      <label fxLayout="row" fxLayoutAlign="space-between start">
        {{content.settings_edit_profile_professional_education_title_label}}
        <span>{{education.title}}</span>
      </label>
      <label fxLayout="row" fxLayoutAlign="space-between start">
        {{content.settings_edit_profile_professional_education_location_label}}
        <div fxLayout="row" fxLayoutGap="10px">
          <span>{{education.city}}</span>
          <span>{{education.state}}</span>
        </div>
      </label>
      <label fxLayout="row" fxLayoutAlign="space-between start">
        {{content.settings_edit_profile_professional_education_start_date_label}}
        <div fxLayout="row" fxLayoutGap="10px">
          <span>{{education.startYear}}</span>
        </div>
      </label>
      <label fxLayout="row" fxLayoutAlign="space-between start">
        {{content.settings_edit_profile_professional_education_end_label}}
        <div fxLayout="row" fxLayoutGap="10px">
          <span>{{education.endYear}}</span>
        </div>
      </label>
    </div>
    <!-- Agregar educación -->
    <form [formGroup]="educationForm" class="form-elements-container white-card"
      fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">
      <span class="edit-subtitle">{{content.settings_edit_profile_professional_education_title}}</span>
      <label fxLayout="row" fxLayoutAlign="space-between start">
        {{content.settings_edit_profile_professional_education_school_label}}
        <mat-form-field>
          <input matInput type="text" formControlName="school">
        </mat-form-field>
      </label>
      <label fxLayout="row" fxLayoutAlign="space-between start">
        {{content.settings_edit_profile_professional_education_website_label}}
        <mat-form-field>
          <input matInput type="text" formControlName="website">
        </mat-form-field>
      </label>
      <label fxLayout="row" fxLayoutAlign="space-between start">
        {{content.settings_edit_profile_professional_education_title_label}}
        <mat-form-field>
          <input matInput type="text" formControlName="title">
        </mat-form-field>
      </label>
      <label fxLayout="row" fxLayoutAlign="space-between center">
        {{content.settings_edit_profile_professional_education_location_label}}
        <div fxLayout="row" fxLayoutGap="10px"
          fxLayout.lt-md="column">
          <mat-form-field appearance="outline">
            <mat-select placeholder="País" formControlName="state">
              <!-- <mat-option *ngFor="let item of items" [value]="item.value">
                {{item.viewValue}}
              </mat-option> -->
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-select placeholder="Ciudad" formControlName="city">
              <!-- <mat-option *ngFor="let item of items" [value]="item.value">
                {{item.viewValue}}
              </mat-option> -->
            </mat-select>
          </mat-form-field>
        </div>
      </label>
      <label fxLayout="row" fxLayoutAlign="space-between center">
        {{content.settings_edit_profile_professional_education_end_label}}
        <div fxLayout="row" fxLayoutGap="10px"
          fxLayout.lt-md="column">
          <mat-form-field appearance="outline">
            <input matInput type="date" placeholder="Mes" formControlName="startYear">
            <!-- <mat-select placeholder="Mes" formControlName="startYear">
              <mat-option *ngFor="let item of items" [value]="item.value">
                {{item.viewValue}}
              </mat-option>
            </mat-select> -->
          </mat-form-field>
          <mat-form-field appearance="outline">
            <input matInput type="date" placeholder="Mes" formControlName="endYear">
            <!-- <mat-select placeholder="Año" formControlName="endYear">
              <mat-option *ngFor="let item of items" [value]="item.value">
                {{item.viewValue}}
              </mat-option>
            </mat-select> -->
          </mat-form-field>
        </div>
      </label>
      <button mat-button color="primary" type="button" (click)="onEditEducation(educationForm)">+Añadir más Estudios</button>
    </form>
    <!-- Mostrar skills -->
    <span class="edit-subtitle">{{content.settings_edit_profile_professional_education_skills_title}}</span>
    <div class="form-elements-container white-card" *ngFor="let skill of profile.skills; let i = index;"
      (click)="skillForm.patchValue({skill: skill}); isNewSkill = false; currentSkillIndex = i"
      fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">
      <span>{{skill}}</span>
    </div>
    <form [formGroup]="skillForm" class="form-elements-container white-card"
      fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">
      <span class="edit-subtitle">{{content.settings_edit_profile_professional_education_skills_title}}</span>
      <mat-form-field>
        <input matInput type="text" formControlName="skill">
      </mat-form-field>
      <!-- Agregar skill -->
      <button mat-button color="primary" (click)="onEditSkills(skillForm)">+Añadir más Habilidades</button>
    </form>
  </div>
</div>