import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  content: any;
  @Output() confirmSave = new EventEmitter<boolean>();

  constructor(private dialogRef: MatDialogRef<ConfirmDialogComponent>,
  /**
   * Titulo del dialogo.
   */
              @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
  }

  onAccept(): void {
    this.confirmSave.emit(true);
    this.dialogRef.close();
  }

  onCancel(): void {
    this.confirmSave.emit(false);
    this.dialogRef.close();
  }
}
