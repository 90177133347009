import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Coupon } from 'shared/models/coupon.model';
import { DatabaseService } from './database.service';
import firebase from 'firebase/app';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CouponService {

  private get couponsRoute(): string {
    return `coupons`;
  }

  constructor(private database: DatabaseService,
    private dbFirestore: AngularFirestore) { }

  getAll() {
    return this.database.getCollectionSnapshotChanges<Coupon>(this.couponsRoute);
  }

  getValidCoupons() {
    const today  =  
      firebase.firestore.Timestamp.fromDate(moment().startOf('day').subtract(1, 'day').toDate());

    return this.dbFirestore.collection<Coupon>(`${this.couponsRoute}`,
          ref => ref.where('validDateEnd', '>=', today)
        )
        .get()
        .pipe(
          // Obtenemos los cupones
          map((documents) => {
            return documents.docs.map((document) => {
              return {
                ...document.data(),
                id: document.id,
                reference: document.ref,
              }
            })
          }),
          // Ordenamos por posicion
          map (x => x.sort((x, y) => x.position! - y.position!))
        );
  }

  get(id: string) {
    return this.database.getDocument<Coupon>(`${this.couponsRoute}/${id}`);
  }

  create(data: Coupon) {
    this.database.addDocumentToCollection(this.couponsRoute, data);
  }

  update(id: string, data: Coupon) {
    this.database.updateDocument(`${this.couponsRoute}/${id}`, data);
  }

  delete(id: string) {
    this.database.deleteDocument(`${this.couponsRoute}/${id}`);
  }

  getAllCouponsCategories(): Observable<any> {
    return this.database.getDocument<any>(`/config/coupon-categories`);
  }

}
