import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { TranslateService } from 'shared/services/translate.service';



@Component({
  selector: 'app-settings-notifications',
  templateUrl: './settings-notifications.component.html',
  styleUrls: ['./settings-notifications.component.scss']
})
export class SettingsNotificationsComponent implements OnInit {

  content: any;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              private translateService: TranslateService) {
    this.translateService.dataObservable.subscribe((data: any) => {
    this.content = data;
    });
  }

  ngOnInit(): void {
  }

  onChangeFollowedForums() {}
  onChangeMyCreatedForums() {}
  onChangeForumsRecommendations() {}
  onChangenewContactRecommendations() {}
  onChangeNewContactRequest() {}
  onChangeReceiveEmailNotifications() {}
}
