import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-successful-publication',
  templateUrl: './successful-publication.component.html',
  styleUrls: ['./successful-publication.component.scss']
})
export class SuccessfulPublicationComponent implements OnInit {
  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() image!: any;
  @Input() buttonInfo!: {text: string; redirectTo: string};
  @Input() bgColor: string = 'green';

  constructor() { }

  ngOnInit(): void {
  }

}
