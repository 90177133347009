import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Notification } from 'shared/models/notifications.model';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss']
})
export class NotificationItemComponent implements OnInit {
  @Input() image!: string;
  @Input() notification!: Notification;
  /**
   * Indica si marco como leida la notificacion.
   */
  @Output() read = new EventEmitter<boolean>();
  /**
   * Indica si acepto o no la amistad.
   */
  @Output() friend = new EventEmitter<boolean>();
  /**
   * Indica si archivó la notificacion.
   */
  @Output() archive = new EventEmitter<boolean>();
  /**
   * Indica si eliminó la notificacion.
   */
  @Output() delete = new EventEmitter<boolean>();

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.image = (this.image && this.image !== '') ? this.image : '../../../../assets/alumni-universe-logo-space-color.png';
  }

  onMarkAsRead() {
    this.read.emit(true);
  }
  
  onOpenFriendDialog(): void {
    this.friend.emit(true);
  }
  
  onDelete() {
    this.delete.emit(true);
  }
  
  onGoToNotification(notification: Notification) {
    // // console.log(this.notification);
    if (notification.internal_action) {
      // console.log(notification.internal_action);
      this.router.navigate([`${notification.internal_action}`]);
      this.onMarkAsRead();
    }
  }

}
