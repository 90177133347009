import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClientAuthGuard } from 'shared/guards/client-auth.guard';
import { ProfileResolver } from 'shared/resolvers/profile.resolver';
import { HomeComponent } from './common/components/home/home.component';
import { LoginAppPreviewComponent } from './common/components/login/login-app-preview/login-app-preview.component';
import { LoginComponent } from './common/components/login/login.component';
import { NavigationComponent } from './common/components/navigation/navigation.component';
import { NotificationsComponent } from './common/components/notifications/notifications.component';
import { ProfileEditProfessionalComponent } from './common/components/profile/profile-edit-professional/profile-edit-professional.component';
import { ProfileEditComponent } from './common/components/profile/profile-edit/profile-edit.component';
import { ProfileProfessionalComponent } from './common/components/profile/profile-professional/profile-professional.component';
import { ProfileComponent } from './common/components/profile/profile.component';
import { SettingsFaqComponent } from './common/components/settings/settings-faq/settings-faq.component';
import { SettingsFriendshipComponent } from './common/components/settings/settings-friendship/settings-friendship.component';
import { SettingsNotificationsComponent } from './common/components/settings/settings-notifications/settings-notifications.component';
import { SettingsSecurityComponent } from './common/components/settings/settings-security/settings-security.component';
import { SettingsComponent } from './common/components/settings/settings.component';
import { SignupComponent } from './common/components/signup/signup.component';
import { UserSearchComponent } from './common/components/user-search/user-search.component';

const routes: Routes = [  
  // { path: '', component: LoginComponent },
  { path: '', component: NavigationComponent, 
    // canActivate: [ClientAuthGuard], 
    canActivateChild:[ClientAuthGuard], 
    children: [
      { path: '', component: HomeComponent },
      { path: 'notificaciones', component: NotificationsComponent },
      { path: 'configuracion', children: [
        { path: '', component: SettingsComponent },
        { path: 'notificaciones', component: SettingsNotificationsComponent },
        { path: 'seguridad-y-privacidad', component: SettingsSecurityComponent },
        { path: 'editar-perfil', component: ProfileEditComponent },
        { path: 'editar-perfil/editar-profesional', component: ProfileEditProfessionalComponent },
        { path: 'reglamento-de-uso', component: SettingsFaqComponent },
        { path: 'solicitudes-amistad', component:  SettingsFriendshipComponent},
      ] },
      { path: 'buscar', component: UserSearchComponent },
      { path: 'buscar/ver/:id', component: ProfileProfessionalComponent, resolve: {data: ProfileResolver} },
      { path: 'perfil', component: ProfileComponent },
      { path: 'perfil-profesional', component: ProfileProfessionalComponent },
      { path: 'foros', loadChildren: () => import('./forums/forums.module').then(m => m.ForumsModule) },
      { path: 'chat', loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule) },
      { path: 'mesa-de-negocios', loadChildren: () => import('./business/business.module').then(m => m.BusinessModule) },
    ]
  },
  { path: 'bienvenido', component: LoginAppPreviewComponent },
  { path: 'legal', loadChildren: () => import('./legal/legal.module').then(m => m.LegalModule) },
  { path: 'login', component: LoginComponent },
  { path: 'iniciar-sesion', component: LoginComponent },
  { path: 'crear-cuenta', component: SignupComponent },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' , relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
