import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import { Observable, Subscription, of, ReplaySubject, BehaviorSubject, timer } from 'rxjs';
import { switchMap, map, first, take, delay, delayWhen } from 'rxjs/operators';
import { cleanObject, getStringOrDefault } from '../methods/common-methods';
import firebase from 'firebase/app';
import '@firebase/functions';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthData } from '../models/auth-data.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /**
   * @deprecated
   * Datos del usuario que inicio sesión. Usado unicamente para detectar inicio de sesion.
   */
  private user!: User;
  /**
   * @deprecated
   * Usuario logueado.
   * @description Sirve para el AuthGuard, verifica si ya esta autenticado,
   * tambien contiene la relación al documento de Firestore.
   */
  private user$: Observable<User> = new Observable<User>();
  public userReplay$: ReplaySubject<User> = new ReplaySubject<User>(1);
  /**
   * @deprecated
   */
  public userId$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private subscriptions: Subscription[] = [];

  constructor( private router: Router,
               private snackbar: MatSnackBar,
               private authentication: AngularFireAuth,
               private database: AngularFirestore) {
      this.autodetectSignInSession();

      this.subscriptions.push(
        // Asignamos el estado de autenticación a traves del observable del usuario
        this.user$.subscribe((currentUser: User) => {
          this.user = currentUser;
        })
      );
  }

  /**
   * Detecta la sesión del usuario que habia o acaba de iniciar sesión.
   */
  autodetectSignInSession() {
    return this.user$ = this.authentication.authState
    .pipe(
      take(10),
      // Obtenemos el usuario logueado y sus claims
      switchMap(async (firebaseUser) => {
        if (!firebaseUser) {
          return null;
        }
        const tokenResult = await firebaseUser.getIdTokenResult();
        // // console.log(tokenResult);
        const user: User = {
          uid: firebaseUser.uid!,
          email: firebaseUser.email!,
          role: firebaseUser.email === 'ramm@rammalvarez.com' || firebaseUser.email === 'danielalvarez@rammalvarez.com' ? 'admin' : (tokenResult.claims?.role ? tokenResult.claims?.role : 'user'),
        };

        return user;
      }),
      // Leemos el documento
      switchMap( user => {
        if (user) {
          const baseRoute = user.role === 'admin' ? 'admins' : 'users';
          
          return this.database.doc<User>(`${baseRoute}/${user.uid}`)
            .get()
            .pipe(
              map((document) => {
                if (!document.data() && document.data()?.isActive === false) {
                  this.snackbar.open('Lo sentimos, por el momento no tienes acceso a la plataforma',
                    undefined, { duration: 5000 });
                  this.signOut();
                  return of({});
                }

                // Si tiene acceso
                return {
                  ...document.data(),
                  uid: document.id,
                  role: user.role,
                  name: getStringOrDefault( document.data()?.name as string,  document.data()?.email as string),
                };
              })
            );
        } else {
          return of({});
        }
      }),
      map((x: User) => {
        this.userId$.next(x.uid!);
        this.userReplay$.next(x);
        return x;
      })
    );
  }

  refreshUserDoc(): Observable<User> {
    return this.userReplay$
      .pipe(
        switchMap(user => {
          return this.database.doc<User>(`users/${user.uid}`)
          .get()
          .pipe(
            map((document) => {
              // console.log(document.data());
              // Si tiene acceso
              return {
                ...user,
                ...document.data(),
                uid: document.id,
                name: getStringOrDefault( document.data()?.name as string,  document.data()?.email as string),
              };
            })
          );
        }),
        map((x) => {
          this.userReplay$.next(x);
          return x;
        })
      );
  }

  /**
   * Inicia sesión del usuario
   * @param authData Contiene el email y contraseña del usuario.
   */
  async signIn(authData: AuthData) {
    this.authentication
      .signInWithEmailAndPassword(authData.email, authData.password)
      .then((response) => {
        const x = this.user$.pipe(
          first()
        ).subscribe(() => {
          this.router.navigateByUrl('');
          this.snackbar.open('¡Bienvenido!', undefined, {duration: 3000});
        });

      }).catch((error) => {
        this.snackbar.open(`No podemos confirmar tus credenciales. Favor de verificarlas.`, undefined,
          { duration: 5000 });
      });
  }

  /**
   * Cierra la sesión del usuario.
   * Este método funciona independientemente si
   * inicio sesión con "Correo y Contraseña" o cualquier tipo de servicio de
   * autenticación como "Google Login".
   */
  signOut() {
    // Nos desuscribimos a los observables
    if (this.subscriptions.length > 0 ) {
      this.subscriptions.forEach((subscription) => {
        subscription.unsubscribe();
      });
    }

    // Nos deslogueamos
    this.authentication.signOut()
      .then(() => {
        this.router.navigate(['/login']);
      })
      .catch((error) => {
        this.snackbar.open(`${error.message}`, undefined,
          { duration: 5000 });
        this.redirectToHome();
        setTimeout(() => {
          location.reload();
        }, 200);
      });
  }

  /**
   * Cambia la contraseña según la especificada.
   */
  resetPassword(code: string, newPassword: string) {
    try {
      this.authentication.confirmPasswordReset(code, newPassword)
        .then(() => {
          this.snackbar.open(`Se cambio la contraseña exitosamente.`, undefined, { duration: 5000});
        });
    } catch (error) {
      // console.error('resetPassword - Error al enviar correo de reinicio de contraseña: ', error);
      this.snackbar.open('Ups! Algo salió mal al cambiar la contraseña del usuario',
        undefined, { duration: 5000});
    }
  }

  /**
   * Manda el correo de reinicio al usuario logueado.
   */
  async sendResetPasswordEmailToThisUser() {
    try {
      const userEmail = (await this.userReplay$.pipe(first()).toPromise()).email as string;
      this.authentication.sendPasswordResetEmail(userEmail)
        .then(() => {
          this.snackbar.open(`Se envió el correo de reinicio a ${userEmail}`,
              undefined, { duration: 10000});
        });
    } catch (error) {
      // console.error('Error al enviar correo de reinicio de contraseña: ', error);
      this.snackbar.open('Ups! Algo salió mal al reiniciar la contraseña del usuario',
        undefined, { duration: 5000});
    }
  }

  /**
   * Manda el correo de reinicio de contraseñe al usuario logueado.
   */
  sendPasswordResetEmailToUserEmail(email: string) {
    try {
      this.authentication.sendPasswordResetEmail(email)
        .then(() => {
          this.snackbar.open(`Se envió el creo de reinicio a ${email}`,
            undefined, { duration: 5000});
        })
        .catch(() => {
          this.snackbar.open(`Por favor ingresa tu correo`,
            undefined, { duration: 5000});
        });
    } catch (error) {
      // console.error('Error al enviar correo de reinicio de contraseña: ', error);
      this.snackbar.open('Ups! Algo salió mal al crear el nuevo usuario',
        undefined, { duration: 5000});
    }
  }

  /**
   * Redirecciona al inicio de la aplicacion '/'.
   */
  private redirectToHome() {
    return this.router.navigate(['']);
  }

  /**
   * Indica si el usuario es `administrador`.
   * @returns Observable<boolean>
   */
  isUserAdmin() {
    return this.userReplay$.pipe(
      switchMap((user: User) => {
        if (user) {
          if (user.isActive === false) {
            return of(false);
          }
          // Devolvemos el permiso del usuario
          return of(user?.role === 'admin');
        } else {
          return of(false);
        }
      })
    );
  }

  /**
   * Indica si el usuario es `administrador`.
   * @returns Observable<boolean>
   */
  isUser () {
    return this.userReplay$.pipe(
      switchMap((user: User) => {
        if (user) {
          if (user.isActive === false) {
            return of(false);
          }
          // Devolvemos el permiso del usuario
          return of(user?.role === 'user');
        } else {
          return of(false);
        }
      })
    );
  }

  
  createUserWithLoginInfo(data: User, password: string) {
    this.callableFuncion_CreateUser(data, password)
      .then(() => {
        this.snackbar.open('El usuario estará disponible de 1 a 15 minutos', undefined, {
          duration: 5000
        });
      })
      .catch ((error) => {
        this.snackbar.open('Ups! Algo salió mal al crear el usuario',
          undefined, { duration: 5000});
        // console.error('createUser - Detalles del error: ', error);
      });
  }

  createUserWithLoginInfoFromSubscriptionRequest(data: User, password: string, authId: string) {
    this.callableFuncion_CreateUserFromSubscriptionRequest(data, password, authId)
      .then(() => {
        // Mostrar mensaje de registro exitoso
        this.snackbar.open('Se realizó la solicitud correctamente.', undefined, {
          duration: 8000
        });
      })
      .catch ((error) => {
        this.snackbar.open('Ups! Algo salió mal al crear el usuario',
          undefined, { duration: 5000});
        // console.error('createUser - Detalles del error: ', error);
      });
  }

    /**
   * Crea un nuevo usuario con una contraseña provisional
   * @param user Datos del usuario a crear.
   * @param password Contraseña provisional creada por un administrador.
   */
  private async callableFuncion_CreateUser(user: User, password: string) {
    try {
      const currentUserUid = (await this.userReplay$.pipe(first()).toPromise()).uid;
      if (user && currentUserUid) {
        cleanObject(user);
        const authData: AuthData = {
          email: user.email as string,
          password,
        };
        // Llamamos a la function de Cloud Functions para crear el usuario con el Admin SDK
        // tslint:disable-next-line: object-literal-shorthand
        firebase.functions().httpsCallable('createUser')({authData: authData, user: user, creatorUid: currentUserUid});
      } else {
        throw new Error('No se puede crear el usuario debido a que no hay información del mismo');
      }
    } catch (error) {
      // console.error('createUser - createUser: ', error);
      this.snackbar.open('Ups! Algo salió mal al reiniciar la contraseña del usuario',
        undefined, { duration: 5000});
    }
  }

    /**
   * Crea un nuevo usuario de acuerdo a una solicitud de aceptación,
   * con una contraseña creada por el solicitante.
   * @param user Datos del usuario a crear.
   * @param password Contraseña creada por el usuario al llenar la solicitud.
   */
     private async callableFuncion_CreateUserFromSubscriptionRequest(user: User, password: string, authId: string) {
      try {
        cleanObject(user);
        const authData: AuthData = {
          email: user.email as string,
          password,
        };
        // Llamamos a la function de Cloud Functions para crear el usuario con el Admin SDK
        // tslint:disable-next-line: object-literal-shorthand
        firebase.functions().httpsCallable('createUserFromSubscriptionRequest')({authData: authData, user: user, authId: authId});
      } catch (error) {
        // console.error('createUser - createUser: ', error);
        this.snackbar.open('Ups! Algo salió mal al reiniciar la contraseña del usuario',
          undefined, { duration: 5000});
      }
    }
}
