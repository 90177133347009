<h1>
  REGLAMENTO DE USO DE LA PLATAFORMA ALUMNI UNIVERSE
</h1>


<p>

  Estimado Integrante de ALUMNI UNIVERSE:
</p>
<p>
  Ponemos a tu disposición esta Plataforma diseñada para restablecer el contacto entre exalumn@s, fomentar los negocios entre nuestra comunidad, hacer acción social, divertirnos, aprender y compartir experiencias.
  <br><br>
  Los derechos autorales o Copyright de esta plataforma pertenecen a ALUMNI UNIVERSE, por lo que no podrán ser utilizados o reproducidos sin la autorización del titular de estos derechos. 
</p>

<h2>
  AL USAR EL SOFTWARE, ESTÁS ACEPTANDO ESTOS TÉRMINOS Y CONDICIONES. 
</h2>

<ol>
  <!-- 1 -->
  <li>
    DERECHOS DE INSTALACIÓN Y USO. Sólo los ASOCIADOS de ALUMNI UNIVERSE y las demás personas expresamente autorizadas podrán utilizar el software que configura esta PLATAFORMA en sus dispositivos.
  </li>
  <!-- 2 -->
  <li>
    SERVICIOS BASADOS EN INTERNET. ALUMNI UNIVERSE proporciona con el software servicios basados en Internet, por lo cual podrá modificar el software total o parcialmente en cualquier momento. 	
    Queda prohibida la utilización de estos servicios de una manera tal que propicie perjuicios y/o obstaculice su uso a ALUMNI UNIVERSE y/o a los demás usuarios. 
  </li>
  <!-- 3 -->
  <li>
    ALCANCE DE LA LICENCIA. El uso del software se autoriza únicamente bajo licencia y de ninguna manera se debe entender como cesión de derechos patrimoniales. El presente contrato sólo otorga a los Usuarios los derechos de uso del software que se señalan específicamente en este Reglamento, por  lo que ALUMNI UNIVERSE como titular de los derechos patrimoniales se reserva el uso y manejo de todos los demás derechos. El uso que los ASOCIADOS hagan de la plataforma, deberá ajustarse a las limitaciones técnicas del software que sólo permiten utilizarlo de determinadas formas, por lo que queda prohibido:
    <ul>
      <li>
        Eludir las limitaciones técnicas del software;
      </li>
      <li>
        Utilizar técnicas de ingeniería inversa, descompilar o desensamblar el software;
      </li>
      <li>
        Hacer público el software para que otros lo copien;
      </li>
      <li>
        Alquilar, arrendar o ceder el software;
      </li>
      <li>
        Transferir este software ni este contrato a terceros; o
      </li>
      <li>
        Utilizar el software para prestar servicios de alojamiento de software comercial.
      </li>
    </ul>
  </li>
  <!-- 4 -->
  <li>
    Bases: Esta Plataforma contiene información interna y privilegiada, por lo que la misma no podrá ser extraída, divulgada o explotada sin la previa autorización del titular de los derechos autorales.
  </li>
  <!-- 5 -->
  <li>
    No se podrá usar esta Plataforma para fines ajenos para los que ha sido creada. Todos debemos salvaguardar la información de nuestros compañeros y amigos.
  </li>
  <!-- 6 -->
  <li>
    Los usuarios deberán mantener esta Plataforma privada, fuera del alcance de terceras personas o compañías comerciales y organizaciones sociales cuyas pretensiones sean las de extraer la información para cualquier fin ajeno a este Grupo, responsabilizándose de los daños y perjuicios que ocasionen.
  </li>
  <!-- 7 -->
  <li>
    Los usuarios no podrán discriminar bajo ningún motivo o por ninguna causa, a los demás integrantes de la Plataforma. Todos los que hayan sido exalumnos de los Colegios de la Red Semper Altius, por sólo ese hecho, tendrán derecho a participar en la Plataforma, cumpliendo con las condiciones de inscripción.
  </li>
  <!-- 8 -->
  <li>
    Todo usuario de la plataforma que quiera ofrecer y aceptar oportunidades de negocios, deberá hacerlo cumpliendo con toda honestidad, ética profesional y camaradería los pactos que asuma, con lo cual se integrarán relaciones de ganar-ganar y de negocios satisfactorios para ambas partes.
  </li>
  <!-- 9 -->
  <li>
    Queda prohibido a los usuarios de la Plataforma el compartir información privilegiada o confidencial, para obtener para sí mismo o de terceros algún beneficio propio o para causar daños.
  </li>
  <!-- 10 -->
  <li>
    Todo usuario se compromete a sólo compartir y/o publicar información o materiales que cumplan con las normas de decencia y respeto generalmente aceptadas. Por lo mismo, no se podrán compartir fotografías, comentarios o expresiones que atenten contra el decoro, la integridad de los demás usuarios y/o de terceros, la moral, la educación y buenas costumbres. 
  </li>
  <!-- 11 -->
  <li>
    Siempre y en toda situación los usuarios deberán expresarse con decoro a través de un lenguaje social y respetuoso que enaltezca a nuestro grupo, por lo que los ASOCIADOS deberán abstenerse de usar un lenguaje soez, y asimismo sin excepción alguna deberán abstenerse de insultar y/o denigrar a nadie por razón alguna.
  </li>
  <!-- 12 -->
  <li>
    La Plataforma debe ser usada de manera proactiva y constructiva. Por lo mismo, no será usada para emitir críticas o faltas de respeto hacia los Colegios o la Orden Religiosa que los inspiró, ni hacia las Directivas o los integrantes de los Planteles de éste o de otros grupos. Las quejas que haya contra los Colegios deberán ser tratadas con ellos de manera directa.
  </li>
  <!-- 13 -->
  <li>
    El incumplimiento y/o contravención de estos preceptos, tendrá como consecuencia el ser dado de baja inmediatamente de la Plataforma, por lo que ya no tendrá acceso a la misma.
  </li>
  <!-- 14 -->
  <li>
    A fin de mantener actualizada de manera eficiente esta Plataforma, los usuarios deberán informar de manera oportuna de los cambios, correcciones o nueva información personal. Es importante para una mejor localización de oportunidades que la mayor cantidad posible de registros de cada exalumno esté completa. 
  </li>
  <!-- 15 -->
  <li>
    No se podrán realizar usos y/o prácticas que propicien daños a la plataforma. Quien perpetre estas prácticas será responsable de los daños y perjuicios que se ocasionen tanto a ALUMNI UNIVERSE como a los demás usuarios y/o a terceros. 
  </li>
</ol>