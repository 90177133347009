<div class="container" fxLayout="column" fxLayoutAlign="start">
  <div fxLayout="column" fxLayoutAlign="start"
    fxLayoutGap="0px">
    <div class="list-element settings-title"
      fxLayout="row" fxLayoutAlign="space-between center">
      <!-- Cancelar -->
      <button mat-button routerLink="../">{{content.settings_edit_profile_cancel}}</button>
      <!-- Titulo -->
      <span>{{content.settings_edit_profile_title}}</span>
      <!-- Guardar -->
      <button mat-button color="primary" (click)="onEdit()">{{content.settings_edit_profile_save}}</button>
    </div>
    <mat-divider></mat-divider>

    <!-- IMAGEN DE PERFIL -->
    <div fxLayout="column" fxLayoutAlign="center center"
      fxLayoutGap="20px" style="padding: 30px 0px;">
      <div class="img-container" *ngIf="user.img">
        <img src="{{user.img ? user.img.downloadUrl : '../../../../../../../assets/no-profile.png'}}" [matMenuTriggerFor]="menuDownloadbleMedia">
        <mat-menu #menuDownloadbleMedia="matMenu">
          <a style="text-decoration: none;" href="{{ user.img.downloadUrl }}" target="_blank">
            <button mat-menu-item>
              Descargar
            </button>         
          </a>
          <button mat-menu-item 
            (click)="uploadImage1.click(); imageIsNew = false;"
            type="button">
            Editar
          </button>         
          <button mat-menu-item 
            (click)="onDeleteFile()"
            type="button">
            Borrar
          </button>         
        </mat-menu>
      </div>
      <!-- <button mat-icon-button color="primary" *ngIf="!user.img"
      (click)="uploadImage1.click(); imageIsNew = true;"><mat-icon>attach_file</mat-icon></button> -->
      <input hidden type="file" 
        #uploadImage1 accept="image/*"
        (change)="onUploadFile($event, imageIsNew)">
      <span class="link change-img-text"*ngIf="!user.img"
      (click)="uploadImage1.click(); imageIsNew = true;">{{content.settings_edit_profile_change_profile_image}}</span>
    </div>

    <!-- FORMULARIO -->
    <form [formGroup]="profileForm" fxLayout="column" fxLayoutAlign="start">
      <mat-divider></mat-divider>
      <label class="list-element"
        fxLayout="row" fxLayoutAlign="space-between center">
        <span>{{content.settings_edit_profile_name_label}}</span>
        <mat-form-field>
          <input matInput type="text" formControlName="name">
        </mat-form-field>
      </label>
      <mat-divider></mat-divider>
      <label class="list-element"
        fxLayout="row" fxLayoutAlign="space-between center">
        <span>{{content.settings_edit_profile_school_label}}</span>
        <mat-form-field>
          <input matInput type="text" formControlName="school">
        </mat-form-field>
      </label>
      <mat-divider></mat-divider>
      <label class="list-element"
        fxLayout="row" fxLayoutAlign="space-between center">
        <span>{{content.settings_edit_profile_instagram_label}}</span>
        <mat-form-field>
          <input matInput type="text" formControlName="instagram">
        </mat-form-field>
      </label>
      <mat-divider></mat-divider>
      <label class="list-element"
        fxLayout="row" fxLayoutAlign="space-between center">
        <span>{{content.settings_edit_profile_twitter_label}}</span>
        <mat-form-field>
          <input matInput type="text" formControlName="twitter">
        </mat-form-field>
      </label>
      <mat-divider></mat-divider>


      <!-- TODO: Descomentar -->
      <div fxLayout="column" fxLayoutAlign="center center">
        <button mat-button class="link" routerLink="/configuracion/editar-perfil/editar-profesional">{{content.settings_edit_profile_edit_profile_professional_link}}</button>
      </div>
    </form>
  </div>
</div>