import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Notification } from 'shared/models/notifications.model';
import { NotificationService } from 'shared/services/notification.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
  @Input() color!: string;
  notifications: Notification[] = [];

  private subcriptions: Subscription[] = [];

  constructor(private notificationService: NotificationService,
              private router: Router,) { }

  ngOnInit(): void {
    this.color = 'rgb(1, 30, 65)';
    this.subcriptions.push(
      this.notificationService.getAllNotifications()
        .subscribe((notifications: Notification[]) => {
          // // console.log(notifications);
          if (notifications?.length > 0 ) {
            this.notifications = [];
            const notReads = notifications.filter((x) => !x.isRead);
            const reads = notifications.filter((y) => y.isRead);
            this.notifications.push(...notReads, ...reads);
            // // console.log(this.notifications.map((x) => `${moment(x.dateCreated?.valueOf()).format('hh:mm, DD-MM-yyyy')} - ${x.title} - ${x.body}`));
          } else {
            this.notifications = [{ body: 'Aun no tienes notificaciones.' }];
          }
        })
    );
  }

  ngOnDestroy() {
    this.subcriptions.forEach(x => x.unsubscribe());
  }

  onDeleteNotification(index: number): void {
    this.notificationService
      .deleteNotification(this.notifications[index].reference!);
  }

  onMarkAsReadNotification(index: number): void {
    this.notificationService
      .markNotificationAsRead(this.notifications[index].reference!);
  }

  onMarkAsArchivedNotification(index: number): void {
    this.notificationService
      .markNotificationAsArchived(this.notifications[index].reference!);
  }

  onSeeFriendshipRequests() {
    this.router.navigate([`/configuracion/solicitudes-amistad`]);
  }
}
