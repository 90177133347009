<div class="container" [ngStyle]="{'background-color': bgColor}" fxLayout="column" fxLayoutAlign="start">
  <img class="bg-top" src="../../../../assets/top-scene-background.svg" alt="">

  <div class="success-container"
    fxLayout="column" fxLayoutAlign="start"
    fxLayoutGap="30px">
    <!-- Logo -->
    <div class="logo-container">
      <img src="'../../../../../../assets/alumni-universe-logo-white.svg">
    </div>
    <!-- Título -->
    <span class="title">{{title ? title : 'No disponible'}}</span>
    <!-- Subtítulo -->
    <span class="subtitle">{{subtitle ? subtitle : 'No disponible'}}</span>
    <!-- Imagen -->
    <div class="img-container">
      <img src="'../../../../../../assets/alumni-universe-logo-space-color.png">
    </div>
    <!-- Botón -->
    <button class="success-button" mat-raised-button
      routerLink="{{buttonInfo ? buttonInfo.redirectTo : '/'}}">{{buttonInfo ? buttonInfo.text : 'REGRESAR'}}</button>
  </div>
</div>