import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { trimString } from 'shared/methods/common-methods';
import { AuthService } from 'shared/services/auth.service';
import { TranslateService } from 'shared/services/translate.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  content: any;
  showForm = true;
  hidePassword = true;
  public keyboardHeight = 1000;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              private platform: Platform,
              private translateService: TranslateService,
              private authService: AuthService,
              private router: Router,
              private snackbar: MatSnackBar) {
    this.translateService.dataObservable.subscribe((data: any) => {
      this.content = data;
    });

    this.ionicDetectKeyboard();
  }

  ngOnInit(): void {
  }

  ionicDetectKeyboard() {
    this.platform.keyboardDidShow.subscribe((ev: any) => {
      // Do something with the keyboard height such as translating an input above the keyboard.
      this.keyboardHeight = ev;
      console.log('ev', ev);
    });
  
    this.platform.keyboardDidHide.subscribe(() => {
      // Move input back to original location
      this.keyboardHeight = 0;
    });
  }

  onLogin(f: NgForm): void {
    this.authService.signIn(
      {
        email: trimString(f.value.email),
        password: f.value.password
      }
    );
  }

  onResetPassword(f: NgForm) {
    this.authService.sendPasswordResetEmailToUserEmail(trimString(f.value.email));
  }
}
