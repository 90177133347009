// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false, 
  firebase: {
    apiKey: 'AIzaSyABo8fQNJb4akPrWgCY00dYCvQE1ONJ5NI',
    authDomain: 'alumni-universe.firebaseapp.com',
    databaseURL: 'https://alumni-universe.firebaseio.com',
    projectId: 'alumni-universe',
    storageBucket: 'alumni-universe.appspot.com',
    messagingSenderId: '1031074812582',
    appId: '1:1031074812582:web:105d4c6a3ef17d66da9a67',
    measurementId: 'G-GKNF0Z4WDC',
  },
  paypalEnvSelector: 'sandbox',
  // paypalSandboxToken: 'AWkJlxCIyJByibwYAna4LUAI1U_SwvArn_3LR2V4DIx-g9UVOeHSM0NEGCvp_y3ahm51H6EWHFbaOE7I',
  paypalProductionToken: 'AdmLhtshrkHMirhErJsSVy5rgMeW80hCr5L8haW7WGpMQDTOvTd0pO-KOXtqEM8_jQJKUJF1K4fsNs6n',
  paypalSandboxToken: 'AYyg6-YbIv0NEMsssY0XJEl4ftfPS5rgRTE1aBM_CXvzAhhBS-odmnguYj6wl-aXKrL3LKy1cTutmjJ8',
  membershipPrice: 45,

  // firebase: {
  //   apiKey: "AIzaSyD6GxJwzhtKK5sLAyv83U30rAtbIyjLk7k",
  //   authDomain: "alumni-universe-test.firebaseapp.com",
  //   projectId: "alumni-universe-test",
  //   storageBucket: "alumni-universe-test.appspot.com",
  //   messagingSenderId: "486978705162",
  //   appId: "1:486978705162:web:6e4186b344bb5884370cf3",
  //   measurementId: "G-EN30KZMVJM"
  // }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
