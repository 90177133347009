import { Component } from '@angular/core';
import firebase from '@firebase/app';
import '@firebase/functions';
import '@firebase/messaging';
import { Platform } from '@ionic/angular';
import {SplashScreen} from '@capacitor/splash-screen';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  token!: string;

  constructor(private platform: Platform) { 
    this.platform = platform;
  }

  
  async ngOnInit() {    
    this.initializeApp();
    
    // // firebase.messaging().getToken().then((token) => {
    // //   // console.log(token);
    // //   this.token = token;
    // // }).catch((error) => {
    // //   // console.error('App 21: No se pudo obtener permiso para notificar', error);
    // // });
    // firebase.messaging().requestPermission()
    //   .then(() => {
    //     firebase.messaging().getToken().then((token) => {
    //         // console.log(token);
    //         this.token = token;
    //       });
    //   }).catch((error) => {
    //     // console.error('No se pudo obtener permiso para notificar', error);
    //   });

    // // Mostramos la notificacion dentro de la aplicación
    // firebase.messaging().onMessage((payload) => {
    //   // console.log('Notificación recibida: ', payload);
    // });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      SplashScreen.hide();
    });
  }
}
