<div class="container" fxLayout="column" fxLayoutAlign="start">
  <mat-list>
    <!-- Invitar Amigos por Whatsapp -->
    <!-- <mat-list-item>{{content.settings_list_1_element}}</mat-list-item>
    <mat-divider></mat-divider> -->

    <!-- Editar Perfil -->
    <mat-list-item routerLink="/configuracion/editar-perfil">{{content.settings_list_2_element}}</mat-list-item>
    <mat-divider></mat-divider>

    <!-- Mi Actividad -->
    <mat-list-item routerLink="/mesa-de-negocios/marketplace/mi-actividad">{{content.settings_list_3_element}}</mat-list-item>
    <mat-divider></mat-divider>

    <!-- Solicitudes de amistad -->
    <mat-list-item routerLink="/configuracion/solicitudes-amistad">{{content.settings_list_10_element}}</mat-list-item>
    <mat-divider></mat-divider>

    <!-- Notificaciones -->
    <!-- <mat-list-item routerLink="/configuracion/notificaciones">{{content.settings_list_4_element}}</mat-list-item>
    <mat-divider></mat-divider> -->

    <!-- Seguridad Y Privacidad -->
    <!-- <mat-list-item routerLink="/configuracion/seguridad-y-privacidad">{{content.settings_list_5_element}}</mat-list-item>
    <mat-divider></mat-divider> -->

    <!-- Cambio De Contraseña -->
    <mat-list-item (click)="onResetPassword()">{{content.settings_list_6_element}}</mat-list-item>
    <mat-divider></mat-divider>

    <!-- Pagos Y Suscripción -->
    <!-- <mat-list-item routerLink="/configuracion/pagos-y-suscripcion">{{content.settings_list_7_element}}</mat-list-item>
    <mat-divider></mat-divider> -->

    <!-- Reglamento de Uso -->
    <mat-list-item routerLink="/configuracion/reglamento-de-uso">{{content.settings_list_8_element}}</mat-list-item>
    <mat-divider></mat-divider>

    <!-- Cerrar Sesión -->
    <mat-list-item class="link" (click)="authService.signOut()">{{content.settings_list_9_element}}</mat-list-item>
  </mat-list>
</div>