import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { cleanObject } from 'shared/methods/common-methods';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  constructor(private database: AngularFirestore) { }

  getCollectionSnapshotChanges<T>(route: string): Observable<T[]> {
    try {
      return this.database.collection<T>(route)
        .snapshotChanges()
        .pipe(
          map((documents) => {
            return documents.map((document) => {
              return {
                ...document.payload.doc.data(),
                id: document.payload.doc.id,
                reference: document.payload.doc.ref,
              };
            });
          })
        );
    } catch (error) {
      // console.error('Detalles del error: ', error);
      return of([]);
    }
  }

  getCollection<T>(route: string): Observable<T[]>{
    return this.database.collection<T>(route)
      .get()
      .pipe(
        map((documents) => {
          return documents.docs.map((document) => {
            return {
              ...document.data()
            }
          })
        })
      );
  }

  getCollectionGroupSnapshotChanges<T>(route: string): Observable<T[]> | any {
    return this.database.collectionGroup<T>(route)
      .snapshotChanges()
      .pipe(
        map((documents) => {
          return documents.map((document) => {
            return {
              ...document.payload.doc.data(),
              id: document.payload.doc.id,
              reference: document.payload.doc.ref,
            };
          });
        })
      );
  }

  getCollectionGroup<T>(route: string): Observable<T[]> | any {
    return this.database.collectionGroup<T>(route)
      .get()
      .pipe(
        map((documents) => {
          return documents.docs.map((document) => {
            return {
              ...document.data()
            }
          })
        })
      );
  }

  getDocumentSnapshotChanges<T>(route: string): Observable<T> | any{
    return this.database.doc<T>(route)
    .snapshotChanges()
    .pipe(
      map((document) => {
        return {
          ...document.payload.data(),
          id: document.payload.id,
          reference: document.payload.ref,
        };
      })
    );
  }

  getDocument<T>(route: string): Observable<T>{
    try {
      return this.database.doc<T>(route)
      .get()
      .pipe(
        map((document) => {
          return ({
            ...document.data(),
            id: document.id,
            reference: document.ref,
          } as unknown) as T;
        })
      );
    } catch (error) {
      // console.error('Detalles del error: ', error);
      return of({} as T);
    }
  }

  addDocumentToCollection<T>(route: string, data: T) {
    cleanObject(data);
    return this.database.collection<T>(route)
      .add({...data});
  }

  createDocument<T>(route: string, id: string, data: T) {
    cleanObject(data);
    return this.database.doc<T>(`${route}/${id}`)
      .set({...data});
  }

  updateDocument<T>(route: string, data: T) {
    cleanObject(data);
    return this.database.doc<T>(route)
      .update({...data});
  }

  deleteDocument<T>(route: string) {
    return this.database.doc<T>(route)
      .delete();
  }
  deleteA(id: string) {
    return this.database.doc(`route/${id}`)
      .delete();
  }
}
