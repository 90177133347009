import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { TranslateService } from 'shared/services/translate.service';



@Component({
  selector: 'app-settings-security',
  templateUrl: './settings-security.component.html',
  styleUrls: ['./settings-security.component.scss']
})
export class SettingsSecurityComponent implements OnInit {

  content: any;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              private translateService: TranslateService) {
    this.translateService.dataObservable.subscribe((data) => {
    this.content = data;
    });
  }

  ngOnInit(): void {
  }

  onChangeBussinesAccess() {}
  onChangeAppearOnProfessionalServicesSearch() {}
  onChangeAppearOnBusinessSearch() {}

}
