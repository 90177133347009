<div class="post-container"
  fxLayout="column" fxLayoutAlign="start" fxLayoutGap="15px">
  <!-- Nombre y donde postea -->
  <div class="post-header"
    fxLayout="row" fxLayoutAlign="space-between start"  fxLayoutGap="5px">
    <!-- Imagen de perfil -->
    <div class="profile-container"
      fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <img src="{{ (creator | async)?.img?.downloadUrl }}">
    

      <div fxLayout="column" fxLayoutAlign="space-between start">
        <!-- Nombre y foro -->
        <div class="first-row"
          fxLayout="row" fxLayoutAlign="start start">
          <span class="creator">{{ (creator | async)?.name | titlecase}}</span>
          <!-- <span>
            {{creator.name ? (creator.name | titlecase | slice:0:24) : 'No disponible'}}
            <span *ngIf="creator.name.length > 24">...</span>
          </span> -->
          <mat-icon *ngIf="!isInForum">arrow_right</mat-icon>
          <span class="forum-link pointer" 
            *ngIf="!isInForum" 
            routerLink="/foros/ver/{{post?.forumId}}">
            {{post?.forumId! | forumName | async}}
            <span *ngIf="post.forumId && post.forumId!.length > 24">...</span>
          </span>
        </div>
        <!-- Titulo del post -->
        <div class="second-row"
          fxLayout="row" fxLayoutAlign="space-between center">
          <span class="title"> {{ post.title }} </span>
          <!-- <span>{{post.role | titlecase | slice:0:25}}<span *ngIf="post.role.length > 25">...</span></span> -->
        </div>
      </div>

    </div>
    <!-- Menú -->
    <div class="menu-column"
      fxLayout="column" fxLayoutAlign="space-between end">
      <button mat-icon-button class="menu-trigger" [matMenuTriggerFor]="menu">
        <mat-icon>more_horiz</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item *ngIf="isMyPost" routerLink="/foros/ver/{{post.forumId}}/{{post.id}}/editar">Editar</button>
        <button mat-menu-item *ngIf="isMyPost" style="color: red" (click)="onDeletePost()">Eliminar</button>
        <!-- <button mat-menu-item>Compartir</button> -->
        <!-- <button mat-menu-item>Reportar</button> -->
        <button mat-menu-item>Cancelar</button>
      </mat-menu>

      <span class="date">{{post.dateCreated ? (post.dateCreated?.toDate() | date : 'd LLL':'':'mx') : 'No disponible'}}</span>
    </div>
  </div>

  <!-- Separador -->
  <mat-divider> </mat-divider>

  <!-- Texto -->
  <div class="text" [innerHTML]="post.description! | linky"></div>

  <!-- Imagen -->
  <div *ngIf="hasImage && post?.images && post?.images?.length! > 0" class="image-container" (click)="onOpenModal()">
    <img src="{{imgUrl}}">
  </div>

  <!-- Iconos -->
  <div class="social-container"
    fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="30px">
    <!-- Likes -->
    <div class="likeButton" 
      fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px"
      *ngIf="canUserInteract" >
      <mat-icon *ngIf="!likedByUser" (click)="onLike()">thumb_up</mat-icon>
      <mat-icon *ngIf="likedByUser" class="liked" (click)="onDislike()">thumb_up</mat-icon>
      <span *ngIf="post.likes! > 0">{{post.likes}}</span>
    </div>
    <!-- Comentatios -->
    <div class="commentButton"
      fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px"
      *ngIf="canUserInteract"
      routerLink="/foros/ver/{{post.forumId}}/{{post.id}}/comentarios" 
      [state]="{ post: post?.reference?.path }"
      >
      <mat-icon>mode_comment</mat-icon>
      <span>{{post.comments}}</span>
    </div>
    <!-- Compartir -->
    <!-- <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px"
      *ngIf="canUserInteract" >
      <mat-icon>share</mat-icon>
      <span>{{post.shares}}</span>
    </div> -->
  </div>
  <!-- Agregar comentario -->
  <!-- TODO: Agregar últimos comentarios -->
  <mat-form-field *ngIf="canUserInteract" >
    <mat-label>Agrega un comentario...</mat-label>
    <input matInput placeholder="Escribe que opinas"
      name="comment" #comment 
      autocapitalize="none"
      autocomplete="off"
      (keyup.enter)="(comment.value === '') ? '' : onCommentPost(post, comment.value); comment.value = '';">
      <mat-icon matSuffix class="pointer" 
        matTooltip="Enviar comentario"
        matTooltipShowDelay="300"
        (click)="(comment.value === '') ? '' : onCommentPost(post,comment.value); comment.value = '';">add_comment</mat-icon>
  </mat-form-field>
</div>

<!-- Modal -->
<ng-template #image>
  <div fxLayout fxLayoutAlign="center center">

    <img class="image-modal" src="{{imgUrl}}">
  </div>
</ng-template>