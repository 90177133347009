<div class="container"
  fxLayout="column" fxLayoutAlign="start center">
  <div class="home"
    fxLayout="column" fxLayoutAlign="start" fxLayoutGap="5px">
    <!-- Crear post -->
    <!-- <mat-tab-group>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon create">create</mat-icon>
          {{content.home_write_label}}
        </ng-template>
        <div class="content-container"
          fxLayout="column" fxLayoutAlign="center">
          <mat-form-field>
            <textarea matInput placeholder="{{content.home_write_something_placeholder}}"
            ngModel name="homeCreatePostTextarea" #homeCreatePostTextarea="ngModel"></textarea>
          </mat-form-field>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon insert-photo">insert_photo</mat-icon>
          {{content.home_upload_image_label}}
        </ng-template>
        <div class="content-container"
          fxLayout="column" fxLayoutAlign="center start">
          <span>
            {{content.home_write_something_placeholder}}
          </span>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon forum">forum</mat-icon>
          {{content.home_go_to_forum_label}}
        </ng-template>
        <div class="content-container"
          fxLayout="column" fxLayoutAlign="center start">
          <span>
            {{content.home_write_something_placeholder}}
          </span>
        </div>
      </mat-tab>
    </mat-tab-group> -->
    <!-- Lista de posts -->
    <div  class="post-list"
      fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
      <ng-container *ngFor="let post of posts">
        <app-post [post]="post" [likedByUser]="likedPosts.includes(post.id!)"></app-post>
      </ng-container>

      <!-- Cargar mas resultados -->
      <!-- <button mat-button (click)="onLoadMorePosts()" *ngIf="AreThereNewResults">+ Cargar más resultados</button> -->
      <button mat-button (click)="onLoadMorePosts()">+ Cargar más resultados</button>
    </div>
  </div>
</div>