<!-- Home -->
<div class="container">
  <section class="home">        
    <img class="backgroundImage" src="../../../../../assets/app-preview/bg-figure-1.svg" style="left: 0; top: 0; width: 100%;">    
    <img class="backgroundImage" src="../../../../../assets/app-preview/bg-figure-2.svg" style="right: 0; top: 141px; width: 683px; height: 710px;">    
    <img class="backgroundImage" src="../../../../../assets/app-preview/bg-figure-3.svg" style="right: 0; top: 519px; width: 360px; height: 496px;">    
    <img class="appImage smallImage" src="../../../../../assets/app-preview/app-small.png" style="right: 480px; top: 400px;">    
    <img class="appImage largeImage" src="../../../../../assets/app-preview/app-large.png" style="right: 200px; top: 65px;">    
  
    <!-- Sale speech -->
    <div class="saleSpeech">
      <img class="homeLogoAU" src="../../../../../assets/app-preview/alumni-universe-logo-white.svg" alt="Alumni Universe">
      <h1>ÉSTE ES EL UNIVERSO DE LOS ALUMNI SEMPER ALTIUS</h1>
      <span class="saleSpeechText">Más que una Red Social. <br><br> Reencuentra a quienes compartimos tu pasión, e intercambia apoyos, ideas, productos y servicios.</span>
      <a routerLink="/login"><button class="actionButton" type="button">Inicia sesi&oacute;n</button></a>
      <a routerLink="/crear-cuenta"><button class="actionButton" type="button">Crea tu cuenta</button></a>
  
      <!-- for small screens -->
      <div class="appLargeImageContainer">      
        <img class="largeImage" src="../../../../../assets/app-preview/app-large.png">
      </div>
    </div>
    
    
    <!-- Briefing -->
    <div class="briefing">
      <h2>¡Únete a la comunidad y descubre todos los beneficios y descuentos exclusivos para ti!</h2>
      <!-- <p>Cras a tellus auctor, aliquet arcu semper, efficitur odio. Praesent aliquam risus consectetur, congue nunc nec, hendrerit . Aliquam sapien odio, iaculis quis nisi vitae, rutrum luctus urna. Nunc varius tortor et lacus semper volutpat. Curabitur justo elit, dapibus nec est varius, sagittis lacinia justo.</p> -->
    </div>
    
  </section>

  <!-- Preview -->
  <section class="postContainer">
    <article class="post" *ngFor="let post of posts">
      <div class="postHeader">
        <h4>{{post.title}}</h4>
        <p>{{post.description}}</p>
      </div>
      <img *ngIf="post?.images?.length! > 0" 
        src="{{post?.images![0].downloadUrl!}}" alt="{{post.title}}">
    </article>
  </section>
  
  <!-- Benefits -->
  <section class="benefits">    
    <img class="backgroundImage" src="../../../../../assets/app-preview/bg-figure-white-left.svg" style="left: 0; top: 136vh; width: 104px; z-index: 0;">
    <div class="benefitsContainer">
  
      <h2>Ventajas de pertenecer a ALUMNI UNIVERSE</h2>
      <div class="benefitsList">
        <!-- Item -->
        <div class="benefitsItem">
          <div class="benefitsItemTitle">
            <img src="../../../../../assets/app-preview/check-mark.svg" alt="✓">
            <span>Privacidad</span>
          </div>
          <p>Los participantes estamos protegidos con la mejor tecnología, desarrollada para nuestras necesidades.</p>
        </div>
        <!-- Item -->
        <div class="benefitsItem">
          <div class="benefitsItemTitle">
            <img src="../../../../../assets/app-preview/check-mark.svg" alt="✓">
            <span>Tecnología</span>
          </div>
          <p>Un portal digital único a nivel mundial, exclusivo para los Alumni Semper Altius.</p>
        </div>
        <!-- Item -->
        <div class="benefitsItem">
          <div class="benefitsItemTitle">
            <img src="../../../../../assets/app-preview/check-mark.svg" alt="✓">
            <span>Comunidad</span>
          </div>
          <p>Formamos un Grupo consolidado, dinámico y vibrante.</p>
        </div>
        <!-- Item -->
        <div class="benefitsItem">
          <div class="benefitsItemTitle">
            <img src="../../../../../assets/app-preview/check-mark.svg" alt="✓">
            <span>Cupones</span>
          </div>
          <p>Gran variedad de descuentos y oportunidades para los integrantes de Nuestro Universo.</p>
        </div>
        <!-- Item -->
        <div class="benefitsItem">
          <div class="benefitsItemTitle">
            <img src="../../../../../assets/app-preview/check-mark.svg" alt="✓">
            <span>Marketplace</span>
          </div>
          <p>Intercambia productos y servicios en un ambiente de ética y honestidad.</p>
        </div>
        <!-- Item -->
        <div class="benefitsItem">
          <div class="benefitsItemTitle">
            <img src="../../../../../assets/app-preview/check-mark.svg" alt="✓">
            <span>Foros</span>
          </div>
          <p>Discusión profesional, artística, social, por interés... todo se puede hacer.</p>
        </div>
  
      </div>    
    </div>
  </section>
  
  <!-- Testimonials -->
  <section class="testimonials">
    <h2>Opiniones de nuestros usuarios</h2>
    <div class="testimonialsList">
      <!-- Item -->
      <div class="testimonialItem">
        <img src="../../../../../assets/app-preview/testimonial-1.jpg" alt="">
        <h3>Almudena Segura Ransanz</h3>
        <p>La plataforma es muy intuitiva, me he reconectado con mucha gente, me da seguridad y confianza, además de promover mi negocio, estoy descubriendo el enorme potencial de ALUMNI UNIVERSE!</p>
      </div>
      <!-- Item -->
      <div class="testimonialItem">
        <img src="../../../../../assets/app-preview/testimonial-2.jpg" alt="">
        <h3>Juan Ignacio Avila Castillo</h3>
        <p>Gracias a la plataforma de ALUMNI UNIVERSE puedo proyectar mi perfil profesional en una comunidad con la que me siento plenamente identificado, además de reencontrarme con antiguos compañeros de los que no sabía nada hace tiempo. Recomiendo mucho que hagamos todos crecer esta poderosa herramienta de comunicación.</p>
      </div>
    </div>
  </section>
  
  <!-- Call to action -->
  <section class="callToAction" id="callToAction">
    <h1 style="z-index: 2 !important;">Inscríbete ahora</h1>
    <div class="callToActionContent">
      <div class="callToActionPriceContainer">
        <img class="backgroundImage" src="../../../../../assets/app-preview/bg-figure-25.svg" 
        style="z-index: -1; max-width: 93vw; display: block !important;">
        
        <div class="callToActionPrice">
          <span style="font-size: 1rem;">Por tiempo limitado de <s>$60 USD</s> a</span>
          <span style="font-size: 4rem;">$45 USD</span>
          <span style="font-size: 3rem;">al año</span>

          <a routerLink="/crear-cuenta">Crea tu cuenta</a>
        </div>

      </div>
    </div>

    <footer>
      <nav> 
        <a href="./index.html"><img class="footerLogoAU" src="../../../../../assets/app-preview/alumni-universe-logo-white.svg" alt="Alumni Universe"></a>           
        <span>Todos los derechos reservados</span>      
        <a routerLink="/legal/privacy-policy">Aviso de privacidad</a>      
        <a routerLink="/legal/terms-of-service">Términos de servicio</a>      
      </nav>   
      <nav id="social-media-container">      
        <a href="https://www.facebook.com/AlumniUniverse" rel="noopener" target="_blank">
          <span class="material-icons">
            facebook
          </span>        
        </a>
        <a href="https://www.instagram.com/alumni_universe/?hl=es-la" rel="noopener" target="_blank">
          <img style="width: 26px;" src="../../../../../assets/app-preview/instagram.svg">
        </a>
      </nav>     
    </footer>
  </section>
  <!-- Footer -->
</div>
