<div class="container" [ngStyle]="{'height': isInDialog ? '75vh' : '90vh'}" fxLayout="column" fxLayoutAlign="end center">
  <button mat-icon-button style="color: white;" *ngIf="isInDialog" type="button" fxFlexAlign="flex-end" (click)="onClose()"><mat-icon>close</mat-icon></button>
  <!-- Centra dentro del card -->
  <div class="profile-container"
    fxLayout="column" fxLayoutAlign="end center">
    <div class="profile-info-container"
      fxLayout="column" fxLayoutAlign="start center">

      <!-- Imagen de perfil -->
      <div class="profile-img-container">
        <img *ngIf="user?.img && user?.img?.downloadUrl" 
              src="{{ user!.img!.downloadUrl! }}">
        <button mat-mini-fab *ngIf="isMyProfile"  color="primary" routerLink="/configuracion/editar-perfil"><mat-icon>edit</mat-icon></button>
      </div>

      <!-- FIXME: Membresía -->
      <div style="border-top: none" class="profile-internal-container"
        fxLayout="column" fxLayoutAlign="start center">
        <span class="profile-name">{{ user.name ? (user.name | titlecase) : 'No disponible' }}</span>
        <span class="profile-position" *ngIf="profile && profile.basicInfo?.job">{{ profile?.basicInfo?.job! ? (profile?.basicInfo?.job | titlecase) : 'No disponible' }}</span>
        <span class="profile-college">{{ user.school ? (user.school) : 'No disponible' }}</span>
      </div>
      
      <!-- <div class="profile-internal-container"
        fxLayout="column" fxLayoutAlign="start center">
        <span class="profile-connections-number">300</span>
        <span class="profile-connections-text">{{content.profile_container_connections}}</span>
      </div> -->
      
      <!-- Redes sociales -->
      <div class="profile-internal-container"
        fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">

        <!-- Instagram -->
        <div class="profile-social" *ngIf="profile?.instagram"
          fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
          <img src="../../../../assets/instagram-logo.png">
          <a style="text-decoration: none; color: inherit;" target="_blank" href="https://www.instagram.com/{{profile.instagram ? profile.instagram : ''}}" [innerHTML]="profile.instagram ? (profile.instagram! | linky) : 'No disponible'"></a>
        </div>

        <!-- Twitter -->
        <div class="profile-social" *ngIf="profile?.twitter"
          fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
          <img src="../../../../assets/twitter-logo.png">
          <a style="text-decoration: none; color: inherit;" target="_blank" href="https://www.twitter.com/{{profile.twitter ? profile.twitter : ''}}" [innerHTML]="profile.twitter ? (profile.twitter! | linky) : 'No disponible'"></a>
        </div>

        <!-- Skype -->
        <!-- <div class="profile-social" *ngIf="profile?.twitter"
          fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
          <img src="../../../../assets/skype-logo.png">
          <a style="text-decoration: none; color: inherit;" target="_blank" href="https://www.skype.com/{{profile.skype ? profile.skype : ''}}" [innerHTML]="profile.twitter ? (profile.twitter! | linky) : 'No disponible'"></a>
        </div> -->

      </div>
      
      <div class="profile-internal-container"
        fxLayout="column" fxLayoutAlign="start center"
        fxLayoutGap="10px">
        <!-- Configuracion de la cuenta -->
        <span *ngIf="isMyProfile" class="profile-action" routerLink="/configuracion">{{content.profile_container_account_configuration}}</span>
        <!-- Solicitud de amistad -->
        <span *ngIf="!isMyProfile && !isFriend && !hasRequested" class="profile-action" (click)="onSendFriendRequest()">Agregar a mi red</span>
        <!-- Perfil profesional -->
        <span *ngIf="isMyProfile" class="profile-action" routerLink="/perfil-profesional">{{content.profile_container_see_professional_profile}}</span>
        <span *ngIf="!isMyProfile && isFriend" class="profile-action" routerLink="/buscar/ver/{{passedUser.id}}" (click)="onClose()">{{content.profile_container_see_professional_profile}} DE LA CONEXIÓN</span>
        <span *ngIf="!isMyProfile && !isFriend && hasRequested" class="profile-action">Solicitud enviada</span>
      </div>
    </div>
  </div>
</div>