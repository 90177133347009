<mat-sidenav-container>
  <mat-sidenav mode="over" [position]="'end'" #sidenav >
    <mat-nav-list
      fxLayout="column" fxLayoutAlign="start">
      <div class="sidenav-top-container"
        fxLayout="row" fxLayoutAlign="center start">
        <div fxLayout="column" fxLayoutAlign="start center"
          fxLayoutGap="10px">
          <!-- Foto de perfil -->
          <div class="profile-sidenav-container">
            <!-- <img src="{{ user.img.downloadUrl }}"> -->
            <img *ngIf="user?.img && user?.img?.downloadUrl" 
              src="{{ user!.img!.downloadUrl! }}">
          </div>
          <!-- Nombre -->
          <span class="profile-name">{{ user.name }}</span>
          <!-- Membresía -->

        </div>
      </div>
      <div class="sidenav-bottom-container"
        fxLayout="column" fxLayoutAlign="start center"
        fxLayoutGap="40px">
        <span class="sidenav-item"
          fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px"
          (click)="sidenav.close()"
          routerLink="/">
          <mat-icon>home</mat-icon>
          <span>INICIO</span>
        </span>
        <span class="sidenav-item"
          fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px"
          (click)="sidenav.close()"
          routerLink="/perfil">
          <mat-icon>person</mat-icon>
          <span>PERFIL</span>
        </span>
        <span class="sidenav-item"
          fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px"
          (click)="sidenav.close()"
          routerLink="/mesa-de-negocios/marketplace">
          <mat-icon>attach_money</mat-icon>
          <span>MARKETPLACE</span>
        </span>
        <span class="sidenav-item"
          fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px"
          (click)="sidenav.close()"
          routerLink="/mesa-de-negocios/cupones">
          <mat-icon>local_offer</mat-icon>
          <span>CUPONES</span>
        </span>
        <span class="sidenav-item"
          fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px"
          (click)="sidenav.close()"
          routerLink="/foros">
          <mat-icon>forum</mat-icon>
          <span>FOROS</span>
        </span>
        <span class="sidenav-item"
          fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px"
          (click)="sidenav.close()"
          routerLink="/buscar">
          <mat-icon>person_search</mat-icon>
          <span>BUSCAR</span>
        </span>
        <span class="sidenav-item"
          fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px"
          (click)="sidenav.close()"
          routerLink="/notificaciones">
          <mat-icon>notifications</mat-icon>
          <span>NOTIFICACIONES</span>
        </span>
        <span class="sidenav-item"
          fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px"
          (click)="sidenav.close()"
          routerLink="/configuracion">
          <mat-icon>settings</mat-icon>
          <span>CONFIGURACIÓN</span>
        </span>
      </div>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <!-- ---------------------------------------------------------------------- -->
    <!-- HEADER -->
    <!-- ---------------------------------------------------------------------- -->
    <mat-toolbar>
      <mat-toolbar-row class="navigation-header"
      fxLayout="row" fxLayoutAlign="space-between center">
        <span style="width: 40px;"></span>
        <span class="header-title">{{ title }}</span>
        <div>
          <!-- Cupones -->
          <button mat-button routerLink="/chat">
            <mat-icon>chat</mat-icon>
            <!-- <mat-icon *ngIf="couponsNumber <= 0">local_offer</mat-icon>
            <mat-icon *ngIf="couponsNumber > 0" [matBadge]="couponsNumber" matBadgeColor="accent">local_offer</mat-icon> -->
          </button>
          <!-- Menu - Adicionales -->
          <button id="matMenu" mat-icon-button (click)="sidenav.toggle()"><mat-icon id="home">sort</mat-icon></button>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>

    <!-- ---------------------------------------------------------------------- -->
    <!-- CONTENIDO -->
    <!-- ---------------------------------------------------------------------- -->

    <div id="global-container" fxLayout="row" fxLayoutAlign="center start">
      <div class="main-container" fxLayout="column">
        <router-outlet></router-outlet>
      </div>
    </div>

    <!-- ---------------------------------------------------------------------- -->
    <!-- FOOTER -->
    <!-- ---------------------------------------------------------------------- -->
    <footer class="footer-container" fxLayout="column" fxLayoutAlign="center center">
      <div class="navigation-footer"
        fxLayout="row" fxLayoutAlign="space-around center">
        <!-- Inicio -->
        <div class="footerButton" 
          [ngClass]="{'footerButtonSelected': title === 'INICIO'}" 
          routerLink="/">
          <mat-icon>home</mat-icon>
        </div>
        <!-- Buscar -->
        <!-- <button mat-button routerLink="/buscar">
          <mat-icon>person_search</mat-icon>
        </button> -->
        <!-- Cupones -->
        <div class="footerButton" 
          [ngClass]="{'footerButtonSelected': title === 'CUPONES'}"
          routerLink="/mesa-de-negocios/cupones">
          <mat-icon *ngIf="couponsNumber <= 0">local_offer</mat-icon>
          <mat-icon *ngIf="couponsNumber > 0" [matBadge]="couponsNumber" matBadgeColor="accent">local_offer</mat-icon>
        </div>
        <!-- Foros -->
        <div class="footerButton" 
          [ngClass]="{'footerButtonSelected': title === 'FOROS'}"
          routerLink="/foros">
          <mat-icon >forum</mat-icon>
          <!-- [matBadge]="publicService.notificationsNumber ? publicService.notificationsNumber : 0" -->
          <!-- matBadgePosition="above after"
          [matBadgeSize]="'small'"
          matBadgeColor="warn" -->
        </div>
        <!-- Notificaciones -->
        <div class="footerButton"  
          [ngClass]="{'footerButtonSelected': title === 'NOTIFICACIONES'}"
          routerLink="/notificaciones">
          <mat-icon *ngIf="notificationsNumber <= 0">notifications</mat-icon>
          <mat-icon *ngIf="notificationsNumber > 0" [matBadge]="notificationsNumber" matBadgeColor="accent">notifications</mat-icon>
        </div>
        <!-- Marketplace -->
        <!-- <button mat-button routerLink="/mesa-de-negocios/marketplace">
          <mat-icon >attach_money</mat-icon>
        </button> -->
        <div class="footerButton" 
          [ngClass]="{'footerButtonSelected': title === 'PERFIL'}"
          routerLink="/perfil">
          <div class="profile-footer-container" >
            <img *ngIf="user?.img && user?.img?.downloadUrl" 
              src="{{ user!.img!.downloadUrl! }}">
          </div>
        </div>                        
      </div>
    </footer>
    
  </mat-sidenav-content>
</mat-sidenav-container>