import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BasicModule } from 'shared/modules/basic.module';
import { PostComponent } from './post/post.component';
import { MaterialModule } from 'shared/modules/material.module';
import { SuccessfulPublicationComponent } from './successful-publication/successful-publication.component';
import { CreateAdvertisingComponent } from './create-advertising/create-advertising.component';
import { GeneralSearchComponent } from './general-search/general-search.component';
import { CreatorImgPipe } from 'shared/pipes/creator-img.pipe';
import { CreatorNamePipe } from 'shared/pipes/creator-name.pipe';
import { SettingsFriendshipComponent } from './settings/settings-friendship/settings-friendship.component';
import { ForumNamePipe } from 'shared/pipes/forum-name.pipe';
@NgModule({
  declarations: [
    PostComponent,
    SuccessfulPublicationComponent,
    CreateAdvertisingComponent,
    GeneralSearchComponent,
    CreatorImgPipe,
    CreatorNamePipe,
    ForumNamePipe,
    SettingsFriendshipComponent,
  ],
  imports: [
    BasicModule,
    MaterialModule,
    RouterModule,
  ],
  exports: [
    PostComponent,
    SuccessfulPublicationComponent,
    CreateAdvertisingComponent,
    GeneralSearchComponent,
    CreatorImgPipe,
    CreatorNamePipe,
  ],
  providers: [
    CreatorImgPipe,
    CreatorNamePipe,
    ForumNamePipe,
  ]
  // entryComponents: [
  //   PostComponent,
  //   SuccessfulPublicationComponent,
  //   CreateAdvertisingComponent,
  //   GeneralSearchComponent,
  // ],
})
export class ComponentsModule { }
