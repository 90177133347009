import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, Inject, Injectable, NgModule, PLATFORM_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
// import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
// import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { environment } from '../environments/environment';
import { MaterialModule } from 'shared/modules/material.module';
import { CommonModule, isPlatformBrowser, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateService } from 'shared/services/translate.service';

import localeMX from '@angular/common/locales/es-MX';
import { FlexLayoutModule } from '@angular/flex-layout';
registerLocaleData(localeMX, 'mx');

// particular imports for hammer
import * as Hammer from 'hammerjs';
import { HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import { PostComponent } from './common/components/post/post.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
// import { SuccessfulPublicationComponent } from './common/components/successful-publication/successful-publication.component';
import { BasicModule } from 'shared/modules/basic.module';
import { ComponentsModule } from './common/components/components.module';
import { LoginComponent } from './common/components/login/login.component';
import { SignupComponent } from './common/components/signup/signup.component';
import { NavigationComponent } from './common/components/navigation/navigation.component';
import { HomeComponent } from './common/components/home/home.component';
import { NotificationsComponent } from './common/components/notifications/notifications.component';
import { NotificationItemComponent } from './common/components/notification-item/notification-item.component';
import { ProfileComponent } from './common/components/profile/profile.component';
import { SettingsComponent } from './common/components/settings/settings.component';
import { SettingsNotificationsComponent } from './common/components/settings/settings-notifications/settings-notifications.component';
import { SettingsSecurityComponent } from './common/components/settings/settings-security/settings-security.component';
import { SettingsFaqComponent } from './common/components/settings/settings-faq/settings-faq.component';
import { SettingsPasswordComponent } from './common/components/settings/settings-password/settings-password.component';
import { SettingsPaymentsComponent } from './common/components/settings/settings-payments/settings-payments.component';
import { SettingsReportsListComponent } from './common/components/settings/settings-reports-list/settings-reports-list.component';
import { ProfileProfessionalComponent } from './common/components/profile/profile-professional/profile-professional.component';
import { ProfileEditComponent } from './common/components/profile/profile-edit/profile-edit.component';
import { ProfileEditProfessionalComponent } from './common/components/profile/profile-edit-professional/profile-edit-professional.component';
import { UserSearchComponent } from './common/components/user-search/user-search.component';
import { ConfirmDialogComponent } from './common/components/dialogs/confirm-dialog/confirm-dialog.component';
import { ServiceWorkerModule, SwPush, SwUpdate } from '@angular/service-worker';
import { GlobalErrorHandlerService } from 'shared/services/global-error-handler.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginAppPreviewComponent } from './common/components/login/login-app-preview/login-app-preview.component';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { RouteReuseStrategy } from '@angular/router';

@Injectable() 
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

@NgModule({
  declarations: [
    AppComponent,
    // PostComponent,
    // SuccessfulPublicationComponent,
    LoginComponent,
    LoginAppPreviewComponent,
    SignupComponent,
    NavigationComponent,
    HomeComponent,
    NotificationsComponent,
    NotificationItemComponent,
    ProfileComponent,
    SettingsComponent,
    SettingsNotificationsComponent,
    SettingsSecurityComponent,
    SettingsFaqComponent,
    SettingsPasswordComponent,
    SettingsPaymentsComponent,
    SettingsReportsListComponent,
    ProfileProfessionalComponent,
    ProfileEditComponent,
    ProfileEditProfessionalComponent,
    UserSearchComponent,
    ConfirmDialogComponent,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFirePerformanceModule,
    AngularFireAnalyticsModule,
    // environment.production ? [] : AkitaNgDevtools.forRoot(),
    // AkitaNgRouterStoreModule.forRoot(),
    BasicModule,
    HammerModule,
    ComponentsModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig, },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    // { provide: NG_ENTITY_SERVICE_CONFIG, useValue: { baseUrl: 'https://jsonplaceholder.typicode.com' }},
    // {
    //   provide: ErrorHandler,
    //   useClass: GlobalErrorHandlerService,
    // },
  ],
  bootstrap: [AppComponent],
})

export class AppModule {
  constructor(private update: SwUpdate,
              private push: SwPush,
              private snackbar: MatSnackBar,
              private translate: TranslateService,
              @Inject(PLATFORM_ID) private platformId: Object) {
      
      // Servicio de traduccion
      if (isPlatformBrowser(platformId)) {
        translate.use(navigator.language);
      }

      // Actualización de la aplicación
    // Si hay un nuevo service worker le avisamos al usuario para que pueda actualizar el service worker
    update.available.subscribe(newUpdate => {
      const snack = snackbar.open('¡Hay una nueva versión disponible!', 'Activar');
      snack.onAction().subscribe(() => {
        window.location.reload();
      });
    });

    // Recepción de notificaciones dentro de la aplicación
    push.messages.subscribe((message: any) => {
      snackbar.open(`${message.notification.title}: ${message.notification.body}`,
        null!, {duration: 5000});
    });
  }
}
