<div class="container"
  fxLayout="column" fxLayoutAlign="start center">
  <div class="ad-container"
    fxLayout="column" fxLayoutAlign="start">
    <mat-horizontal-stepper [linear]="true">
      <!-- label="{{content.search_new_offer_step_1_title}}" -->
      <mat-step [stepControl]="currentForm"
        class="ad-first-step"
        fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <span class="ad-title">{{title ? title : 'DEFAULT'}}</span>
        <span class="ad-subtitle">{{subtitle}}</span>
        <!-- <span class="ad-conditions">{{conditions}}</span>
        <a class="ad-link">{{link}}</a> -->

        <!-- Oferta o busqueda -->
        <form *ngIf="mode === 'offer' || mode === 'search'" [formGroup]="currentForm"
          fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">
          <span *ngIf="mode === 'offer'">{{content.search_new_offer_step_radio_1_label}}</span>
          <span *ngIf="mode === 'search'">{{content.search_new_request_step_radio_1_label}}</span>
          <div fxLayout="row">
            <label>
              <input type="radio" value="sales" formControlName="type">
                <span>{{content.search_new_offer_step_radio_1_option_1}}</span>
            </label>
            <label>
              <input type="radio" value="services" formControlName="type">
                <span>{{content.search_new_offer_step_radio_1_option_2}}</span>
            </label>
            <label *ngIf="mode === 'offer'">
              <input type="radio" value="real estate" formControlName="type">
                <span>{{content.search_new_request_step_radio_1_option_3}}</span>
            </label>
            <label *ngIf="mode === 'search'">
              <input type="radio" value="real estate" formControlName="type">
                <span>{{content.search_new_offer_step_radio_1_option_3}}</span>
            </label>
          </div>
          <label fxLayout="column" fxLayoutAlign="start">
            <span *ngIf="mode === 'offer'">{{content.search_new_offer_step_input_1_label}}</span>
            <span *ngIf="mode === 'search'">{{content.search_new_request_step_input_1_label}}</span>
            <mat-form-field appearance="outline">
              <mat-select formControlName="category" placeholder="Categoría">
                <mat-option [value]="category" *ngFor="let category of categories">{{category}}</mat-option>
              </mat-select>
            </mat-form-field>
          </label>
          <label fxLayout="column" fxLayoutAlign="start">
            <span *ngIf="mode === 'offer'">{{content.search_new_offer_step_input_valid_end_label}}</span>
            <span *ngIf="mode === 'search'">{{content.search_new_request_step_input_valid_end_label}}</span>
            <mat-form-field appearance="outline">
              <input matInput [matDatepicker]="picker" [min]="today" formControlName="validDateEnd">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </label>
          <label fxLayout="column" fxLayoutAlign="start">
            <span *ngIf="mode === 'offer'">{{content.search_new_offer_step_input_2_label}}</span>
            <span *ngIf="mode === 'search'">{{content.search_new_request_step_input_2_label}}</span>
            <mat-form-field appearance="outline">
              <input matInput type="text" formControlName="title" placeholder="Título">
            </mat-form-field>
          </label>
          <label *ngIf="mode === 'offer'"
            fxLayout="column" fxLayoutAlign="start">
            <span>{{content.search_new_offer_step_input_3_label}}</span>
            <mat-form-field appearance="outline">
              <input matInput type="number" [min]="0" formControlName="price" placeholder="Precio">
            </mat-form-field>
          </label>
          
          <label fxLayout="column" fxLayoutAlign="start">
            <span *ngIf="mode === 'offer'">{{content.search_new_offer_step_input_4_label}}</span>
            <span *ngIf="mode === 'search'">{{content.search_new_request_step_input_3_label}}</span>
            <mat-form-field appearance="outline">
              <textarea matInput type="text" formControlName="description"
                placeholder="Cuéntale a la gente de lo que se trata tu oferta."></textarea>
                <mat-hint>Puedes agregar un enlace para direccionar al usuario a un archivo pdf. </mat-hint>
            </mat-form-field>
          </label>
          <button class="next-button" type="button" mat-raised-button matStepperNext [disabled]="!currentForm.valid">{{content.search_new_offer_step_next_button}}</button>
        </form>

        <!-- directorio -->
        <form *ngIf="mode === 'business'" [formGroup]="currentForm"
          fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">
          <label *ngIf="hasProfessionalProfile" fxLayout="row" fxLayoutAlign="start">
            <input type="checkbox" name="profileProfessionalCheckbox" #profileProfessionalCheckbox (click)="onChangeAddProfessionalProfile();">
            <span>{{content.search_new_business_step_checkbox_1_label}}<span class="ad-link" routerLink="/perfil-profesional"> {{content.search_new_business_step_checkbox_1_professional_profile}}</span></span>
          </label>
          <div class="no-profile-advice" *ngIf="hasProfessionalProfile === false" fxLayout="row" fxLayoutAlign="start">
            <span>{{content.search_new_business_step_advice_label}}<span class="ad-link" routerLink="/perfil-profesional"> {{content.search_new_business_step_advice_link}}.</span></span>
          </div>
          {{content.search_new_business_step_radio_1_label}}
          <div fxLayout="row">
            <label>
              <input type="radio" value="business" formControlName="showAs">
              <span>{{content.search_new_business_step_radio_1_option_1}}</span>
            </label>
            <label *ngIf="hasProfessionalProfile">
              <input type="radio" value="services" formControlName="showAs">
              <span>{{content.search_new_business_step_radio_1_option_2}}</span>
            </label>
          </div>
          <label fxLayout="column" fxLayoutAlign="start">
            <span>{{content.search_new_business_step_input_1_label}}</span>
            <mat-form-field appearance="outline">
              <mat-select formControlName="category" placeholder="Categoría">
                <mat-option [value]="category" *ngFor="let category of categories">
                  {{category}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </label>
          <label fxLayout="column" fxLayoutAlign="start">
            <span>{{content.search_new_business_step_input_2_label}}</span>
            <mat-form-field appearance="outline">
              <input matInput type="text" formControlName="title">
            </mat-form-field>
          </label>
          <label fxLayout="column" fxLayoutAlign="start">
            <span>{{content.search_new_business_step_input_3_label}}</span>
            <mat-form-field appearance="outline">
              <input matInput type="text" [min]="0" formControlName="website">
            </mat-form-field>
          </label>
          
          <label fxLayout="column" fxLayoutAlign="start">
            <span>{{content.search_new_business_step_input_4_label}}</span>
            <mat-form-field appearance="outline">
              <textarea matInput type="text" formControlName="description"></textarea>
              <mat-hint>Puedes agregar un enlace para direccionar al usuario a un archivo pdf. </mat-hint>
            </mat-form-field>
          </label>
          <button class="next-button" type="button" mat-raised-button matStepperNext [disabled]="!currentForm.valid">{{content.search_new_offer_step_next_button}}</button>
        </form>

        <!-- bolsa de trabajo -->
        <form *ngIf="mode === 'job'" [formGroup]="currentForm"
          fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">
          <label fxLayout="column" fxLayoutAlign="start">
            <span>{{content.search_new_job_step_input_1_label}}</span>
            <mat-form-field appearance="outline">
              <input matInput type="text" formControlName="title">
            </mat-form-field>
          </label>
          <label fxLayout="column" fxLayoutAlign="start">
            <span>{{content.search_new_job_step_input_2_label}}</span>
            <mat-form-field appearance="outline">
              <mat-select placeholder="Categoría" formControlName="category">
                <mat-option [value]="category" *ngFor="let category of categories">
                  {{category}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </label>
          <label fxLayout="column" fxLayoutAlign="start">
            <span>{{content.search_new_job_step_input_3_label}}</span>
            <mat-form-field appearance="outline">
              <input matInput type="text" [min]="0" formControlName="website">
            </mat-form-field>
          </label>
          <label fxLayout="column" fxLayoutAlign="start">
            <span>{{content.search_new_job_step_input_4_label}}</span>
            <mat-form-field appearance="outline">
              <input matInput type="text" [min]="0" formControlName="socialUrl">
            </mat-form-field>
          </label>
          <label fxLayout="column" fxLayoutAlign="start">
            <span>{{content.search_new_job_step_input_5_label}}</span>
            <mat-form-field appearance="outline">
              <textarea matInput type="text" formControlName="description"></textarea>
              <mat-hint>Puedes agregar un enlace para direccionar al usuario a un archivo pdf. </mat-hint>
            </mat-form-field>
          </label>
          <button class="next-button" type="button" mat-raised-button matStepperNext [disabled]="!currentForm.valid">{{content.search_new_offer_step_next_button}}</button>
        </form>
      </mat-step>

      <mat-step [stepControl]="currentForm2">
        <span class="ad-title">{{content.search_new_offer_step_title_2}}</span>
        <!-- Oferta o busqueda -->
        <form *ngIf="mode === 'offer' || mode === 'search'" [formGroup]="currentForm2"
          fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">
          <span *ngIf="mode === 'offer'">{{content.search_new_offer_step_input_5_label}}</span>
          <div *ngIf="mode === 'offer'" class="filesGrid"
            fxLayout="row" fxLayoutAlign="center start">
            <!-- Imagen 1 -->
            <div *ngIf="images[0]">
              <img src="{{images[0].downloadUrl}}" alt="{{images[0].name}}"
                [matMenuTriggerFor]="menuDownloadbleMedia">
              <mat-menu #menuDownloadbleMedia="matMenu">
                <a style="text-decoration: none;" href="{{ images[0].downloadUrl }}" target="_blank">
                  <button mat-menu-item>
                    Descargar
                  </button>         
                </a>
                <button mat-menu-item 
                  (click)="uploadImage1.click(); imagesIndex = 0; imageIsNew = false;"
                  type="button">
                  Editar
                </button>         
                <button mat-menu-item 
                  (click)="onDeleteFile(0)"
                  type="button">
                  Borrar
                </button>         
              </mat-menu>
            </div>
            <button mat-icon-button color="primary" *ngIf="!images[0]" type="button"
            (click)="uploadImage1.click(); imageIsNew = true; imagesIndex = 0;"><mat-icon>attach_file</mat-icon></button>
            <input hidden type="file" 
              #uploadImage1 accept="image/*"
              (change)="onUploadFile($event, imagesIndex, imageIsNew)">

            <!-- Imagen 2 -->
            <div *ngIf="images[1]">
              <img src="{{images[1].downloadUrl}}" alt="{{images[1].name}}"
                [matMenuTriggerFor]="menuDownloadbleMedia">
              <mat-menu #menuDownloadbleMedia="matMenu">
                <a style="text-decoration: none;" href="{{ images[1].downloadUrl }}" target="_blank">
                  <button mat-menu-item>
                    Descargar
                  </button>         
                </a>
                <button mat-menu-item 
                  (click)="uploadImage1.click(); imagesIndex = 1; imageIsNew = false;"
                  type="button">
                  Editar
                </button>         
                <button mat-menu-item 
                  (click)="onDeleteFile(1)"
                  type="button">
                  Borrar
                </button>         
              </mat-menu>
            </div>
            <button mat-icon-button color="primary" *ngIf="!images[1]" type="button"
            (click)="uploadImage2.click(); imageIsNew = true; imagesIndex = 1;"><mat-icon>attach_file</mat-icon></button>
            <input hidden type="file" 
              #uploadImage2 accept="image/*"
              (change)="onUploadFile($event, imagesIndex, imageIsNew)"> 

            <!-- Imagen 3 -->
            <div *ngIf="images[2]">
              <img src="{{images[2].downloadUrl}}" alt="{{images[2].name}}"
                [matMenuTriggerFor]="menuDownloadbleMedia">
              <mat-menu #menuDownloadbleMedia="matMenu">
                <a style="text-decoration: none;" href="{{ images[2].downloadUrl }}" target="_blank">
                  <button mat-menu-item>
                    Descargar
                  </button>         
                </a>
                <button mat-menu-item 
                  (click)="uploadImage1.click(); imagesIndex = 2; imageIsNew = false;"
                  type="button">
                  Editar
                </button>         
                <button mat-menu-item 
                  (click)="onDeleteFile(2)"
                  type="button">
                  Borrar
                </button>         
              </mat-menu>
            </div>
            <button mat-icon-button color="primary" *ngIf="!images[2]" type="button"
            (click)="uploadImage2.click(); imageIsNew = true; imagesIndex = 2;"><mat-icon>attach_file</mat-icon></button>
            <input hidden type="file" 
              #uploadImage2 accept="image/*"
              (change)="onUploadFile($event, imagesIndex, imageIsNew)"> 
            
            <!-- Imagen 4 -->
            <div *ngIf="images[3]">
              <img src="{{images[3].downloadUrl}}" alt="{{images[3].name}}"
                [matMenuTriggerFor]="menuDownloadbleMedia">
              <mat-menu #menuDownloadbleMedia="matMenu">
                <a style="text-decoration: none;" href="{{ images[3].downloadUrl }}" target="_blank">
                  <button mat-menu-item>
                    Descargar
                  </button>         
                </a>
                <button mat-menu-item 
                  (click)="uploadImage1.click(); imagesIndex = 3; imageIsNew = false;"
                  type="button">
                  Editar
                </button>         
                <button mat-menu-item 
                  (click)="onDeleteFile(3)"
                  type="button">
                  Borrar
                </button>         
              </mat-menu>
            </div>
            <button mat-icon-button color="primary" *ngIf="!images[3]" type="button"
            (click)="uploadImage3.click(); imageIsNew = true; imagesIndex = 3;"><mat-icon>attach_file</mat-icon></button>
            <input hidden type="file" 
              #uploadImage3 accept="image/*"
              (change)="onUploadFile($event, imagesIndex, imageIsNew)"> 
            
            <!-- Imagen 5 -->
            <div *ngIf="images[4]">
              <img src="{{images[4].downloadUrl}}" alt="{{images[4].name}}"
                [matMenuTriggerFor]="menuDownloadbleMedia">
              <mat-menu #menuDownloadbleMedia="matMenu">
                <a style="text-decoration: none;" href="{{ images[4].downloadUrl }}" target="_blank">
                  <button mat-menu-item>
                    Descargar
                  </button>         
                </a>
                <button mat-menu-item 
                  (click)="uploadImage1.click(); imagesIndex = 4; imageIsNew = false;"
                  type="button">
                  Editar
                </button>         
                <button mat-menu-item 
                  (click)="onDeleteFile(4)"
                  type="button">
                  Borrar
                </button>         
              </mat-menu>
            </div>
            <button mat-icon-button color="primary" *ngIf="!images[4]" type="button"
            (click)="uploadImage4.click(); imageIsNew = true; imagesIndex = 4;"><mat-icon>attach_file</mat-icon></button>
            <input hidden type="file" 
              #uploadImage4 accept="image/*"
              (change)="onUploadFile($event, imagesIndex, imageIsNew)"> 
            
          </div>
          <label fxLayout="column" fxLayoutAlign="start">
            <span *ngIf="mode === 'offer'">{{content.search_new_offer_step_input_6_label}}</span>
            <span *ngIf="mode === 'search'">{{content.search_new_request_step_input_4_label}}</span>
             <mat-form-field appearance="outline">
               <mat-select formControlName="state" placeholder="Estado">
                 <mat-option [value]="state.value" *ngFor="let state of states">{{state.title}}</mat-option>
               </mat-select>
            </mat-form-field>
          </label>
          <label fxLayout="column" fxLayoutAlign="start">
            <span *ngIf="mode === 'offer'">{{content.search_new_offer_step_input_7_label}}</span>
            <span *ngIf="mode === 'search'">{{content.search_new_request_step_input_5_label}}</span>
            <mat-form-field appearance="outline">
              <input matInput type="text" formControlName="city" placeholder="Ciudad">
              <!-- <mat-select formControlName="city">
                <mat-option [value]="city" *ngFor="let city of cities" placeholder="Ciudad">{{city}}</mat-option>
              </mat-select> -->
            </mat-form-field>
          </label>
          <label fxLayout="column" fxLayoutAlign="start">
            <span *ngIf="mode === 'offer'">{{content.search_new_offer_step_input_8_label}}</span>
            <span *ngIf="mode === 'search'">{{content.search_new_request_step_input_6_label}}</span>
            <mat-form-field appearance="outline">
              <input matInput type="tel" formControlName="contactPhone" placeholder="Teléfono">
            </mat-form-field>
          </label>
          <label fxLayout="column" fxLayoutAlign="start">
            <span *ngIf="mode === 'offer'">{{content.search_new_offer_step_input_9_label}}</span>
            <span *ngIf="mode === 'search'">{{content.search_new_request_step_input_7_label}}</span>
            <mat-form-field appearance="outline">
              <input matInput type="email" formControlName="contactEmail" placeholder="Correo">
            </mat-form-field>
          </label>
          
          {{content.search_new_offer_step_radio_2_label}}
          <div fxLayout="row">
            <label>
              <input type="radio" value="phone" formControlName="showInfo">
                <span>{{content.search_new_offer_step_radio_2_option_1}}</span>
            </label>
            <label>
              <input type="radio" value="email" formControlName="showInfo">
              <span>{{content.search_new_offer_step_radio_2_option_2}}</span>
            </label>
            <label>
              <input type="radio" value="both" formControlName="showInfo">
              <span>{{content.search_new_offer_step_radio_2_option_3}}</span>
            </label>
          </div>
          <label fxLayout="row" fxLayoutAlign="start">
            <input type="checkbox" name="acceptCheckbox" #acceptCheckbox (click)="onChangeAccepted();">
            <span>{{content.search_new_offer_step_terms_and_conditions_1}}<span>{{content.search_new_offer_step_terms_and_conditions_link_1}}</span>{{content.search_new_offer_step_terms_and_conditions_2}}<span>{{content.search_new_offer_step_terms_and_conditions_link_2}}</span></span>
          </label>
          <!-- [disabled]="!currentForm2.valid || !isAccepted  || images.length === 0" -->
          <button class="next-button" type="button" mat-raised-button
          [disabled]="!currentForm2.valid || !isAccepted || (mode === 'offer' ? images.length === 0 : false)"
          (click)="onPublish()"
          >{{content.search_new_offer_step_publish_button}}</button>
        </form>

        <!-- directorio -->
        <form *ngIf="mode === 'business'" [formGroup]="currentForm2"
          fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">
          <label fxLayout="column" fxLayoutAlign="start">
            <span>{{content.search_new_business_step_input_5_label}}</span>
            <mat-form-field appearance="outline">
              <input matInput type="number" [min]="0" formControlName="expYears">
            </mat-form-field>
            <!-- <select formControlName="expYears">
              <option [value]="state" *ngFor="let state of states">{{state}}</option>
            </select> -->
          </label>
          <span>{{content.search_new_business_step_input_6_label}}</span>
          <div id="bussines-img" class="filesGrid"
            fxLayout="row" fxLayoutAlign="center start">
            <!-- Imagen 1 -->
            <div *ngIf="images[0]">
              <img src="{{images[0].downloadUrl}}" alt="{{images[0].name}}"
                [matMenuTriggerFor]="menuDownloadbleMedia">
              <mat-menu #menuDownloadbleMedia="matMenu">
                <a style="text-decoration: none;" href="{{ images[0].downloadUrl }}" target="_blank">
                  <button mat-menu-item>
                    Descargar
                  </button>         
                </a>
                <button mat-menu-item 
                  (click)="uploadImage1.click(); imagesIndex = 0; imageIsNew = false;"
                  type="button">
                  Editar
                </button>         
                <button mat-menu-item 
                  (click)="onDeleteFile(0)"
                  type="button">
                  Borrar
                </button>         
              </mat-menu>
            </div>
            <button mat-icon-button color="primary" *ngIf="!images[0]" type="button"
            (click)="uploadImage1.click(); imageIsNew = true; imagesIndex = 0;"><mat-icon>attach_file</mat-icon></button>
            <input hidden type="file" 
              #uploadImage1 accept="image/*"
              (change)="onUploadFile($event, imagesIndex, imageIsNew)">

            <!-- Imagen 2 -->
            <div *ngIf="images[1]">
              <img src="{{images[1].downloadUrl}}" alt="{{images[1].name}}"
                [matMenuTriggerFor]="menuDownloadbleMedia">
              <mat-menu #menuDownloadbleMedia="matMenu">
                <a style="text-decoration: none;" href="{{ images[1].downloadUrl }}" target="_blank">
                  <button mat-menu-item>
                    Descargar
                  </button>         
                </a>
                <button mat-menu-item 
                  (click)="uploadImage2.click(); imagesIndex = 1; imageIsNew = false;"
                  type="button">
                  Editar
                </button>         
                <button mat-menu-item 
                  (click)="onDeleteFile(1)"
                  type="button">
                  Borrar
                </button>         
              </mat-menu>
            </div>
            <button mat-icon-button color="primary" *ngIf="!images[1]" type="button"
            (click)="uploadImage2.click(); imageIsNew = true; imagesIndex = 1;"><mat-icon>attach_file</mat-icon></button>
            <input hidden type="file" 
              #uploadImage2 accept="image/*"
              (change)="onUploadFile($event, imagesIndex, imageIsNew)"> 

            <!-- Imagen 3 -->
            <div *ngIf="images[2]">
              <img src="{{images[2].downloadUrl}}" alt="{{images[2].name}}"
                [matMenuTriggerFor]="menuDownloadbleMedia">
              <mat-menu #menuDownloadbleMedia="matMenu">
                <a style="text-decoration: none;" href="{{ images[2].downloadUrl }}" target="_blank">
                  <button mat-menu-item>
                    Descargar
                  </button>         
                </a>
                <button mat-menu-item 
                  (click)="uploadImage3.click(); imagesIndex = 2; imageIsNew = false;"
                  type="button">
                  Editar
                </button>         
                <button mat-menu-item 
                  (click)="onDeleteFile(2)"
                  type="button">
                  Borrar
                </button>         
              </mat-menu>
            </div>
            <button mat-icon-button color="primary" *ngIf="!images[2]" type="button"
            (click)="uploadImage3.click(); imageIsNew = true; imagesIndex = 2;"><mat-icon>attach_file</mat-icon></button>
            <input hidden type="file" 
              #uploadImage3 accept="image/*"
              (change)="onUploadFile($event, imagesIndex, imageIsNew)">

            <!-- Imagen 4 -->
            <div *ngIf="images[3]">
              <img src="{{images[3].downloadUrl}}" alt="{{images[3].name}}"
                [matMenuTriggerFor]="menuDownloadbleMedia">
              <mat-menu #menuDownloadbleMedia="matMenu">
                <a style="text-decoration: none;" href="{{ images[3].downloadUrl }}" target="_blank">
                  <button mat-menu-item>
                    Descargar
                  </button>         
                </a>
                <button mat-menu-item 
                  (click)="uploadImage4.click(); imagesIndex = 3; imageIsNew = false;"
                  type="button">
                  Editar
                </button>         
                <button mat-menu-item 
                  (click)="onDeleteFile(3)"
                  type="button">
                  Borrar
                </button>         
              </mat-menu>
            </div>
            <button mat-icon-button color="primary" *ngIf="!images[3]" type="button"
            (click)="uploadImage4.click(); imageIsNew = true; imagesIndex = 3;"><mat-icon>attach_file</mat-icon></button>
            <input hidden type="file" 
              #uploadImage4 accept="image/*"
              (change)="onUploadFile($event, imagesIndex, imageIsNew)">

            <!-- Imagen 5 -->
            <div *ngIf="images[4]">
              <img src="{{images[4].downloadUrl}}" alt="{{images[4].name}}"
                [matMenuTriggerFor]="menuDownloadbleMedia">
              <mat-menu #menuDownloadbleMedia="matMenu">
                <a style="text-decoration: none;" href="{{ images[4].downloadUrl }}" target="_blank">
                  <button mat-menu-item>
                    Descargar
                  </button>         
                </a>
                <button mat-menu-item 
                  (click)="uploadImage5.click(); imagesIndex = 4; imageIsNew = false;"
                  type="button">
                  Editar
                </button>         
                <button mat-menu-item 
                  (click)="onDeleteFile(4)"
                  type="button">
                  Borrar
                </button>         
              </mat-menu>
            </div>
            <button mat-icon-button color="primary" *ngIf="!images[4]" type="button"
            (click)="uploadImage5.click(); imageIsNew = true; imagesIndex = 4;"><mat-icon>attach_file</mat-icon></button>
            <input hidden type="file" 
              #uploadImage5 accept="image/*"
              (change)="onUploadFile($event, imagesIndex, imageIsNew)"> 
          </div>
          
          <label fxLayout="column" fxLayoutAlign="start">
            <span>{{content.search_new_business_step_input_7_label}}</span>
            <mat-form-field appearance="outline">
              <mat-select placeholder="Estado" formControlName="state">
                <mat-option [value]="state.value" *ngFor="let state of states">
                  {{state.title}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </label>
          <label fxLayout="column" fxLayoutAlign="start">
            <span>{{content.search_new_business_step_input_8_label}}</span>
            <mat-form-field appearance="outline">
              <input matInput placeholder="Ciudad" formControlName="city">
              <!-- <mat-select placeholder="Ciudad" formControlName="city">
                <mat-option [value]="city" *ngFor="let city of cities">
                  {{city}}
                </mat-option>
              </mat-select> -->
            </mat-form-field>
          </label>
          <label fxLayout="column" fxLayoutAlign="start">
            <span>{{content.search_new_business_step_input_9_label}}</span>
            <mat-form-field appearance="outline">
              <input matInput type="tel" formControlName="contactPhone">
            </mat-form-field>
          </label>
          <label fxLayout="column" fxLayoutAlign="start">
            <span>{{content.search_new_business_step_input_10_label}}</span>
            <mat-form-field appearance="outline">
              <input matInput type="email" formControlName="contactEmail">
            </mat-form-field>
          </label>
          
          {{content.search_new_business_step_radio_2_label}}
          <div fxLayout="row">
            <label>
              <input type="radio" value="phone" formControlName="showInfo">
                <span>{{content.search_new_business_step_radio_2_option_1}}</span>
            </label>
            <label>
              <input type="radio" value="email" formControlName="showInfo">
              <span>{{content.search_new_business_step_radio_2_option_2}}</span>
            </label>
            <label>
              <input type="radio" value="both" formControlName="showInfo">
              <span>{{content.search_new_business_step_radio_2_option_3}}</span>
            </label>
          </div>
          <label fxLayout="row" fxLayoutAlign="start">
            <input type="checkbox" name="acceptCheckbox" #acceptCheckbox (click)="onChangeAccepted();">
            <span>{{content.search_new_offer_step_terms_and_conditions_1}}<span>{{content.search_new_offer_step_terms_and_conditions_link_1}}</span>{{content.search_new_offer_step_terms_and_conditions_2}}<span>{{content.search_new_offer_step_terms_and_conditions_link_2}}</span></span>
          </label>
          <!-- [disabled]="!currentForm2.valid || !isAccepted  || images.length === 0" -->
          <button class="next-button" type="button" mat-raised-button
          [disabled]="!currentForm2.valid || !isAccepted"
          (click)="onPublish()">{{content.search_new_business_step_publish_button}}</button>
        </form>

        <!-- bolsa de trabajo -->
        <form *ngIf="mode === 'job'" [formGroup]="currentForm2"
          fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">
          <span>{{content.search_new_job_step_input_6_label}}</span>
          <div fxLayout="column" fxLayoutAlign="start center">
            <!-- Imagen de puesto -->
            <div *ngIf="jobImage" class="add-img-container">
              <img src="{{jobImage.downloadUrl}}" alt="{{jobImage.name}}"
                [matMenuTriggerFor]="menuDownloadbleMedia">
              <mat-menu #menuDownloadbleMedia="matMenu">
                <a style="text-decoration: none;" href="{{ jobImage.downloadUrl }}" target="_blank">
                  <button mat-menu-item>
                    Descargar
                  </button>         
                </a>
                <button mat-menu-item 
                  (click)="uploadJobImage.click(); imagesIndex = 0; imageIsNew = false;"
                  type="button">
                  Editar
                </button>         
                <button mat-menu-item 
                  (click)="onDeleteFile(0)"
                  type="button">
                  Borrar
                </button>         
              </mat-menu>
            </div>
            <button mat-icon-button color="primary" *ngIf="!jobImage" type="button"
            (click)="uploadJobImage.click(); imageIsNew = true; imagesIndex = 0;"><mat-icon>attach_file</mat-icon></button>
            <input hidden type="file" 
              #uploadJobImage accept="image/*"
              (change)="onUploadFile($event, imagesIndex, imageIsNew)">
          </div>

          <span>{{content.search_new_job_step_input_7_label}}</span>
          <div fxLayout="column" fxLayoutAlign="start center">
            <!-- Imagen de logo -->
            <div *ngIf="companyLogo" class="add-img-container">
              <img src="{{companyLogo.downloadUrl}}" alt="{{companyLogo.name}}"
                [matMenuTriggerFor]="menuDownloadbleMedia">
              <mat-menu #menuDownloadbleMedia="matMenu">
                <a style="text-decoration: none;" href="{{ companyLogo.downloadUrl }}" target="_blank">
                  <button mat-menu-item>
                    Descargar
                  </button>         
                </a>
                <button mat-menu-item 
                  (click)="uploadCompanyLogo.click(); imagesIndex = 1; imageIsNew = false;"
                  type="button">
                  Editar
                </button>         
                <button mat-menu-item 
                  (click)="onDeleteFile(0)"
                  type="button">
                  Borrar
                </button>         
              </mat-menu>
            </div>
            <button mat-icon-button color="primary" *ngIf="!companyLogo" type="button"
            (click)="uploadCompanyLogo.click(); imageIsNew = true; imagesIndex = 1;"><mat-icon>attach_file</mat-icon></button>
            <input hidden type="file" 
              #uploadCompanyLogo accept="image/*"
              (change)="onUploadFile($event, imagesIndex, imageIsNew)">
          </div>
          
          <label fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">
            <span>{{content.search_new_job_step_input_8_label}}</span>
            <mat-form-field appearance="outline">
              <mat-select placeholder="Estado" formControlName="state">
                <mat-option [value]="state" *ngFor="let state of states">
                  {{state}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <input matInput placeholder="Ciudad" formControlName="city">
              <!-- <mat-select placeholder="Ciudad" formControlName="city">
                <mat-option [value]="city" *ngFor="let city of cities">
                  {{city}}
                </mat-option>
              </mat-select> -->
            </mat-form-field>
          </label>

          <label fxLayout="row" fxLayoutAlign="start">
            <input type="checkbox" name="acceptCheckbox" #acceptCheckbox (click)="onChangeAccepted();">
            <span>{{content.search_new_job_step_terms_and_conditions_1}}<span>{{content.search_new_job_step_terms_and_conditions_link_1}}</span>{{content.search_new_job_step_terms_and_conditions_2}}<span>{{content.search_new_job_step_terms_and_conditions_link_2}}</span></span>
          </label>
          <!-- [disabled]="!currentForm2.valid || !isAccepted" -->
          <button class="next-button" type="button" mat-raised-button
          [disabled]="!currentForm2.valid || !isAccepted || !jobImage || !companyLogo"
          (click)="onPublish()"
          >{{content.search_new_job_step_publish_button}}</button>
        </form>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>