import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { filter, first, map } from 'rxjs/operators';
import { MarketplaceOfferRequest } from 'shared/models/marketplace-offer-request.model';
import { MarketplaceDirectoryJobBankOffer } from 'shared/models/marketplace-directory-job-bank-offer.model';
import { DatabaseService } from './database.service';
import * as moment from 'moment';
import { User } from 'shared/models/user.model';
import { BehaviorSubject, merge, Observable, of, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MarketplaceService {

  private get offersRoute(): string {
    return `offers`;
  }
  private get requestsRoute(): string {
    return `requests`;
  }
  private get jobBankRoute(): string {
    return `job-bank`;
  }
  private get directoryRoute(): string {
    return `job-directory`;
  }
  private get usersRoute(): string {
    return `users`;
  }

  latestOffers: ReplaySubject<MarketplaceOfferRequest[]> = new ReplaySubject<MarketplaceOfferRequest[]>();
  latestRequests: ReplaySubject<MarketplaceOfferRequest[]> = new ReplaySubject<MarketplaceOfferRequest[]>();
  latestDirectories: ReplaySubject<MarketplaceDirectoryJobBankOffer[]> = new ReplaySubject<MarketplaceDirectoryJobBankOffer[]>();
  latestJobs: ReplaySubject<MarketplaceDirectoryJobBankOffer[]> = new ReplaySubject<MarketplaceDirectoryJobBankOffer[]>();
  usersById: ReplaySubject<User[]> = new ReplaySubject<User[]>();
  // TODO: No debe ser mayor a 10 para no romper el paginado
  private offerListSize = 5;

  currentSearch!: any;
  constructor(private database: DatabaseService,
    private dbFirestore: AngularFirestore) { }

  getAllMarketplaceCategories(): Observable<string[]> {
    return this.database.getDocument<string[]>(`/config/marketplace-categories`);
  }

  //---------------------------------------------------------------------------------------
  // Offers
  //---------------------------------------------------------------------------------------

  getAllOffers() {
    const today  =  
      firebase.firestore.Timestamp.fromDate(moment().startOf('day').subtract(1, 'day').toDate());

    return this.dbFirestore.collection<MarketplaceOfferRequest>(`${this.offersRoute}`,
        ref => ref.where('validDateEnd', '>=', today)
                  .orderBy('validDateEnd', 'desc')
      )
      .get()
      .pipe(
        map((documents) => {
          return documents.docs.map((document) => {
            return {
              ...document.data(),
              id: document.id,
              reference: document.ref,
            }
          })
        })
      );
  }

  // Esto se usa para resetear el subject, ya que si no se hace lo que está dentro de latest
  // offers se ejecuta múltiples veces
  cleanOffers() {
    this.latestOffers.complete();
    this.latestOffers = new ReplaySubject<MarketplaceOfferRequest[]>();
  }

  async refreshOffers(offerIndex = 1): Promise<Observable<MarketplaceOfferRequest[]>> {
    // console.log('executing service');
    const result = await this.getLatestOffers(offerIndex);
    // // console.log(result);
    this.latestOffers.next(result);
    return this.latestOffers.asObservable();
  }

  getLatestOffers(offerIndex = 1): Promise<MarketplaceOfferRequest[]> {
    // // console.log('getting latest offers');
    const today  =  
      firebase.firestore.Timestamp.fromDate(moment().startOf('day').subtract(1, 'day').toDate());

    return this.dbFirestore.collection<MarketplaceOfferRequest>(`${this.offersRoute}`,
      ref => ref.where('validDateEnd', '>=', today)
                .orderBy('validDateEnd', 'asc')
                .limit(this.offerListSize * offerIndex)
      )
      .get()
      .pipe(
        first(),
        map((documents) => {
          return documents.docs.map((document) => {
            return {
              ...document.data(),
              id: document.id,
              reference: document.ref,
            }
          })
          .slice(this.offerListSize * (offerIndex - 1), this.offerListSize * offerIndex)
        })
      ).toPromise();
  }

  getOffer(id: string) {
    return this.database.getDocument<MarketplaceOfferRequest>(`${this.offersRoute}/${id}`);
  }

  createOffer(data: MarketplaceOfferRequest): Promise<any> {
    return this.database.addDocumentToCollection(this.offersRoute, data);
  }

  updateOffer(id: string, data: MarketplaceOfferRequest) {
    return this.database.updateDocument(`${this.offersRoute}/${id}`, data);
  }

  deleteOffer(id: string) {
    return this.database.deleteDocument(`${this.offersRoute}/${id}`);
  }

  //---------------------------------------------------------------------------------------
  // Request
  //---------------------------------------------------------------------------------------

  getAllRequests() {
    const today  =  
      firebase.firestore.Timestamp.fromDate(moment().startOf('day').subtract(1, 'day').toDate());

    return this.dbFirestore.collection<MarketplaceOfferRequest>(`${this.requestsRoute}`,
        ref => ref.where('validDateEnd', '>=', today)
                  .orderBy('validDateEnd', 'desc')
      )
      .get()
      .pipe(
        map((documents) => {
          return documents.docs.map((document) => {
            return {
              ...document.data(),
              id: document.id,
              reference: document.ref,
            }
          })
        })
      );
  }

  // Esto se usa para resetear el subject, ya que si no se hace lo que está dentro de latest
  // requests se ejecuta múltiples veces
  cleanRequests() {
    this.latestRequests.complete();
    this.latestRequests = new ReplaySubject<MarketplaceOfferRequest[]>();
  }

  async refreshRequests(requestIndex = 1): Promise<Observable<MarketplaceOfferRequest[]>> {
    const result = await this.getLatestRequests(requestIndex);
    this.latestRequests.next(result);
    return this.latestRequests.asObservable();
  }

  getLatestRequests(requestIndex = 1): Promise<MarketplaceOfferRequest[]> {
    const today  =  
      firebase.firestore.Timestamp.fromDate(moment().startOf('day').subtract(1, 'day').toDate());
    
    return this.dbFirestore.collection<MarketplaceOfferRequest>(`${this.requestsRoute}`,
      ref => ref.where('validDateEnd', '>=', today)
                .orderBy('validDateEnd', 'asc')
                .limit(this.offerListSize * requestIndex)
      )
      .get()
      .pipe(
        first(),
        map((documents) => {
          return documents.docs.map((document) => {
            return {
              ...document.data(),
              id: document.id,
              reference: document.ref,
            }
          })
          .slice(this.offerListSize * (requestIndex - 1), this.offerListSize * requestIndex)
        })
      ).toPromise();
  }

  getRequest(id: string) {
    return this.database.getDocument<MarketplaceOfferRequest>(`${this.requestsRoute}/${id}`);
  }

  createRequest(data: MarketplaceOfferRequest) {
    return this.database.addDocumentToCollection(this.requestsRoute, data);
  }

  updateRequest(id: string, data: MarketplaceOfferRequest) {
    return this.database.updateDocument(`${this.requestsRoute}/${id}`, data);
  }

  deleteRequest(id: string) {
    return this.database.deleteDocument(`${this.requestsRoute}/${id}`);
  }

  getAllMyActivities(id: string): Observable<MarketplaceOfferRequest[] | MarketplaceDirectoryJobBankOffer[]> {
    const allRequests = this.dbFirestore.collection<MarketplaceOfferRequest>(`${this.requestsRoute}`,
      ref => ref.where('creatorId', '==', id))
      .get()
      .pipe(
        map((documents) => {
          return documents.docs.map((document) => {
            return {
              ...document.data(),
              id: document.id,
              reference: document.ref,
            }
          })
        }),
      );
    const allOffers = this.dbFirestore.collection<MarketplaceOfferRequest>(`${this.offersRoute}`,
      ref => ref.where('creatorId', '==', id))
      .get()
      .pipe(
        map((documents) => {
          return documents.docs.map((document) => {
            return {
              ...document.data(),
              id: document.id,
              reference: document.ref,
            }
          })
        }),
      );
    const allDirectories = this.dbFirestore.collection<MarketplaceDirectoryJobBankOffer>(`${this.directoryRoute}`,
      ref => ref.where('creatorId', '==', id))
      .get()
      .pipe(
        map((documents) => {
          return documents.docs.map((document) => {
            return {
              ...document.data(),
              id: document.id,
              reference: document.ref,
            }
          })
        }),
      );
    const allJobs = this.dbFirestore.collection<MarketplaceDirectoryJobBankOffer>(`${this.jobBankRoute}`,
      ref => ref.where('creatorId', '==', id))
      .get()
      .pipe(
        map((documents) => {
          return documents.docs.map((document) => {
            return {
              ...document.data(),
              id: document.id,
              reference: document.ref,
            }
          })
        }),
      );

      return merge(allOffers, allRequests, allDirectories, allJobs);
  }

  //---------------------------------------------------------------------------------------
  // Job Bank
  //---------------------------------------------------------------------------------------
  // async refreshJobBank(jobIndex = 1): Promise<Observable<MarketplaceDirectoryJobBankOffer[]>> {
  //   const result = await this.getAllJobBank(jobIndex);
  //   this.latestJobs.next(result);
  //   return this.latestJobs.asObservable();
  // }

  getAllJobBank() {
    // const auxArray = [];

    // for (let index = 0; index < 100; index++) {
    //   if (index < 20) {
    //     auxArray.push({
    //         title: 'title' + index,
    //         validDateStart: firebase.firestore.Timestamp.fromMillis(1616197929000),
    //         isArchive: false,
    //         category: 'Medicina',
    //       });
    //   } else if (index < 40) {
    //     auxArray.push({
    //         title: 'title' + index,
    //         validDateStart: firebase.firestore.Timestamp.fromMillis(1616197929000),
    //         isArchive: false,
    //         category: 'Finanzas',
    //       });
    //   } else if (index < 60) {
    //     auxArray.push({
    //         title: 'title' + index,
    //         validDateStart: firebase.firestore.Timestamp.fromMillis(1616197929000),
    //         isArchive: false,
    //         category: 'Legal',
    //       });
    //   } else if (index < 75) {
    //     auxArray.push({
    //         title: 'title' + index,
    //         validDateStart: firebase.firestore.Timestamp.fromMillis(1616197929000),
    //         isArchive: false,
    //         category: 'Autos',
    //       });
    //   } else if (index < 90) {
    //     auxArray.push({
    //         title: 'title' + index,
    //         validDateStart: firebase.firestore.Timestamp.fromMillis(1616197929000),
    //         isArchive: false,
    //         category: 'Arte',
    //       });
    //   } else {
    //     auxArray.push({
    //         title: 'title' + index,
    //         validDateStart: firebase.firestore.Timestamp.fromMillis(1616197929000),
    //         isArchive: false,
    //         category: 'Arquitectura',
    //       });

    //   }
    // }

    // return of(auxArray.slice(this.offerListSize * (jobIndex - 1), this.offerListSize * jobIndex));

    return this.dbFirestore.collection<MarketplaceDirectoryJobBankOffer>(`${this.jobBankRoute}`,
        ref => ref.orderBy('dateCreated', 'desc')
                  .limit(50)
      )
      .get()
      .pipe(
        map((documents) => {
          return documents.docs.map((document) => {
            return {
              ...document.data(),
              id: document.id,
              reference: document.ref,
            }
          })
        })
      );
  }

  getLatestJobBank(jobIndex = 1) {
    const monthAgo  =  
      firebase.firestore.Timestamp.fromDate(moment().startOf('day').subtract(30, 'day').toDate());
    
    return this.dbFirestore.collection<MarketplaceDirectoryJobBankOffer>(`${this.jobBankRoute}`,
      ref => ref
                // .where('dateCreated', '>=', monthAgo)
                .orderBy('dateCreated', 'desc')
                .limit(this.offerListSize * jobIndex)
      )
      .get()
      .pipe(
        map((documents) => {
          return documents.docs.map((document) => {
            return {
              ...document.data(),
              id: document.id,
              reference: document.ref,
            }
          })
          .slice(this.offerListSize * (jobIndex - 1), this.offerListSize * jobIndex)
        })
      );
  }

  getJobBank(id: string) {
    return this.database.getDocument<MarketplaceDirectoryJobBankOffer>(`${this.jobBankRoute}/${id}`);
  }

  createJobBank(data: MarketplaceDirectoryJobBankOffer) {
    return this.database.addDocumentToCollection(this.jobBankRoute, data);
  }

  updateJobBank(id: string, data: MarketplaceDirectoryJobBankOffer) {
    return this.database.updateDocument(`${this.jobBankRoute}/${id}`, data);
  }

  deleteJobBank(id: string) {
    return this.database.deleteDocument(`${this.jobBankRoute}/${id}`);
  }

  //---------------------------------------------------------------------------------------
  // Directory
  //---------------------------------------------------------------------------------------

  getAllDirectory() {
    return this.dbFirestore.collection<MarketplaceDirectoryJobBankOffer>(`${this.directoryRoute}`,
        ref => ref.orderBy('dateCreated', 'desc')
                  .limit(50)
      )
      .get()
      .pipe(
        map((documents) => {
          return documents.docs.map((document) => {
            return {
              ...document.data(),
              id: document.id,
              reference: document.ref,
            }
          })
        })
      );
  }

  async refreshDirectory(directoryIndex = 1): Promise<Observable<MarketplaceDirectoryJobBankOffer[]>> {
    const result = await this.getLatestDirectory(directoryIndex);
    this.latestDirectories.next(result);
    return this.latestDirectories.asObservable();
  }

  getLatestDirectory(directoryIndex = 1) {
    const monthAgo  =  
      firebase.firestore.Timestamp.fromDate(moment().startOf('day').subtract(30, 'day').toDate());
    
    return this.dbFirestore.collection<MarketplaceDirectoryJobBankOffer>(`${this.directoryRoute}`,
      ref => ref.where('dateCreated', '>=', monthAgo)
                .orderBy('dateCreated', 'desc')
                .limit(this.offerListSize * directoryIndex)
      )
      .get()
      .pipe(
        first(),
        map((documents) => {
          return documents.docs.map((document) => {
            return {
              ...document.data(),
              id: document.id,
              reference: document.ref,
            }
          })
          .slice(this.offerListSize * (directoryIndex - 1), this.offerListSize * directoryIndex)
        })
      ).toPromise();
  }

  getDirectory(id: string) {
    return this.database.getDocument<MarketplaceDirectoryJobBankOffer>(`${this.directoryRoute}/${id}`);
  }

  createDirectory(data: MarketplaceDirectoryJobBankOffer) {
    return this.database.addDocumentToCollection(this.directoryRoute, data);
  }

  updateDirectory(id: string, data: MarketplaceDirectoryJobBankOffer) {
    return this.database.updateDocument(`${this.directoryRoute}/${id}`, data);
  }

  deleteDirectory(id: string) {
    return this.database.deleteDocument(`${this.directoryRoute}/${id}`);
  }

  //---------------------------------------------------------------------------------------
  // Users
  //---------------------------------------------------------------------------------------

  getUsersById(ids: string[], index = 1) {
    if (ids.length > 0) {
      const sections = Math.ceil(ids.length / 10);
  
      if (index > sections) {
        index = sections;
      }
  
      const result = ids.slice(((index - 1) * 10), ((index * 10)));
      // // console.log('result: ', result);
      return this.dbFirestore.collection(this.usersRoute, 
        ref  => ref.where(firebase.firestore.FieldPath.documentId(), 'in', result!)
      )
      .get()
      .pipe(
        map((documents) => {
          return documents.docs.map((document) => {
            return document.data() as User;
          })
        })
      );
    } else {
      return of([]);
    }
  }

  async refreshUsersById(ids: string[], index = 1) {
    const x = await this.getUsersById(ids, index).pipe(first()).toPromise();
    this.usersById.next(x);
    return this.usersById.asObservable();
  }

  //---------------------------------------------------------------------------------------
  // Misc
  //---------------------------------------------------------------------------------------
  getAllStates(forSearch = false): any[] {
    const states = [];

    if (forSearch) {
      states.push(
        {title: 'Todas las regiones', value: 'all-regions'},
        );
      }
      
      states.push(
      {title: 'Internacional', value: 'international'},
      {title: 'Nacional', value: 'all-mexico'},
      {title: 'Aguascalientes', value: 'aguascalientes'},
      {title: 'Baja California', value: 'baja-california'},
      {title: 'Baja California Sur', value: 'baja-california-sur'},
      {title: 'Campeche', value: 'campeche'},
      {title: 'Coahuila', value: 'coahuila'},
      {title: 'Colima', value: 'colima'},
      {title: 'Chiapas', value: 'chiapas'},
      {title: 'Chihuahua', value: 'chihuahua'},
      {title: 'CDMX', value: 'cdmx'},
      {title: 'Durango', value: 'durango'},
      {title: 'Guanajuato', value: 'guanajuato'},
      {title: 'Guerrero', value: 'guerrero'},
      {title: 'Hidalgo', value: 'hidalgo'},
      {title: 'Jalisco', value: 'jalisco'},
      {title: 'Michoacán', value: 'michoacan'},
      {title: 'Morelos', value: 'morelos'},
      {title: 'Nayarit', value: 'nayarit'},
      {title: 'Nuevo León', value: 'nuevo-leon'},
      {title: 'Oaxaca', value: 'oaxaca'},
      {title: 'Puebla', value: 'puebla'},
      {title: 'Querétaro', value: 'queretaro'},
      {title: 'Quintana Roo', value: 'quintana-roo'},
      {title: 'San Luis Potosí', value: 'san-luis-potosi'},
      {title: 'Sinaloa', value: 'sinaloa'},
      {title: 'Sonora', value: 'sonora'},
      {title: 'Tabasco', value: 'tabasco'},
      {title: 'Tamaulipas', value: 'tamaulipas'},
      {title: 'Tlaxcala', value: 'tlaxcala'},
      {title: 'Veracruz', value: 'veracruz'},
      {title: 'Yucatán', value: 'yucatan'},
      {title: 'Zacatecas', value: 'zacatecas'},
    );
    
    return states;
  }
}
