import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-admin-confirm-dialog',
  templateUrl: './admin-confirm-dialog.component.html',
  styleUrls: ['./admin-confirm-dialog.component.scss']
})
export class AdminConfirmDialogComponent implements OnInit {

  @Output() confirmSave = new EventEmitter<boolean>();

  constructor(private dialogRef: MatDialogRef<AdminConfirmDialogComponent>,
              /**
               * Titulo del dialogo.
               */
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  onAccept(): void {
    this.confirmSave.emit(true);
    this.dialogRef.close();
  }

  onCancel(): void {
    this.confirmSave.emit(false);
    this.dialogRef.close();
  }

}
