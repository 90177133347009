import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'hammerjs';

import firebase from '@firebase/app';
import '@firebase/firestore';
import '@firebase/messaging';
firebase.initializeApp(environment.firebase);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
  // ---------------------------------------------------------------------
  // Service Worker para notificaciones web
  // ---------------------------------------------------------------------
  if ('serviceWorker' in navigator && environment.production) {
    console.log('Registrando service worker...');
    navigator.serviceWorker.register('/ngsw-worker.js')
      .then((registration) => {
        firebase.messaging().useServiceWorker(registration);
        console.log('El service worker fue registrado correctamente.');
      }).catch((error) => {
        console.error('main: Error en el service worker:', error);
      });
  }
})
  .catch(err => console.error(err));
