import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'shared/services/auth.service';
import { TranslateService } from 'shared/services/translate.service';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  content: any;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              public authService: AuthService,
              public snackbar: MatSnackBar,
              private translateService: TranslateService) {
    this.translateService.dataObservable.subscribe((data: any) => {
    this.content = data;
    });
  }

  ngOnInit(): void {
  }

  onResetPassword() {
    this.authService.sendResetPasswordEmailToThisUser();
  }
}
