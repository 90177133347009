<div class="container"
  fxLayout="column" fxLayoutAlign="start center">
  <div class="notifications-container"
    fxLayout="column" fxLayoutAlign="start"
    fxLayoutGap="30px">

    <!-- <div class="notifications-title-container"
      fxLayout="column" fxLayoutAlign="start center">
      <span class="notifications-title">NOTIFICACIONES</span>
    </div> -->

    <div class="title-container">
      <div class="top-shape-container">
        <div class="top-shape" [ngStyle]="{'background-color': color ? color : '#898A8D'}"></div>
      </div>
    </div>

    <div class="notifications-list-container"
      fxLayout="column" fxLayoutAlign="start">
      <div *ngFor="let notification of notifications; let i = index;">
        <app-notification-item [notification]="notification" [image]="notification.icon!"
          (read)="onMarkAsReadNotification(i)" 
          (delete)="onDeleteNotification(i)" 
          (friend)="onSeeFriendshipRequests()"></app-notification-item>
      </div>
    </div>
    
  </div>
</div>