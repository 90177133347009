import { Pipe, PipeTransform } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { User } from 'shared/models/user.model';

@Pipe({
  name: 'creatorImg'
})
export class CreatorImgPipe implements PipeTransform {

  constructor(private database: AngularFirestore) {}

  transform(id: string): Promise<string> {
    if (id) {
      return this.database.doc<User>(`users/${id}`)
      .get()
      .pipe(
        first(),
        map((doc) => {
          if (doc.exists && doc.data()?.img?.downloadUrl) {
            return doc.data()?.img?.downloadUrl as string;
          } else {
            return './assets/no-profile.png';
          }
        }))
        .toPromise();
    } else {
      return of('./assets/no-profile.png').pipe(first()).toPromise();
    }
  }

}
