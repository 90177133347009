import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MarketplaceService } from 'shared/services/marketplace.service';

@Component({
  selector: 'app-general-search',
  templateUrl: './general-search.component.html',
  styleUrls: ['./general-search.component.scss']
})
export class GeneralSearchComponent implements OnInit, OnDestroy {
  @Input() color!: string;
  @Input() title!: string;
  @Input() firstSubtitle!: string;
  @Input() filters!: any[];
  @Input() categoriesSelector!: any[];
  @Input() statesSelector!: any[];
  @Input() buttonControls!: any[];
  @Input() searchRoute!: string;
  @Input() type: 'offer' | 'search' | 'directory' | 'job' = 'offer';
  subscriptions: Subscription[] = [];
  categoryAvailable = true;

  constructor(private router: Router,
              private marketplaceService: MarketplaceService,) { }

  ngOnInit(): void {
    
    // // console.log(this.buttonControls);
    this.filters = [
      {title: 'ventas', checked: true, value: 'sales'},
      {title: 'servicios', checked: false, value: 'services'},
      {title: 'bienes raíces', checked: false, value: 'real estate'},
    ];
    if (this.type === 'directory') {
      this.filters = [
        {title: 'negocios', checked: true, value: 'business'},
        {title: 'servicios', checked: false, value: 'services'},
      ];
    }
    // Obtenemos los estados
    this.statesSelector = this.marketplaceService.getAllStates(true);
    
    // Obtenemos las categorías
    this.subscriptions.push(this.marketplaceService.getAllMarketplaceCategories()
      .subscribe((categories: any) => {
        this.categoriesSelector = categories.categories;
      }));
    this.firstSubtitle = '1) ¿Qué tipo de oferta buscas?';
    // // console.log(this.filters);

  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      for (const subscription of this.subscriptions) {
        subscription.unsubscribe();
      }
    }
  }

  onClickFilter(filter: any): void {
    if ((this.type === 'offer' || this.type === 'search') && filter.value === 'real estate' && filter.checked) {
      this.categoryAvailable = false;
    } else if ((this.type === 'offer' || this.type === 'search') && filter.value === 'real estate' && !filter.checked) {
      this.categoryAvailable = true;
    }
  }

  onSearch(form: NgForm): void {
    // // console.log(form.value);
    // // console.log(this.filters);
    // this.router.navigate([`/${this.searchRoute}`]);
    this.router.navigate([`/${this.searchRoute}`], {
      queryParams: {
        types: this.filters.filter((x) => x.checked === true).map((y) => y.value),
        category: form.value.categorySelect,
        state: form.value.stateSelect,
      }
    });
    this.marketplaceService.currentSearch = {
      types: this.filters.filter((x) => x.checked === true).map((y) => y.value),
      category: form.value.categorySelect,
      state: form.value.stateSelect,
    };

    // // console.log(this.marketplaceService.currentSearch);

    // Realizar la búsqueda
    switch(this.type) {
      case 'offer':
        // do something...
        break;
      case 'search':
        // do something...
        break;
      case 'directory':
        // do something...
        break;
      case 'job':
        // do something...
        break;
    }
  }
}
